import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const UpcomingDates = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleChange = (newDate) => {
    setSelectedDate(newDate);
  };

  return (
    <div className='UpcomingDate container p-3 w-75 secondary-class'>
      <div className='d-flex justify-content-center align-item-center fw-bold h2 pt-2'>
        <p>Upcoming Dates</p>
      </div>
      <hr />
      <div className='d-flex justify-content-center'>
        <a href="#">Add this Schedule to HEY Calendar, Google Calendar, Outlook, or iCal…</a>
      </div>
      <div className="d-flex justify-content-center">
        <div className="Calendar-cont p-3">
          <div className="card-body">
            <div className="calendar">
              <DatePicker
                selected={selectedDate}
                onChange={handleChange}
                inline
                monthsShown={2}
                calendarClassName="calendar"
              />
              <div className="calendar-footer row">
                <p className='t-color1 col'>
                  <FontAwesomeIcon className='text-warning pe-2' icon={faCalendarDays} />
                  {selectedDate.toDateString()}
                </p>
                <p className='t-color1 col'>Nothing's on the schedule</p>
              </div>
            </div>
            <hr className="line" />
            <div className='d-flex justify-content-center'>
              <a href="#">Show everything up to {format(selectedDate, 'MMM dd')}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingDates;
