import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const InviteNewuser = () => {
    const { token, organizationId } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        password: '',
        confirmPassword: '',
        organizationId: '',
        role: '',
        profilePhoto: null,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchInviteData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/new-member/${organizationId}/${token}`);
                const { fullName, email, role } = response.data;
                setFormData({ ...formData, fullName, email, organizationId, role });
            } catch (error) {
                console.error('Error fetching invite data:', error);
                setError('Error fetching invitation data.');
            }
        };

        fetchInviteData();
    }, [token, organizationId]);

    const handleChange = (e) => {
        const { id, value, files } = e.target;

        if (id === 'profilePhoto' && files.length > 0) {
            const file = files[0];
            setFormData({
                ...formData,
                profilePhoto: URL.createObjectURL(file), // Preview URL for the image
                profilePhotoFile: file // Store the actual file for upload
            });
        } else {
            setFormData({ ...formData, [id]: value });
        }
    };
    const getInitials = (name) => {
        const nameArray = name.split(' ');
        const initials = nameArray.map(n => n.charAt(0)).join('').toUpperCase();
        return initials;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (!formData.password || !formData.confirmPassword) {
            setError('Password and confirm password are required.');
            setLoading(false);
            return;
        }
        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }
        if (formData.password.length < 8) {
            setError('Password must be at least 8 characters.');
            setLoading(false);
            return;
        }

        // Prepare FormData for sending both text fields and the file
        const formDataToSend = new FormData();
        formDataToSend.append('token', token);
        formDataToSend.append('password', formData.password);

        if (formData.profilePhotoFile) {
            formDataToSend.append('profilePhoto', formData.profilePhotoFile); // Append the file to FormData
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/accept-invite/new-user`,
                { token, password: formData.password, organizationId },
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            toast.success('Signup successful!');
            navigate('/');
        } catch (error) {
            console.error('Error during registration:', error);
            setError('Error during registration.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container profile-form-container mt-2 d-flex justify-content-center align-items-center">
            <div style={{ width: '30rem' }}>
                <div className="card-body profileForm p-3 bg-white b-shad">
                    <form onSubmit={handleSubmit}>
                        <div className="profileformGroup text-center mb-4">
                            <div
                                className="rounded-circle bg-success text-white d-inline-block"
                                style={{ width: '80px', height: '80px', lineHeight: '80px', fontSize: '36px' }}
                            >
                                {formData.profilePhoto ? (
                                    <img
                                        src={formData.profilePhoto}
                                        alt="Profile"
                                        style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                                    />
                                ) : (
                                    getInitials(formData.fullName)
                                )}
                            </div>
                            <div className="mt-2">
                                <input
                                    type="file"
                                    name="profilePhoto"
                                    className="photo-button text-black"
                                    id="profilePhoto"
                                    style={{ display: 'none' }}
                                    onChange={handleChange}
                                />
                                <label htmlFor="profilePhoto" className="text-dark photobutton">
                                    Upload a photo...
                                </label>
                            </div>
                            <div className="mt-3 photobutton2 d-block m-auto w-50 fs-6 textRequired">
                                Upload a photo to show your 😊
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="fullName" className="h5 fw-bold text-dark">Name <span className="textRequired h6">required</span></label>
                            <input
                                type="text"
                                className="form-control personalForm text-black"
                                id="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email" className="h5 text-dark fw-bold mt-3">Email address <span className="textRequired h6">required</span></label>
                            <input
                                type="email"
                                className="form-control personalForm mb-2 text-black"
                                id="email"
                                value={formData.email}
                                readOnly
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password" className="h5 text-dark fw-bold mt-3">New Password</label>
                            <input
                                type="password"
                                id="password"
                                className="form-control personalForm mb-2"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="confirmPassword" className="h5 text-dark fw-bold mt-3">Confirm Password</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                className="form-control personalForm mb-2"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                            />
                        </div>



                        <button type="submit" className="w-100 p-2 mt-3 action-btn" disabled={loading}>
                            {loading ? 'Please Wait...' : "OK, let's go"}
                        </button>
                    </form>

                    {error && <div className="alert alert-danger mt-3">{error}</div>}
                </div>
            </div>
        </div>
    );
};

export default InviteNewuser;
