// import React, { useContext, useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { FaPlus } from "react-icons/fa";
// import { Link, useParams } from 'react-router-dom';
// import { Outlet } from 'react-router-dom';
// import { UserContext } from '../../context/userContext';
// import { Rings } from 'react-loader-spinner'
// import Loader from '../../Loader';
// const SetUpPeople = () => {
//     const [selectedStatus, setSelectedStatus] = useState('');
//     const { user, error, loading } = useContext(UserContext)
//     const [err, setErr] = useState(null)
//     const [projectData, setProjectData] = useState(null)
//     const { id } = useParams();
//     useEffect(() => {
//         const fetchProject = async () => {
//             const token = localStorage.getItem("token") // Retrieve token from localStorage or any other storage
//             if (!token) {
//                 setErr('No token found. Please log in.');
//                 return;
//             }

//             try {
//                 const response = await fetch(`${process.env.REACT_APP_API_URL}/api/project/${id}`, {
//                     method: 'GET',
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Authorization': `Bearer ${token}` // Attach the token in the Authorization header
//                     }
//                 });

//                 const data = await response.json();

//                 if (response.ok) {
//                     // console.log(data.project)
//                     setProjectData(data.project.members);
//                     console.log(data.project.members)
//                 } else {
//                     setErr(data.message || 'Failed to load project');
//                 }
//             } catch (err) {
//                 console.error('Error fetching project:', err);
//                 setErr(err.message || 'Error fetching project');
//             }
//         };

//         fetchProject();
//     }, [id]);

//     const users = [
//         { name: "Arul Kumar V", role: "Product Developer - React Native at webnexs.com", status: "On the HQ" },
//         // { name: "Arun Kumar", role: "Internship at webnexs.com", status: "On the HQ" },
//         // { name: "Arun Pandiyan", role: "Front End Intern at webnexs.com", status: "On the HQ" },
//         // { name: "ArunKumar AD", role: "Front End Intern at webnexs.com", status: "On the HQ" },
//         // { name: "Bala", role: "UI&UX Designer & Graphic Designer at webnexs.com", status: "On the HQ" },
//         // { name: "Balaji", role: "Front End Developer at webnexs.com", status: "Just following" },
//         // { name: "David Bright Livingstone", role: "webnexs.com", status: "On the HQ" },
//     ];
//     const handleChanges = (event) => {
//         setSelectedStatus(event.target.value);
//     };
//     const statusOptions = [
//         { value: 'On the HQ', label: 'On the HQ' },
//         { value: 'Just following', label: 'Just following' },
//     ];
//     if (loading) return <div><Loader /></div>

//     return (
//         <div className='campfire todo-contain'>
//             <div className=' text-center d-block m-auto campfire card--head camp secondary-class' style={{ width: '60vw', height: '6vh' }}>
//                 <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
//                     <button className="icon--button me-2">
//                         <FontAwesomeIcon className='me-1' icon={faTh} />
//                         <FontAwesomeIcon icon={faChevronDown} />
//                     </button>
//                     <Link to="/project" className="fw-bold">{user?.organizationId?.name}</Link>
//                 </div>
//             </div>
//             <div className="d-flex justify-content-center align-items-center camp">
//                 <div className="card text-center" style={{ width: '70vw' }}>
//                     <div className="card campfireBody">
//                         <div className="container ">
//                             <div className="row">
//                                 <div className="col-3 text-start">
//                                     <Link to="/new-people">
//                                         <button className="newEvent px-2 mt-3 py-1 t-color1">
//                                             <FaPlus className='me-1' />Add a people
//                                         </button>
//                                     </Link>
//                                 </div>
//                                 <div className="col-6 text-center mt-3">
//                                     <a href="CompanyHQ" className='headcamp fw-bold'> <h2 className="mb-0">People who can see this Company HQ</h2> </a>
//                                 </div>
//                             </div>
//                             <hr className="line" />
//                             <div className='container pt-3 setupPeople'>
//                                 <p className=' text-center text-break h-5'>If someone is<span className='user-status'> On the Company HQ</span> their avatar will show up at the top of the Company HQ and they’ll be notified when someone posts in Chat.
//                                     People who are<span className='follwBtn'> Just following</span> won’t hear from the Company HQ unless someone specifically @mentions them, assigns them a to-do, or loops them into a thread.</p>
//                                 <div>
//                                     <h4 className='fw-bold text-start '>{user?.organizationId?.name}</h4>
//                                 </div>
//                                 <div className="user-list text-start">
//                                     {users.map((user, index) => (
//                                         <div key={index} className="user-item">
//                                             <div className="user-avatar">{user.name.charAt(0)}</div>
//                                             <div className="user-info">
//                                                 <div className="user-name">{user.name}</div>
//                                                 <div className="user-role">{user.role}</div>
//                                             </div>
//                                             <select
//                                                 className='user-status'
//                                                 id="day-selector"
//                                                 value={selectedStatus}
//                                                 onChange={handleChanges}
//                                             >
//                                                 {statusOptions.map((option) => (
//                                                     <option key={option.value} value={option.value}>
//                                                         {option.label}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                             <div className="text-end">
//                                                 <button className="setupbtn">
//                                                     <FontAwesomeIcon icon={faEllipsisH} />
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </div>
//                                 <div>
//                                     <h4 className='fw-bold text-start'>Everyone else</h4>
//                                 </div>
//                                 <div className="user-list text-start">
//                                     {users.map((user, index) => (
//                                         <div key={index} className="user-item">
//                                             <div className="user-avatar">{user.name.charAt(0)}</div>
//                                             <div className="user-info">
//                                                 <div className="user-name">{user.name}</div>
//                                                 <div className="user-role">{user.role}</div>
//                                             </div>
//                                             <select
//                                                 className='user-status'
//                                                 id="day-selector"
//                                                 value={selectedStatus}
//                                                 onChange={handleChanges}
//                                             >
//                                                 {statusOptions.map((option) => (
//                                                     <option key={option.value} value={option.value}>
//                                                         {option.label}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                             <div className="text-end">
//                                                 <button className="setupbtn ">
//                                                     <FontAwesomeIcon icon={faEllipsisH} />
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
// <div className='client-card d-flex justify-content-center secondary-class'>
//     <div className='ques d-flex w-75 justify-content-center align-items-center'>
//         <div className='w-75'>
//             <h4 className='text-start'>Working with a client?</h4>
//             <p className='text-start'>When you invite a client to this project, you decide what they can see. When you’re ready to share a message, a to-do, or a file with the client, just flip the switch. Basecamp will keep both sides organized, everyone’s feedback on the record, and all decisions safe and centralized.</p>
//         </div>
//         <div>
//             <button>Add a client to this project…
//             </button>
//         </div>
//     </div>
// </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Outlet />
//         </div>
//     )
// }

// export default SetUpPeople;


import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaPlus } from "react-icons/fa";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { UserContext } from '../../context/userContext';
import Loader from '../../Loader';

const SetUpPeople = () => {
    const [selectedStatus, setSelectedStatus] = useState('');
    const { user, error, loading } = useContext(UserContext);
    const [err, setErr] = useState(null);
    const [projectData, setProjectData] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate()
    useEffect(() => {
        const fetchProject = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                setErr('No token found. Please log in.');
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/project/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                const data = await response.json();

                if (response.ok) {
                    setProjectData(data.project.members); // Set project members
                    console.log(data.project)
                } else {
                    setErr(data.message || 'Failed to load project');
                }
            } catch (err) {
                console.error('Error fetching project:', err);
                setErr(err.message || 'Error fetching project');
            }
        };

        fetchProject();
    }, [id]);

    const handleChanges = (event) => {
        setSelectedStatus(event.target.value);
    };

    const statusOptions = [
        { value: 'On the HQ', label: 'On the HQ' },
        { value: 'Just following', label: 'Just following' },
    ];
    const handleBack = () => {
        navigate(-1)
    }
    // if (loading) return <div><Loader /></div>;

    return (
        <div className='campfire todo-contain'>
            <div className='text-center d-block m-auto campfire card--head camp secondary-class' style={{ width: '60vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <Link className="fw-bold" onClick={handleBack}>{user?.organizationId?.name}</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center" style={{ width: '70vw' }}>
                    <div className="card campfireBody">
                        <div className="container">
                            <div className="row">
                                <div className="col-3 text-start">
                                    <Link to="add">
                                        <button className="newEvent px-2 mt-3 py-1 t-color1">
                                            <FaPlus className='me-1' />Add a person
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-6 text-center mt-3">
                                    <a href="CompanyHQ" className='headcamp fw-bold'>
                                        <h2 className="mb-0 t-color1">People who can see this Company HQ</h2>
                                    </a>
                                </div>
                            </div>
                            <hr className="line" />
                            <div className='container pt-3 setupPeople'>
                                <p className='text-center text-break h-5'>
                                    If someone is<span className='user-status'> On the Company HQ</span>, their avatar will show up at the top of the Company HQ and they’ll be notified when someone posts in Chat.
                                    People who are   <span className='follwBtn px-2 py-1'> Just following</span>  won’t hear from the Company HQ unless someone specifically @mentions them, assigns them a to-do, or loops them into a thread.
                                </p>
                                <div>
                                    <h4 className='fw-bold text-start'>{user?.organizationId?.name}</h4>
                                </div>
                                <div className="user-list text-start">
                                    {projectData && projectData.length > 0 ? (
                                        projectData.map((member, index) => (
                                            <div key={index} className="user-item">
                                                <div className="user-avatar">{member.username.charAt(0)}</div>
                                                <div className="user-info">
                                                    <div className="user-name t-color1">{member.username}</div>
                                                    <div className="user-role">{member.role.RoleName || "Role not specified"}</div>
                                                </div>
                                                <select
                                                    className='user-status'
                                                    id="day-selector"
                                                    value={selectedStatus}
                                                    onChange={handleChanges}
                                                >
                                                    {statusOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="text-end">
                                                    <button className="setupbtn">
                                                        <FontAwesomeIcon icon={faEllipsisH} />
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No members found for this project.</p>
                                    )}
                                </div>
                                <div className='client-card d-flex justify-content-between b-shad mb-4 rounded'>
                                    <div className='ques d-flex justify-content-center align-items-center p-3'>
                                        <div className='w-75'>
                                            <h4 className='text-start'>Working with a client?</h4>
                                            <p className='text-start'>When you invite a client to this project, you decide what they can see. When you’re ready to share a message, a to-do, or a file with the client, just flip the switch. Teamnex will keep both sides organized, everyone’s feedback on the record, and all decisions safe and centralized.</p>
                                        </div>
                                        <div>
                                            <button className='btn  camp-cancel secondary-class'>Add a client to this project…
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    );
};

export default SetUpPeople;
