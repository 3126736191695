import React from 'react'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const BodyStyleManager = () => {
    const location = useLocation();
    useEffect(() => {
        const { pathname } = location;
        const isAuthPage =
            pathname === "/login" ||
            pathname === "/signup" ||
            pathname === "/" ||
            pathname === "/forgot-password" ||
            pathname.startsWith("/reset-password") ||
            pathname.startsWith("/verify-email") ||
            pathname.startsWith("/select-organization") ||
            pathname.match(/\/accept-invite/);

        if (isAuthPage) {
            document.body.classList.add("auth-page"); // Add class for auth pages
        } else {
            document.body.classList.remove("auth-page"); // Remove class for other pages
        }

        return () => {
            // Clean up: Reset body styles on component unmount
            document.body.classList.remove("auth-page");
        };
    }, [location]);

    return null;

}

export default BodyStyleManager
