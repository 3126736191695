import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaDropbox, FaFigma, FaFile, FaFolder, FaGoogleDrive, FaInvision, FaPlus, FaSketch } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'react-bootstrap';
import { SiAdobecreativecloud, SiAirtable, SiBox, SiIcloud, SiVerizon, SiWebflow, SiZoho } from 'react-icons/si';
import { IoBookmark, IoDocumentOutline } from 'react-icons/io5';
import { GoClockFill } from "react-icons/go";
import { RiNotionFill } from "react-icons/ri";
import { MdRocketLaunch, MdUpload } from 'react-icons/md';
import { IoIosLink, IoMdCloud } from 'react-icons/io';
import { FaRegBookmark, FaMicrochip } from 'react-icons/fa6';
import { IoMdPhotos, IoMdPeople } from 'react-icons/io';
import { TbHandClick } from "react-icons/tb";
const DocsFiles = () => {
    const toggled = () => setDropdownOpen((prevState) => !prevState);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const openNav = () => {
        setIsOpen(true);
    };

    const closeNav = () => {
        setIsOpen(false);
    };
    return (
        <div className='campfire todo-contain'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <Link to="/project" className="fw-bold">Webnexs HQ</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center" style={{ width: '75vw' }}>
                    <div className="card secondary-class campfireBody">
                        <div className="container ">
                            <div className="d-flex justify-content-between">
                                <div className=" text-start">
                                    <Dropdown toggle={toggled} className=" StuffDw ">
                                        <DropdownToggle className=" StuffDw ">
                                            <button className="newEvent px-2 mt-3 py-1 text-dark">
                                                <FaPlus className='me-1' />New event
                                            </button>
                                        </DropdownToggle>
                                        <DropdownMenu className='StuffDw myDocs p-3 overflow-auto'>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><IoDocumentOutline /></button>Start a new doc</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><FaFolder /></button>Make a new folder</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><MdUpload /></button>Upload files</Link>
                                            </div>
                                            <hr className="line" />
                                            <p className='nameEvent'>Or link to files from…</p>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><SiAdobecreativecloud /></button>Adobe creative cloud</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><SiAirtable /></button>Airtable</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><SiWebflow /></button>TeamNex</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><SiBox /></button>Box</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><FaDropbox /></button>Dropbox</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><FaFigma /></button>Figma</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><FaGoogleDrive /></button>Google Drive</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><SiIcloud /></button>icloud Drive</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><FaInvision /></button>InVision</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><RiNotionFill /></button>Notion</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><IoMdCloud /></button>One Drive</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><FaSketch /></button>Sketch</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><SiZoho /></button>Zoho</Link>
                                            </div>
                                            <div className="p-2">
                                                <Link to="#" className=' text-decoration-none'> <button className=' me-2 fileBtn'><IoIosLink /></button>External Service</Link>
                                            </div>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <div className=" text-center mt-3">
                                    <a href="" className='headcamp fw-bold'> <h3 className="mb-0 t-color1">Docs & Files</h3> </a>
                                </div>
                                <div className=" text-end">
                                    <button className="Notifybtn " onClick={openNav}>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </button>
                                </div>
                            </div>
                            <hr className="line" />
                        </div>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='ms-3 p-4 docsfilesDot'>
                                <FaFile className='' />
                                <p className='fw-bold  text-start'>No files just yet</p>
                                <p className=' text-start'>A centralized place to share docs, spreadsheets,
                                    images, and other files. Organize in folders, share assets with contractors,
                                    and keep tabs on file versions.</p>
                            </div>
                        </div>
                        <div className="" style={{ height: '60vh' }} >
                            <div className="text-start">
                                <div id="mySidenav" className={`CampfireSidenav docsfilesSidenav ${isOpen ? 'open' : ''}`}>
                                    <span className="closebtn" onClick={closeNav}>&times;</span>
                                    <div className="P-2">
                                        <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaRegBookmark /></button>Bookmark</Link>
                                    </div>
                                    <div className="P-2">
                                        <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><TbHandClick /></button>Don't forget</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocsFiles
