import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';

import MessageBoardShow from "../ProjectPage/MessageBoard/MessageBoardShow";
import CampfireShow from "../ProjectPage/CampfireShow";
import ScheduleShow from "../ProjectPage/ScheduleShow";
import TodoShow from "../ProjectPage/TodoShow";
import AutocheckinShow from "../ProjectPage/AutocheckinShow";
import DocumentShow from "../ProjectPage/DocumentShow";
import EmailForwardShow from "../ProjectPage/EmailForwardShow";
import CardTableShow from "../ProjectPage/CardTableShow";
import ProfileCircles from "./ProfileCircles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { BiSolidArchiveIn } from "react-icons/bi";
import { FaBellSlash, FaBoxesStacked } from "react-icons/fa6";
import { BsPinAngleFill } from "react-icons/bs";
import { FiGlobe } from "react-icons/fi";
import { IoIosSettings, IoMdPeople } from "react-icons/io";
import { Cookie } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import Campfire from './Campfire';

const items = [
  {
    username: "Arun Pandiyan",
    profilePic: '',
    title: "Title 1",
    paragraph: "this is sample paragraph to check the messageboard in TeamNex truncation functionality in the message board"
  },
  {
    username: "Pragatheeshwaran",
    profilePic: 'photoo.JPG',
    title: "Title 2",
    paragraph: "Another example paragraph to demonstrate the truncation functionality in the message board."
  },
  {
    username: "balayukesh",
    profilePic: '',
    title: "Title 3",
    paragraph: "This paragraph is short."
  },
  {
    username: "User Four",
    profilePic: 'photoo.JPG',
    title: "Title 4",
    paragraph: "This is another sample paragraph for the message board."
  }
];

const campfireShow = [
  {
    username: "Arun Pandiyan",
    profilePic: 'photoo.JPG',
    title: "Important Marketing Checklists:",
    paragraph: "this is sample paragraph to check the messageboard in TeamNex truncation functionality in the message board"
  },
  {
    username: "Pragatheeshwaran",
    profilePic: 'pic2o.png',
    title: "Guide To Social Media Image Dimensions",
    paragraph: "Another example paragraph to demonstrate the truncation functionality in the message board."
  },
  {
    username: "balayukesh",
    profilePic: 'phooto.JPG',
    title: "Kicking off our HQ",
    paragraph: "Please check for these texts in webnexs."
  },
  {
    username: "User Four",
    profilePic: 'photo.png',
    title: "	Kicking off our HQ",
    paragraph: "This is another sample paragraph for the message board."
  }
];

const acc = [
  {
    id: 1,
    username: "Prakash Kumar",
    profilePic: 'photo.JPG',
    companyName: "Webnexs",
    role: "Web Developer",
    location: "Banglore"
  },
  {
    id: 2,
    username: "Balaji",
    profilePic: null,
    companyName: "Webnexs",
    role: "Web Developer",
    location: "Kerala"
  },
  {
    id: 3,
    username: "Arun Kmumar",
    profilePic: null,
    companyName: "Webnexs",
    role: "Web Developer",
    location: "chennai"
  }
];



const ProjectCard = () => {
  const { id } = useParams(); // Extract project ID from the URL params
  const [projectData, setProjectData] = useState(null); // Store fetched project data
  const [isOpen, setIsOpen] = useState(false); // Manage sidebar state
  const [error, setError] = useState(null); // Handle any errors during the fetch
  const [cookies] = useCookies(['token']);
  const location = useLocation();
  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));

  // Function to open sidebar
  const openNav = () => {
    setIsOpen(true);
  };

  // Function to close sidebar
  const closeNav = () => {
    setIsOpen(false);
  };

  // Fetch project data from the backend
  useEffect(() => {
    const fetchProject = async () => {
      const token = userData?.token || cookies.token;; // Retrieve token from localStorage or any other storage
      if (!token) {
        setError('No token found. Please log in.');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/project/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Attach the token in the Authorization header
          }
        });

        const data = await response.json();

        if (response.ok) {
          // console.log(data.project)
          setProjectData(data.project);
        } else {
          setError(data.message || 'Failed to load project');
        }
      } catch (err) {
        console.error('Error fetching project:', err);
        setError(err.message || 'Error fetching project');
      }
    };

    fetchProject();
  }, [id]);

  if (error) {
    return <div>Error: {error}</div>; // Handle any errors
  }


  return (
    <div className='Projectborder container project secondary-class'>
      <div>
        <div id="mySidenav" className={`sidenav ${isOpen ? 'open' : ''}`}>
          <span className="closebtn" onClick={closeNav}>&times;</span>

          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><BsPinAngleFill /></button>Pin to Home</Link>
          </div>
          <div className="P-2">
            <Link to="/justfollow" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaBellSlash /></button>Switch to just following</Link>
          </div>
          <div className="P-2">
            <Link to="/projectsettings" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><IoIosSettings /></button>Edit project settings</Link>
          </div>
          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaBoxesStacked /></button>Set up tools</Link>
          </div>
          <div className="P-2">
            <Link to="people/users/edit" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><IoMdPeople /></button>Set up people</Link>
          </div>
          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><BiSolidArchiveIn /></button>Archive or delete</Link>
          </div>
          <hr className="line" />
          <p className=' sideEvent ps-2 m-0'>For developers</p>
          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FiGlobe /></button>Set up webhooks</Link>
          </div>

        </div>
      </div>

      <div className='projectheader'>
        <div className="d-flex justify-content-end">
          <button className="projeccardtBtn  mt-2" onClick={openNav}>
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>
        </div>
        {/* Display dynamic project name */}
        <h3 className='text-center fs-4 fw-bold  headcamp'>{projectData?.name}</h3>
        <h4 className=' fs-6 fw-normal text-center'>
          {projectData?.description}
        </h4>

        {/* Display members or other project details */}
        <div className="d-flex flex-column d-flex justify-content-center flex-md-row setupbtn">
          <Link to="people/users/edit" className=" text-decoration-none sbtn t-color1">Set up people</Link>
          <div className="d-flex justify-content-center justify-content-md-start mt-2 ps-md-4">
            {/* <ProfileCircles accounts={projectData?.members} /> */}
            {projectData?.members && <ProfileCircles accounts={projectData.members} />}
          </div>
        </div>
      </div>

      {/* Project tools and other sections */}
      <div className='d-flex justify-content-center'>

        <div className="row d-flex justify-content-center p-0 w-100 mb-5">
          {/* Campfire */}
          {projectData?.tools?.chat && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div" >
              <Link to="chat" className="text-decoration-none" state={{ projectData }}>
                <CampfireShow projectData={projectData} />
              </Link>
            </div>
          )}

          {/* Messageboard */}
          {projectData?.tools?.messageBoard && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
              <Link to="messageBoard" className="text-decoration-none" state={{ projectData }}>
                <MessageBoardShow projectData={projectData} />
              </Link>
            </div>
          )}

          {/* Schedule */}
          {projectData?.tools?.schedule && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
              <Link to="my/schedules" className="text-decoration-none">
                <ScheduleShow />
              </Link>
            </div>
          )}

          {/* Todo show */}
          {projectData?.tools?.todos && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
              <Link to="todos" className="text-decoration-none">
                <TodoShow />
              </Link>
            </div>
          )}

          {/* Doc & Files */}
          {projectData?.tools?.docsFiles && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
              <Link to="docsfiles" className="text-decoration-none">
                <DocumentShow data={items} />
              </Link>
            </div>
          )}

          {/* Card table */}
          {projectData?.tools?.docsFiles && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
              <Link to="cardtable" className="text-decoration-none">
                <CardTableShow data={items} />
              </Link>
            </div>
          )}

          {/* Auto checkin */}
          <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
            <Link to="autocheckin" className="text-decoration-none">
              <AutocheckinShow data={items} />
            </Link>
          </div>

          {/* Email forward */}
          <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
            <Link to="emailforward" className="text-decoration-none">
              <EmailForwardShow data={items} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;