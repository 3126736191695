import React from 'react'
import { RiKey2Fill } from 'react-icons/ri'
import { Link } from 'react-router-dom'

const ManagePublicItems = () => {
    return (
        <div className='campfire todo-contain'>
            <div className=' text-center d-block m-auto campfire card--head camp secondary-class' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <Link to="/adminland" className="fw-bold"><RiKey2Fill style={{
                        fontSize: "20"
                    }} />Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card " style={{ width: '75vw' }}>
                    <div className="card secondary-class campfireBody">
                        <div className="container ">
                            <div className=" text-center mt-3">
                                <a href="public" className='headcamp fw-bold'> <h3 className="mb-0 ">You haven’t made anything public yet</h3> </a>
                            </div>
                            <hr className="line" />
                        </div>
                        <div className="card-body secondary-class p-0" style={{ height: '80vh', }}>
                            <div className="public-panel ">
                                <p className='text-center'><a href='links'>Public</a> links allow you to send something to someone who’s not part of a Project or Team. Once you’ve turned on the Public Link for something, it will appear here.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManagePublicItems
