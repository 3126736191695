import React, { useState } from "react";
import { RiKey2Fill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; // Make sure axios is installed
import toast from "react-hot-toast";

const SetPermission = () => {

    const pages = [
        "Adminland",
        "Projects",
        "Activity",
        "Todos",
        "MessageBoard",
        "Campfire",

        // "Schedule",
        // "Card table",
        // "New project",
        // "Invite people",
    ];
    const actions = [
        "AdminLand",
        "Projects",
        "Todos",
        "MessageBoard",
        "CampFire",

    ];
    const navigate = useNavigate()
    const handleBack = () => {
        navigate(-2)
    }
    // Handle state for dropdowns and checkboxes
    const [roleName, setRoleName] = useState('');
    const [pagePermissions, setPagePermissions] = useState(
        pages.reduce((acc, page) => {
            acc[page] = "Not Allow";
            return acc;
        }, {})
    );
    const [actionPermissions, setActionPermissions] = useState(
        actions.reduce((acc, action) => {
            acc[action] = { view: false, add: false, edit: false, delete: false };
            return acc;
        }, {})
    );

    // Handle page permission change
    const handlePagePermissionChange = (page, value) => {
        setPagePermissions({ ...pagePermissions, [page]: value });
    };

    // Handle action permission change (view, add, edit, delete)
    const handleActionPermissionChange = (action, permission) => (e) => {
        setActionPermissions((prev) => ({
            ...prev,
            [action]: { ...prev[action], [permission]: e.target.checked },
        }));
    };



    const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
    const organizationId = storedUserData?.user?.organizationId._id;

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!roleName.trim()) {
            toast.error("Please enter a role name");
            return;
        }
        // Prepare the userPermissions object
        const userPermissions = Object.keys(actionPermissions).reduce((acc, action) => {
            acc[action] = actionPermissions[action];
            return acc;
        }, {});

        // Prepare the pageAuth object
        const pageAuth = Object.keys(pagePermissions).reduce((acc, page) => {
            acc[page] = pagePermissions[page];
            return acc;
        }, {});

        // Make API call to create the role
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/roles`, {
                RoleName: roleName,
                userPermissions: userPermissions,
                pageAuth: pageAuth,
                organizationId
            });
            console.log({
                RoleName: roleName,
                userPermissions: userPermissions,
                pageAuth: pageAuth,
                organizationId
            })
            toast.success('New role Added');
            navigate(-1)
            console.log(response.data);
        } catch (error) {
            if (error.response && error.response.status === 409) {
                // alert(error.response.data.error); // Role name already exists
                toast.error("This Role already exist    \n Try Different Role name", {

                })
            } else {
                alert("Error creating role!");
                console.error(error.response ? error.response.data : error.message);
            }
        }
    };

    return (
        <div>
            <div className="campfire todo-contain">
                <div
                    className="text-center d-block m-auto campfire card--head camp"
                    style={{ width: "65vw", height: "6vh" }}
                >
                    <div className="d-flex justify-content-center align-items-center mt-2 h-100">
                        <Link to="/adminland" onClick={handleBack} className="fw-bold">
                            <RiKey2Fill style={{ fontSize: "20px" }} /> Adminland
                        </Link>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center camp">
                    <div className="card" style={{ width: "75vw" }}>
                        <div className="campfireBody">
                            <div className="container">
                                <div className="text-center mt-3">
                                    <a href="#" className="headcamp fw-bold">
                                        <h3 className="mb-0 t-color1">Add New Role</h3>
                                    </a>
                                </div>
                                <hr className="line" />
                            </div>
                            <div className="card-body p-0">
                                <div className="admin-panel t-color1 new-role-con">
                                    <div className="mb-4">
                                        <label className="t-color1">
                                            <h4>
                                                <strong>Role name:</strong>
                                            </h4>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control ipboxx"
                                            placeholder="Role name"
                                            value={roleName}
                                            onChange={(e) => setRoleName(e.target.value)}
                                        />
                                    </div>

                                    {/* Page Authorization */}
                                    <div className="mb-5 rounded">
                                        <h5>Page Authorization</h5>
                                        <div className="set-per-con">
                                            {pages.map((page) => (
                                                <div
                                                    className="d-flex justify-content-between align-items-center mb-2"
                                                    key={page}
                                                >
                                                    <span>{page}</span>
                                                    <select
                                                        className="form-select w-50"
                                                        value={pagePermissions[page]}
                                                        onChange={(e) =>
                                                            handlePagePermissionChange(page, e.target.value)
                                                        }
                                                    >
                                                        <option value="Allow">Allow</option>
                                                        <option value="Not Allow">Not Allow</option>
                                                    </select>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Actions Authorization */}
                                    <div className="mb-4">
                                        <h5>Actions Authorization</h5>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Actions</th>
                                                    <th>View</th>
                                                    <th>Add</th>
                                                    <th>Edit</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {actions.map((action, index) => (
                                                    <tr key={action}>
                                                        <td>{index + 1}</td>
                                                        <td>{action}</td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                checked={actionPermissions[action].view}
                                                                onChange={handleActionPermissionChange(action, "view")}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                checked={actionPermissions[action].add}
                                                                onChange={handleActionPermissionChange(action, "add")}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                checked={actionPermissions[action].edit}
                                                                onChange={handleActionPermissionChange(action, "edit")}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                checked={actionPermissions[action].delete}
                                                                onChange={handleActionPermissionChange(action, "delete")}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Save/Cancel buttons */}
                                    <div className="d-flex justify-content-end mt-4 ">
                                        <button className="camp-cancel secondary-class" onClick={handleBack}>Cancel</button>
                                        <button
                                            className="rol-save-btn text-thin"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetPermission;
