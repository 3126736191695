import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include the styles for the editor
import EmojiPicker from 'emoji-picker-react';
import { FaA } from "react-icons/fa6";
import { BsEmojiSmile } from "react-icons/bs";
import { IoIosAttach } from "react-icons/io";
import io from 'socket.io-client'
import { useParams, useLocation } from 'react-router-dom'; // Import useLocation to access passed data
import Clock from 'react-live-clock';

const PrivateChatinPing = () => {

    const { chatId } = useParams();
    const location = useLocation(); // Get state from navigation
    const { userName, userProfilePhoto } = location.state || {}; // Destructure passed state

    const [editorVisible, setEditorVisible] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Emoji picker visibility
    const [attachment, setAttachment] = useState(null); // File attachment
    const emojiPickerRef = useRef(null); // Ref for the emoji picker

    // Quill toolbar configuration for formatting options
    const toolbarOptions = [
        ['bold', 'italic', 'strike'], // Formatting options
        [{ list: 'ordered' }, { list: 'bullet' }], // Ordered and unordered lists
        ['link'], // Link option
        [{ 'color': [] }, { 'background': [] }],
        ['blockquote', 'code-block'],
        ['clean']
    ];

    const senderId = '66d594d6905fb32a7c161018';

    useEffect(() => {
        if (chatId) {
            joinChat(chatId);
        }
    }, [chatId]);

    // Handle emoji click
    const handleEmojiClick = (emojiData) => {
        // You can update any state or functionality here as needed
        setMessage(message + emojiData.emoji)
    };

    // Handle file change
    const handleFileChange = (event) => {
        setAttachment(event.target.files[0]);
    };

    // Handle clicks outside of the emoji picker
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getInitials = (name) => {
        const nameArray = name.split(' ');
        return nameArray.map(n => n.charAt(0)).join('').toUpperCase();
    };

    // Client side Socket.io integration
    const socket = io('ws://localhost:3334');

    const joinChat = (chatId) => {
        socket.emit("joinChat", chatId);
    };

    useEffect(() => {
        socket.on("messageReceived", (newMessage) => {
            setMessages((prevMessages) => [...prevMessages, newMessage]);
        });

        return () => {
            socket.off("messageReceived");
        };
    }, [socket]);

    const sendMessage = () => {
        if (message.trim()) {
            const newMessage = { senderId, content: message, timestamp: new Date() };
            socket.emit("sendMessage", { chatId, senderId, content: message });
            console.log("Emitting message with:", { chatId, senderId, content: message });
            setMessages([...messages, newMessage]);
            setMessage(""); // Clear the input after sending
        }
    };

    // List of messages
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        sendMessage();
    };

    return (
        <div className="chat-container">
            <div className="chat-header">
                {userProfilePhoto ? (
                    <img
                        src={`http://localhost:3334/uploads/${userProfilePhoto}`}
                        alt="User Profile"
                        style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 10 }}
                    />
                ) : (
                    <span
                        style={{
                            width: 40,
                            height: 40,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%',
                            backgroundColor: '#4caf50',
                            color: '#fff',
                            marginRight: 10,
                            fontSize: '16px',
                        }}
                    >
                        {getInitials(userName)}
                    </span>
                )}
                <div className='chat-header-name'>
                    <h5 className='mb-0 fw-bold'>{userName}</h5>
                    <div className='chat-assignment-link'>
                        <a href=''>Assignments</a>
                        <a href=''>Activity</a>
                        <span>
                            <Clock format={'h:mm a'} />
                        </span>
                        <p>IST</p>
                    </div>
                </div>
                <hr />
            </div>

            <div className='pingMsg'>

                <div className="chat-box" >
                    {messages.map((msg, index) => (
                        <div key={index} className={`message ${msg.senderId === senderId ? "my-message" : ""}`}>
                            <div className='msg-bg d-flex justify-content-end'> <div className='d-inline  message-card'>
                                <span className='fw-light ' >{new Date(msg.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} </span>
                                <strong>{msg.senderId === senderId ? "Me" : `User ${msg.senderId}`}</strong>
                                <p>{msg.content}</p>
                            </div>
                            </div>
                        </div>
                    ))}
                </div>

                <form onSubmit={handleFormSubmit}>
                    <div style={{ margin: '0 auto' }} className='px-3 py-0'>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', background: '#2c3e50', paddingRight: "10px", borderRadius: '5px', border: '2px solid #32526c' }}>
                            {editorVisible ? (
                                <ReactQuill
                                    modules={{ toolbar: toolbarOptions }}
                                    style={{ flexGrow: 1, minHeight: '100px' }} // Flex to take up input space
                                />
                            ) : (
                                <input
                                    type="text"
                                    value={message}
                                    onChange={handleInputChange}
                                    style={{
                                        flexGrow: 1,
                                        padding: '10px',
                                        border: 'none',
                                        background: 'transparent',
                                        color: 'white',
                                        outline: 'none',
                                        fontSize: '16px',
                                    }}
                                />
                            )}

                            <div className='ping-input-container'>
                                <button
                                    type="button"
                                    onClick={() => setEditorVisible(!editorVisible)}
                                    style={{ background: 'transparent', color: '#fff', border: 'none', fontSize: '16px', cursor: 'pointer' }}
                                >
                                    <FaA className='editorIcon' />
                                </button>

                                <button type="button" onClick={() => setShowEmojiPicker(!showEmojiPicker)} className='emojiBtn'>
                                    <BsEmojiSmile className='emojiIcon' />
                                </button>

                                <input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="file-upload" />
                                <label htmlFor="file-upload" style={{ cursor: 'pointer', fontSize: "larger" }} className='m-0'>
                                    <IoIosAttach />
                                </label>
                            </div>
                        </div>

                        {showEmojiPicker && (
                            <div ref={emojiPickerRef} style={{ position: 'absolute', zIndex: 1000 }}>
                                <EmojiPicker onEmojiClick={handleEmojiClick} />
                            </div>
                        )}

                        {attachment && (
                            <div style={{ marginTop: '10px', color: '#fff' }}>
                                <strong>Attachment:</strong> {attachment.name}
                            </div>
                        )}
                        <button type="submit" className='d-none'>Send</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PrivateChatinPing;
