// import axios from "axios";

// export const getUser = async () => {
//     try {
//         const response = await axios.get(`${process.env.REACT_APP_API_URL}/register/user`);
//         return response.data;
//     } catch (error) {
//         console.error("Error fetching user details:", error);
//         throw error;
//     }
// }
