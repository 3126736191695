// import React, { useState } from "react";
// import PasswordForm from "./PasswordFrom";
// import axios from "axios";

// const Signup = () => {
//   const [formData, setFormData] = useState({
//     username: "",
//     email: "",
//     companyname: "",
//     password: "",
//   });
//   const [error, setError] = useState(null);
//   const [isLoading, setLoading] = useState(false);
//   const [isSign, setSignUp] = useState(false);
//   const [invalidEmail, setInvalidEmail] = useState(false);
//   const [invalidCompany, setInvalidCompany] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const validateEmail = (email) => {
//     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return re.test(String(email).toLowerCase());
//   };

//   const validateCompanyName = (companyname) => {
//     return companyname.trim().length > 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     const emailValid = validateEmail(formData.email);
//     const companyValid = validateCompanyName(formData.companyname);

//     setInvalidEmail(!emailValid);
//     setInvalidCompany(!companyValid);

//     if (!emailValid || !companyValid) {
//       let errorMessage = "";
//       if (!emailValid) errorMessage += "Invalid email format. ";
//       if (!companyValid) errorMessage += "Organization name is required.";
//       setError(errorMessage);
//       setLoading(false);
//       return;
//     }

//     try {
//       setError(null);

//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/register/checkmail`,
//         { email: formData.email });
//       console.log(response.data)

//       if (response.data.success === false) {
//         setError("This email is already registered. Please use another email.");
//       }

//       if (response.data.status === true) {
//         setSignUp(true);
//       }
//     } catch (err) {
//       setError("An unexpected error occurred. Please try again.");
//       console.log("this is error :", err.response)
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fakeApiCallForSignup = async (data) => {
//     if (data.email === "already@registered.com") {
//       return { emailExists: true };
//     }
//     return { emailExists: false };
//   };



//   return isSign ? (
//     <PasswordForm formData={formData} setFormData={setFormData} />
//   ) : (
//     <div className="d-flex justify-content-center align-items-center signup">
//       <div
//         className="Loginpage text-center"
//         style={{ maxWidth: "500px", width: "100%" }}
//       >
//         <img
//           src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public'
//           alt="Logo"
//           className="d-block m-auto"
//           style={{ width: "100px" }}
//         />
//         <h5 className="card-title text-center mb-3 text-black">Try TeamNex for free</h5>
//         <h3 className="text-center card-title text-m text-black">
//           No credit card required • Cancel anytime
//         </h3>
//         <div className="card p-4 mt-4 b-shad">
//           <div className="card-body">
//             <h3 className="text-center card-title text-mm mb-4 text-black">
//               Just need a few things to get you going
//             </h3>
//             <form onSubmit={handleSubmit}>
//               <div className="form-group">
//                 <input
//                   type="text"
//                   className="form-control mb-2"
//                   placeholder="Your name"
//                   id="username"
//                   name="username"
//                   value={formData.username}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//               <div className="form-group">
//                 <input
//                   type="email"
//                   className={`form-control mb-2 ${invalidEmail ? 'is-invalid' : ''}`}
//                   placeholder={invalidEmail ? "Please enter a valid email" : "Your email (e.g. julie.young@hey.com)"}
//                   id="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   required
//                   style={{ color: invalidEmail ? "black" : "black" }}
//                 />
//                 {error}
//                 {invalidEmail && <small className="text-danger">Invalid email format</small>}
//               </div>
//               <div className="form-group">
//                 <input
//                   type="text"
//                   className={`form-control mb-2 ${invalidCompany ? 'is-invalid' : ''}`}
//                   placeholder={invalidCompany ? "Please enter your organization name" : "Your organization's name"}
//                   id="companyname"
//                   name="companyname"
//                   value={formData.companyname}
//                   onChange={handleChange}
//                   required
//                   style={{ color: invalidCompany ? "black" : "black" }}
//                 />
//                 {invalidCompany && <small className="text-danger">Organization name is required</small>}
//               </div>
//               <button
//                 type="submit"
//                 className="btn btn-success btn-block mt-3 w-100"
//                 disabled={isLoading}
//               >
//                 {isLoading ? "Loading..." : "Last step: you’ll set a password"}
//               </button>
//               {error && <p className="text-danger mt-2">{error}</p>} {/* Display general form error */}
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Signup;
import React, { useState } from "react";
import PasswordForm from "../Authentication/PasswordFrom";
import axios from "axios";

const Signup = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    companyname: "",
    password: "",
  });

  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isSignup, setSignUp] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidCompany, setInvalidCompany] = useState(false);
  const [exist, setExist] = useState(false)
  // Input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Email validation
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Company name validation
  const validateCompanyName = (companyname) => {
    return companyname.trim().length > 0;
  };

  // Form submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate inputs
    const emailValid = validateEmail(formData.email);
    const companyValid = validateCompanyName(formData.companyname);

    setInvalidEmail(!emailValid);
    setInvalidCompany(!companyValid);
    setExist(error === "Email already exists , Try any other email")
    if (!emailValid || !companyValid) {
      let errorMessage = "";
      if (!emailValid) errorMessage += "Invalid email format. ";
      if (!companyValid) errorMessage += "Organization name is required.";
      setError(errorMessage);
      setLoading(false);
      return;
    }

    try {
      setError(null); // Clear previous errors

      // API call to check email
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register/checkmail`, {
        email: formData.email,
      });

      const { success, message } = response.data;

      if (!success) {
        setError(message); // Show error from the backend (e.g., "Email already exists")
      }
      if (success === true) {
        setSignUp(true); // Navigate to the password form if email is available
      }
    } catch (err) {
      // Handle server error
      console.error("Error checking email:", err.response || err);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false); // Reset loading state
    }
  };
  console.log(isSignup)
  return isSignup ? (
    <PasswordForm formData={formData} setFormData={setFormData} />
  ) : (
    <div className="d-flex justify-content-center align-items-center signup">
      <div
        className="Loginpage text-center"
        style={{ maxWidth: "500px", width: "100%" }}
      >
        <img
          src="https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public"
          alt="Logo"
          className="d-block m-auto"
          style={{ width: "100px" }}
        />
        <h5 className="card-title text-center mb-3 text-black">
          Try TeamNex for free
        </h5>
        <h3 className="text-center card-title text-m text-black">
          No credit card required • Cancel anytime
        </h3>
        <div className="card p-4 mt-4 b-shad">
          <div className="card-body">
            <h3 className="text-center card-title text-mm mb-4 text-black">
              Just need a few things to get you going
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Your name"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className={`form-control mb-2 ${exist ? "is-invalid" : ""} ${invalidEmail ? "is-invalid" : ""}`}
                  placeholder={
                    invalidEmail
                      ? "Please enter a valid email"
                      : "Your email (e.g. julie.young@hey.com)"
                  }
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  style={{ color: invalidEmail ? "black" : "black" }}
                />
                {exist && <p className="text-danger">{error}</p>}
                {invalidEmail && (
                  <small className="text-danger">Invalid email format</small>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control mb-2 ${invalidCompany ? "is-invalid" : ""
                    }`}
                  placeholder={
                    invalidCompany
                      ? "Please enter your organization name"
                      : "Your organization's name"
                  }
                  id="companyname"
                  name="companyname"
                  value={formData.companyname}
                  onChange={handleChange}
                  required
                  style={{ color: invalidCompany ? "black" : "black" }}
                />
                {invalidCompany && (
                  <small className="text-danger">
                    Organization name is required
                  </small>
                )}
              </div>
              <button
                type="submit"
                className="btn btn-success btn-block mt-3 w-100"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Last step: you’ll set a password"}
              </button>
              {/* {error && <p className="text-danger mt-2">{error}</p>} */}
            </form>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Signup;
