import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import toast from "react-hot-toast";
import Select from 'react-select';


const CreateProject = () => {
  const user = [
    {
      // label: "Arun Pandiyan",
      username: "Arun Kumar",
      profilePic: null
    },
    {
      username: "Prakash Kumar",
      profilePic: null
    },
    {
      username: "Ganesh",
      profilePic: null
    }
  ];
  const location = useLocation();
  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
  const [showInputs, setShowInputs] = useState(false);
  const [switchStates, setSwitchStates] = useState({
    messageBoard: true,
    todos: true,
    docsFiles: true,
    chat: true,
    schedule: true,
    cardTable: true,
    trackTime: false,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [invites, setInvites] = useState("");
  const [inviteNote, setInviteNote] = useState("");
  const [message, setMessage] = useState("");


  const navigate = useNavigate();
  const organizationId = userData ? userData.user.organizationId._id : "";

  const handleSwitchChange = (name) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleOptionsClick = () => {
    setShowOptions(!showOptions);
  };

  const handleShowNoteClick = () => {
    setShowNotes(!showNotes);
  };

  const [projectVisibility, setProjectVisibility] = useState("inviteOnly");
  const [projectAllAccess, setProjectAllAccess] = useState(""); // To handle nested radio options

  const handleVisibilityChange = (e) => {
    const selectedVisibility = e.target.value;
    setProjectVisibility(selectedVisibility);

    // If All-access is selected, set a default nested option (e.g., teamVisible)
    if (selectedVisibility === "allAccess") {
      setProjectAllAccess("teamVisible");
    }
  };

  const handleAllAccessChange = (e) => {
    setProjectAllAccess(e.target.value);
  };


  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleCreate = (options) => {
    setSelectedUsers(options);
  };
  const formatOptionLabel = ({ username, profilePhoto }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {profilePhoto ? (
        <img
          src={profilePhoto}
          alt='as'
          style={{ width: 24, height: 24, borderRadius: '50%', marginRight: 10 }}
        />
      ) : (
        <span
          style={{
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            backgroundColor: '#4caf50', // Background color for initials
            color: '#fff', // Text color for initials
            marginRight: 10,
            fontSize: '12px',
          }}
        >
          {getInitials(username)}
        </span>
      )}
      {username}
    </div>
  );
  const toolbarOptions = [
    ['bold', 'italic', 'strike'], // Formatting options
    [{ list: 'ordered' }, { list: 'bullet' }], // Ordered and unordered lists
    ['link'], // Link option
    [{ 'color': [] }, { 'background': [] }],
    ['blockquote', 'code-block'],
    ['clean']
  ];
  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const projectData = {
      name,
      description,
      startDate: startDate ? startDate.toISOString().split('T')[0] : null,
      endDate: endDate ? endDate.toISOString().split('T')[0] : null,
      organizationId,
      tools: switchStates,
      inviteOnly: true,
      members: invites.split(',').map(member => member.trim()).filter(member => member),
      // members: selectedUsers.map((user) => user.username),

    };

    try {
      const token = userData.token;
      if (!token) {
        console.error('Token is undefined or not found in localStorage');
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/project`, projectData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Project created successfully!');
      navigate(`/${organizationId}`);
    } catch (error) {
      console.error('Error creating project:', error);
      toast.error('Error creating project. Please try again.');
    }
  };
  const getInitials = (name) => {
    const nameArray = name.split(' ');
    const initials = nameArray.map(n => n.charAt(0)).join('').toUpperCase();
    return initials;
  };
  return (
    <div className="container create-project-container">
      <h2 className="fs-1 fw-bold t-color1 text-center mb-4">Make a new project</h2>
      <div className="create-project">
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div className="card-container pb-5 project-contain secondary-class">
              <div className="ps-5 pe-2 pt-5 cre-pro-ress">
                <div className="cre-pro-con">
                  <h2 className="fs-4 fw-bold d-flex align-items-center cre-pro-head">
                    <span className="me-3 sno">1</span>Start with the basics
                  </h2>
                  <div className="d-flex justify-content-between pro-label mt-3">
                    <h4 className="fs-6 fw-bold ps-4">Name of the Project</h4>
                    <Link to="/view-template" className="fs-6">Create a project template</Link>
                  </div>
                  <input
                    type="text"
                    className="proinput ms-4 px-2"
                    placeholder="e.g. Office Renovation"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <h4 className=" fs-6 fw-bold ps-4 pt-4">Add a description (Optional)</h4>
                  <input
                    type="text"
                    className="proinput ms-4 px-2 pb-5"
                    placeholder="e.g. Plans and scheduling for expanding the office"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <h4 className=" fs-6 fw-bold ps-4 pt-4">Set the schedule (optional)</h4>
                  <Link to="#" className="ps-4 text-secondary" onClick={() => setShowInputs(!showInputs)}>
                    Add start/end dates
                  </Link>
                  {showInputs && (
                    <div className="sm w-75">
                      <div className="mt-3 d-flex ps-5">
                        <div className="form-group pe-3">
                          <label htmlFor="startDate" className=" fs-6 fw-bold">Start Date</label>
                          <br />
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            placeholderText="Choose start date"
                            className="dateinput px-2"
                          />
                        </div>
                        <div className="form-group ps-5">
                          <label htmlFor="endDate" className=" fs-6 fw-bold">End Date</label>
                          <br />
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            placeholderText="Choose end date"
                            className="dateinput px-2"
                          />
                        </div>
                      </div>
                      <Link to="#" className="ps-5 text-secondary pt-2" onClick={() => setShowInputs(!showInputs)}>
                        Remove date
                      </Link>
                    </div>
                  )}
                  <h4 className=" fs-6 fw-bold ps-4 pt-4 sm w-75">Track time on this project (optional)</h4>
                  <div className="pt-2 ps-4">
                    <BootstrapSwitchButton
                      checked={switchStates.trackTime}
                      onlabel="On"
                      offlabel="Off"
                      onstyle="switch-on"
                      offstyle="switch-off"
                      onChange={() => handleSwitchChange("trackTime")}
                      className="custom-switch"
                    />
                  </div>

                  <h2 className="fs-4 fw-bold  d-flex align-items-center pt-3 cre-pro-head">
                    <span className="me-3 sno">2</span>Choose your tools
                  </h2>
                  <span className="text-secondary fs-6 ps-4">You can always change tools later</span>
                  <div className="row d-flex justify-content-start no-gap mt-3">
                    {[{ name: "Message Board", description: "Post announcements, pitch ideas, progress updates, etc. and keep feedback on-topic", key: "messageBoard" },
                    { name: "To-dos", description: "Make lists of work that needs to get done, assign items, set due dates, and discuss.", key: "todos" },
                    { name: "Docs & Files", description: "Share docs, files, images, and spreadsheets. Organize in folders so they’re easy to find.", key: "docsFiles" },
                    { name: "Chat", description: "Chat casually with the group, ask random questions, and share stuff without ceremony.", key: "chat" },
                    { name: "Schedule", description: "Set important dates on a shared schedule. Subscribe to events in Google Cal, iCal, or Outlook.", key: "schedule" },
                    { name: "Card Table", description: "A visual, Kanban-like tool for process-oriented work. Establish a workflow and move cards.", key: "cardTable" },
                    ].map((tool) => (
                      <div className="col-12 col-lg-4 col-md-6 col-sm-6 toolsbox  text-center" key={tool.key}>
                        <h1 className="pt-2 text-center fs-5 fw-bold ">{tool.name}</h1>
                        <div className="my-2">
                          <BootstrapSwitchButton
                            checked={switchStates[tool.key]}
                            onlabel="On"
                            offlabel="Off"
                            onstyle="switch-on"
                            offstyle="switch-off"
                            onChange={() => handleSwitchChange(tool.key)}
                            className="custom-switch"
                          />
                        </div>
                        <p className=" text-align-justify m-0 lh-sm">
                          {tool.description}
                        </p>
                      </div>
                    ))}
                  </div>

                  <h2 className="fs-4 fw-bold  d-flex align-items-center pt-3 cre-pro-head">
                    <span className="me-3 sno">3</span>Choose who’s on the project
                  </h2>
                  {/* <p className="text-secondary ps-2 w-75 ms-3">
                    This project is <b>Invite-only</b>. Only people who are explicitly invited can see this project{" "}
                    <Link className="text-secondary" onClick={handleOptionsClick}><span className="">(change)</span></Link>
                  </p>
                  {showOptions && (
                    <div className="ps-4">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="projectVisibility" id="inviteOnly" defaultChecked />
                        <label className="form-check-label text-secondary" htmlFor="inviteOnly">
                          Invite-only <span>Only people invited to this project can view</span>
                        </label>
                      </div>
                      <div className="form-check pt-3">
                        <input className="form-check-input" type="radio" name="projectVisibility" id="teamVisible" />
                        <label className="form-check-label text-secondary" htmlFor="teamVisible">
                          Visible to anyone on the team <span>Everyone on your team can view this project</span>
                        </label>
                      </div>
                    </div>
                  )} */}
                  <p className="text-secondary ps-2 w-75 ms-3">
                    This project is <b>{projectVisibility === "inviteOnly" ? "Invite-only" : "All-access"}</b>.{" "}
                    {projectVisibility === "inviteOnly"
                      ? "Only people who are explicitly invited can see this project"
                      : "In addition to people who are explicitly invited..."}
                    <Link className="text-secondary" onClick={handleOptionsClick}>
                      <span className="">(change)</span>
                    </Link>
                  </p>

                  {showOptions && (
                    <div className="ps-4 cre-pro-rad">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="projectVisibility"
                          id="inviteOnly"
                          value="inviteOnly"
                          checked={projectVisibility === "inviteOnly"}
                          onChange={handleVisibilityChange}
                        />
                        <label className="form-check-label text-secondary" htmlFor="inviteOnly">
                          <b className="">Invite-only</b>
                          <div className="">Only people who are explicitly invited can see this project</div>
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="projectVisibility"
                          id="allAccess"
                          value="allAccess"
                          checked={projectVisibility === "allAccess"}
                          onChange={handleVisibilityChange}
                        />
                        <label className="form-check-label text-secondary mb-0" htmlFor="allAccess">
                          <b className="">All-access</b>
                          <div className="">In addition to people who are explicitly invited...</div>
                        </label>

                        {projectVisibility === "allAccess" && (
                          <div className="ps-4">
                            <div className="form-check ">
                              <input
                                className="form-check-input cre-pro-rad"
                                type="radio"
                                name="projectAllAccess"
                                id="teamVisible"
                                value="teamVisible"
                                checked={projectAllAccess === "teamVisible"}
                                onChange={handleAllAccessChange}
                              />
                              <label className="form-check-label text-secondary m-0" htmlFor="teamVisible">
                                Anyone from Webnexs can see this project
                              </label>
                            </div>

                            <div className="form-check ">
                              <input
                                className="form-check-input cre-pro-rad"
                                type="radio"
                                name="projectAllAccess"
                                id="accountVisible"
                                value="accountVisible"
                                checked={projectAllAccess === "accountVisible"}
                                onChange={handleAllAccessChange}
                              />
                              <label className="form-check-label text-secondary m-0" htmlFor="accountVisible">
                                Anyone on the account (except clients) can see
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {/* <div>
                    <input className="proinput ms-4 px-2 mt-2" placeholder="Type their names here..."></input>
                    <p className="ps-4 text-secondary">You can always invite people later.</p>
                  </div> */}
                  <div className="ms-4 mt-2 cre-select-user">
                    <Select
                      closeMenuOnSelect={false}
                      className="  proinput"
                      // components={animatedComponents}
                      placeholder="Type their names here..."
                      isMulti
                      options={user} // Make sure the 'users' array contains your options with _id and label
                      getOptionValue={(option) => option.username}
                      onChange={handleCreate} // handleCreate should update the state for invited users
                      formatOptionLabel={formatOptionLabel} // Ensure this function formats the option correctly
                    />
                    <p className="ps-4 text-secondary">You can always invite people later.</p>
                  </div>

                  <Link to="#" className="ps-4  text-decoration-none fw-bold" onClick={handleShowNoteClick}>
                    Add a note to the invitation email
                  </Link>
                  {showNotes && (
                    <div>
                      <textarea
                        className="proinput ms-4 px-2 pb-5 mt-2"
                        placeholder="Type your note here..."
                        value={inviteNote}
                        onChange={(e) => setInviteNote(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="Projectbtn">Create Project</button>
          </div>
          <div className="d-flex justify-content-center">
            <p className=" text-center w-50 mt-3 create-project-notes">
              <strong>
                What happens next?  </strong>
              We'll drop you right on your project so you can get started.
              Anyone invited will be notified so they can join in.
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateProject;
