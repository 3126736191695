import axios from 'axios';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

function InviteRegister() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const navigate = useNavigate();
    const handleNewLoginClick = () => {
        navigate('new-login'); // Replace '/new-login' with your desired route
    };

    const handleExistingLoginClick = () => {
        navigate('login'); // Replace '/existing-login' with your desired route
    };
    return (
        <div className="d-flex justify-content-center align-items-center Forgot invite-register">
            <div className='Loginpage text-center' style={{ maxWidth: '448px', width: '100%' }}>
                <img
                    src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public'
                    alt="Logo"
                    className="d-block m-auto"
                    style={{ width: "100px" }}
                />

                <div className="card p-3 mt-2 b-shad text-black" >
                    <div className="card-body ">
                        <h2 className="h5 mt-0 mb-3 text-center fw-bold text-black">Join Organization on Teamnex</h2>
                        <div className='d-flex flex-column justify-content-around height'>
                            <button className='action-btn py-2' onClick={handleNewLoginClick}>Make a new login with my email</button>
                            <div className='d-flex align-items-center t-color1' >
                                <hr style={{ flex: 1, margin: '0 10px' }} />
                                <div class="separator text-black">Or, if you’ve used TeamNex before</div>
                                <hr style={{ flex: 1, margin: '0 10px' }} />
                            </div>
                            <button className='action-btn py-2 btn-success' onClick={handleExistingLoginClick}>Log in and join with my account</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default InviteRegister
