import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faEllipsisH, faFont, faSmile, faPaperclip, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaRegBookmark, FaMicrochip } from 'react-icons/fa6';
import { IoMdPhotos, IoMdPeople } from 'react-icons/io';
import { TbHandClick } from "react-icons/tb";
import EmojiPicker from 'emoji-picker-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import socket from '../../socket/socket';
import axios from 'axios';
import asset from '../asset/pdf.jpeg'
import { IoIosMore } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaHandPointer } from "react-icons/fa";
import { CiBookmark } from "react-icons/ci";
import { IoIosLink } from "react-icons/io";
import { MdOutlineReply } from "react-icons/md";
import toast, { Toaster } from 'react-hot-toast';
const Campfire = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const projectData = location.state?.projectData;
  const { id: projectId } = useParams()
  const fileInputRef = useRef(null);
  const [userProfiles, setUserProfiles] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [messages, setMessages] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);

  const [reactions, setReactions] = useState({});
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  const [replyingTo, setReplyingTo] = useState(null);
  const [menuPosition, setMenuPosition] = useState("below");

  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
  const senderId = userData ? userData?.user?._id : null

  const inputRef = useRef(null);

  const handleReplyClick = (msg) => {
    setReplyingTo(msg);
    setMenuOpen({})
  };

  const startEditing = (message) => {
    setEditingMessageId(message._id);
    setMenuOpen({})
    setEditedContent(message.content);  // Load existing content
  };

  const saveEdit = (messageId) => {
    const updatedMessage = {
      _id: messageId,
      content: editedContent, // the edited content from ReactQuill
    };
    socket.emit('updateMessage', updatedMessage);
    // Update the message in local state
    console.log("Message updated ", updatedMessage)
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg._id === messageId ? { ...msg, content: editedContent } : msg
      ));
    setEditingMessageId(null); // Close editor
  };


  const toggleEmojiPicker = () => setShowEmojiPicker((prev) => !prev); // Toggle open/close

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const token = userData.token;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/project/${projectId}/messages`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        // console.log(projectId)
        setMessages(response.data); // Assuming response.data is an array of messages

        // console.log(response.data)
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
    if (projectId) {
      socket.emit('joinProjectChat', projectId);
      console.log(`Joined project chat room: ${projectId}`);
    }

    socket.on('receiveProjectMessage', (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });
    socket.on('messageDeleted', ({ messageId }) => {
      setMessages((prevMessages) => prevMessages.filter((msg) => msg._id !== messageId));
      console.log("Message deleted:", messageId);
    });

    socket.on('reactionUpdated', ({ messageId, reactions }) => {
      // Update the message's reactions in the state
      updateMessageReactions(messageId, reactions);
    });
    return () => {
      socket.off('receiveProjectMessage');
      socket.off('messageDeleted');
      socket.off('reactionUpdated');
    };
  }, [projectId]);

  const updateMessageReactions = (messageId, newReactions) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg._id === messageId ? { ...msg, reactions: newReactions } : msg
      )
    );
  };
  const messageEndRef = useRef(null);
  //Use react virtualize for million messages
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleBack = () => navigate(-1);

  const handleEmojiClick = (emojiData) => {
    setMessage((prev) => prev + emojiData.emoji);
    setShowEmojiPicker(false)
    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  };

  const handleReactionClick = (msgId, emoji) => {
    const userReacted = messages.find((msg) => msg._id === msgId)
      ?.reactions.find((r) => r.emoji === emoji)?.users.includes(senderId);

    const event = userReacted ? 'removeReaction' : 'addReaction';

    socket.emit(event, {
      messageId: msgId,
      senderId: senderId,
      emoji,
      projectId,
    });
    setMenuOpen({})
  };
  // const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);
  const toggleEditor = () => setShowEditor(!showEditor) && setMessage("");

  const sendMessage = () => {
    if (!message.trim()) return; // Prevent sending empty messages

    // Remove any <div> tags if they're added

    // Emit the message to the backend
    socket.emit('sendProjectMessage', {
      projectId, senderId, username: userData?.user?.username, // Include sender's username
      profilePicture: userData?.user?.profilePicture, content: message, replyTo: replyingTo,
    });
    console.log("Message sent:", { projectId, senderId, content: message, replyTo: replyingTo });
    setMessage(""); // Reset the message after sending
    setReplyingTo(null)
    // setShowEditor(!showEditor)
  };

  const handleButtonClick = () => fileInputRef.current.click();

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
          headers: {
            'Authorization': `Bearer ${userData.token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        const fileUrl = response.data.fileUrl; // Assuming API returns the uploaded file URL
        console.log(fileUrl)
        // Emit the message with the file URL to the server
        socket.emit('sendProjectMessage', {
          projectId,
          senderId,
          username: userData?.user?.username,
          profilePicture: userData?.user?.profilePicture,
          content: fileUrl // Send the file URL directly
        });

      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };


  const fetchSenderProfile = async () => {
    if (!userProfiles[senderId]) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/register/users/${senderId}`);
        setUserProfiles((prevProfiles) => ({
          ...prevProfiles,
          [senderId]: response.data,
        }));
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    }
  };
  useEffect(() => {
    const uniqueSenderIds = [...new Set(messages.map(msg => msg.senderId))];
    uniqueSenderIds.forEach(senderId => fetchSenderProfile(senderId));
  }, [messages]);

  function getInitials(name = "") {
    if (!name) return "?"; // Fallback to "?" if name is undefined or empty.
    const initials = name
      .split(" ")
      .map(part => part.charAt(0).toUpperCase())
      .join("");
    return initials;
  }
  const toggleMenu = (messageId) => {
    setMenuOpen((prev) => (prev === messageId ? null : messageId));
    setEditingMessageId(null);
  };

  const deleteMessage = (messageId) => {
    if (window.confirm("Are you sure you want to delete this message?")) {

      socket.emit('deleteProjectMessage', { projectId, messageId });
    }
    console.log(messageId)
  };

  const iconRef = useRef(null);

  // Calculate menu position based on available space
  useEffect(() => {
    const calculateMenuPosition = () => {
      if (iconRef.current) {
        const iconRect = iconRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if there's more space above or below the icon
        if (windowHeight - iconRect.bottom < 10) {
          setMenuPosition("above");
        } else {
          setMenuPosition("below");
        }
      }
    };

    // Initial calculation
    calculateMenuPosition();

    // Recalculate on window resize
    window.addEventListener("resize", calculateMenuPosition);

    return () => window.removeEventListener("resize", calculateMenuPosition);
  }, [menuOpen, iconRef]);

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href) // Copies current URL
      .then(() => {
        toast.success('Copied!');
        setMenuOpen({})
      })
      .catch(() => {
        toast.error('Failed to copy the link');
      });
  };
  const getColorFromId = (id) => {
    const colors = [
      '#1abc9c', // Turquoise
      '#e74c3c', // Red
      '#3498db', // Blue
      '#9b59b6', // Purple
      '#f39c12', // Orange
      '#2ecc71', // Green
      '#e67e22', // Carrot Orange
    ];

    // Fallback color in case id is null or undefined
    if (!id) return colors[0];

    const hash = id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return colors[hash % colors.length];
  };
  return (
    <div className='camp-container'>

      <div className='campfire'>
        <div className='text-center d-flex justify-content-center m-auto card--head camp py-4'>
          <div className='d-flex justify-content-center align-items-center'>
            <button className="icon--button me-2">
              <FontAwesomeIcon className='me-1' icon={faTh} />
              <FontAwesomeIcon icon={faChevronDown} />
            </button>
            <span onClick={handleBack} className="fw-bold text-primary text-decoration-underline" style={{ cursor: 'pointer' }}>
              {projectData?.name}
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center camp">
          <div className="card text-center camp-card" >
            <div className="card campfireBody">
              <div className="container campfireBody">
                <div className="row align-items-center">
                  <div className="col"></div>
                  <div className="col text-center camp-head-start">
                    <h3 className="mb-0 text-light fw-bold">Campfire</h3>
                  </div>
                  <div className="col text-end camp-head-end">
                    <button className="Notifybtn mr-3 text-light">
                      <FontAwesomeIcon icon={faBell} /> Notifying
                    </button>
                    <button className="Notifybtn text-light" onClick={() => setIsOpen(true)} type='file'>
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center w-100 flex-column'>

              <div className="card-body campfireBody d-flex align-items-center w-100 camp-messsage-body" style={{ height: '65vh' }}>

                {/* Conditionally show either the camp-demo-card or the messages */}
                {messages.length === 0 ? (
                  <div className="camp-demo-card">
                    <div className="card card-custom text-secondary ">
                      <div className="card-body text-center">
                        <div className="d-flex justify-content-center align-items-center mb-3">
                          <i className="bi bi-chat-left-text-fill fs-2"></i>
                          <span className="ms-3 fs-5 text-secondary">
                            Chat casually in Teamnex. Ask questions, connect with your team, and share news without ceremony.
                          </span>
                        </div>
                        <p className="start-text fw-bold text-secondary">
                          Start by saying <span>“Hi!”</span> to your team.
                        </p>
                        <div className="arrow">
                          <img src='https://bc3-production-assets-cdn.basecamp-static.com/assets/onboarding/arrow-down-left-714e89c36456193c3de0245343b0f069f0fd4acb96efcfe966acbcae64a7e48a.svg' alt="arrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="message-container px-3" style={{ overflowY: 'auto', width: '100%', padding: '10px', flexGrow: 1 }}>
                    {messages.map((msg, index) => {
                      const sender = userProfiles[msg.sender._id];
                      const isCurrentUser = msg.sender._id === senderId;

                      // Check if the message content is a URL (for files)
                      const isFileMessage = /\.(jpg|jpeg|png|gif|pdf)$/i.test(msg.content);
                      const formatDate = (timestamp) => {
                        const messageDate = new Date(timestamp);
                        const today = new Date();
                        // Check if the message is today
                        if (
                          messageDate.getDate() === today.getDate() &&
                          messageDate.getMonth() === today.getMonth() &&
                          messageDate.getFullYear() === today.getFullYear()
                        ) {
                          return "Today";
                        } else {
                          // Format for older messages
                          return messageDate.toLocaleDateString("en-US", {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          });
                        }
                      };

                      // Get current date string for the message
                      const currentDate = formatDate(msg.timestamp);

                      // **Changes Start: Render the date header only if it has changed**
                      let dateHeader = null;  // **New variable to track the date header**
                      if (index === 0 || formatDate(messages[index - 1].timestamp) !== currentDate) {
                        dateHeader = (
                          <div key={`date-${index}`} className="date-header text-center my-2 d-flex justify-content-center fs-4 align-items-center  ">
                            <hr style={{ flex: 1, margin: '0 10px' }} />
                            <strong>{currentDate}</strong>
                            <hr style={{ flex: 1, margin: '0 10px' }} />
                          </div>
                        );
                      }
                      return (
                        <>
                          <div>{dateHeader}</div>
                          <div div key={index} className="message d-flex align-items-center justify-content-end position-relative camp-hover-parent" style={{ textAlign: isCurrentUser ? 'right' : 'left' }
                          }>
                            {/* {dateHeader} */}
                            {/* <div className='fw-light msg-font me-1 opacity-50'>
                          {currentDate === "Today" ? new Date(msg.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true }) : ""}
                        </div> */}
                            {/* <div className='fw-light msg-font me-1 opacity-50'>
                          {currentDate === "Today" ? "Today" : new Date(msg.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                        </div> */}
                            {!isCurrentUser && (
                              sender?.profilePhoto ? (
                                <>
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}${sender.profilePhoto}`}
                                    alt={`${sender.username}'s profile`}
                                    style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                                  />

                                </>
                              ) : (
                                <span
                                  className='me-1'
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '50%',
                                    backgroundColor: getColorFromId(senderId),
                                    color: '#fff',
                                    marginRight: '10px',
                                    fontSize: '16px',
                                  }}
                                >
                                  {getInitials(sender?.username)}
                                </span>
                              )
                            )}
                            <div className='d-flex me-2 position-relative'>

                              {menuOpen === msg._id && (
                                <div className={`options-menu p-2 shadow text-white`}
                                  style={{ top: menuPosition === "above" ? '-290px' : '40px' }} >
                                  <div className="emoji-container mb-2">
                                    <div className="emoji-row d-flex justify-content-around mb-2">
                                      {["👍", "👏", "👋", "❤️"].map((emoji) => (
                                        <span key={emoji} role="img" aria-label={emoji} onClick={() => handleReactionClick(msg._id, emoji)} style={{ cursor: 'pointer' }} className='reaction-span'>
                                          {emoji}
                                        </span>
                                      ))}
                                    </div>
                                    <div className="emoji-row d-flex justify-content-around ">
                                      {["😂", "😊", "🎉", "⭐"].map((emoji) => (
                                        <span key={emoji} role="img" aria-label={emoji} onClick={() => handleReactionClick(msg._id, emoji)} style={{ cursor: 'pointer' }} className='reaction-span'>
                                          {emoji}
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                  <hr className='m-1'></hr>
                                  <div className="P-2" onClick={() => handleReplyClick(msg)}>
                                    <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                                      <button className='me-2 sidebarBtn'><MdOutlineReply /></button>Reply
                                    </Link>
                                  </div>
                                  <div className="P-2" onClick={copyLink}>
                                    <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()} >
                                      <button className='me-2 sidebarBtn'><IoIosLink /></button>Copy link
                                    </Link>
                                  </div>
                                  <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                                      <button className='me-2 sidebarBtn'><CiBookmark /></button>Bookmark
                                    </Link>
                                  </div>
                                  <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                                      <button className='me-2 sidebarBtn'><FaHandPointer /></button>Dont forget
                                    </Link>
                                  </div>
                                  <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                                      <div onClick={() => startEditing(msg)}>
                                        <button className='me-2 sidebarBtn' onClick={() => startEditing(msg)}><MdModeEdit /></button>Edit
                                      </div>
                                    </Link>

                                  </div>
                                  <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                                      {console.log(msg._id)}
                                      <div onClick={() => msg._id && deleteMessage(msg._id)}>
                                        <button className='me-2 sidebarBtn' onClick={() => msg._id && deleteMessage(msg._id)}>
                                          <MdDelete />
                                        </button>
                                        Delete
                                      </div>
                                    </Link>
                                  </div>

                                </div>
                              )}
                              <button onClick={() => toggleMenu(msg._id)} style={{ background: 'none', border: 'none' }} className="more-options-btn" ref={iconRef}>
                                <span role="img" aria-label="more options" className='fs-4'><IoIosMore className='text-white' /></span>
                              </button>

                            </div>
                            <div className={`message-bubble ${isCurrentUser ? 'my-message msg-bg m-0' : 'other-message'}`}>

                              {editingMessageId === msg._id ? (
                                <div>
                                  <div className='mb-5'>

                                    <span className='fw-light msg-font me-1'>
                                      {new Date(msg.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                                    </span>
                                    <strong className='msg-font'>
                                      {isCurrentUser ? "Me" : sender ? sender.username : "Loading..."}
                                    </strong>
                                    <ReactQuill value={editedContent} onChange={setEditedContent} />
                                  </div>

                                  <div>
                                    <button onClick={() => saveEdit(msg._id)} className='loginBtn text-black text-thin'>Save Changes</button>
                                    <button onClick={() => setEditingMessageId(null)} className='text-success  camp-cancel'>Cancel</button>
                                  </div>
                                  <div>
                                    {msg.reactions.map((reaction) => (
                                      <span key={reaction.emoji} onClick={() => handleReactionClick(msg._id, reaction.emoji)}>
                                        {reaction.emoji} {reaction.users.length}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              ) : isFileMessage ? (
                                msg.content.endsWith('.pdf') ? (
                                  <div>
                                    <span className='fw-light msg-font me-1'>
                                      {new Date(msg.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                                    </span>
                                    <strong className='msg-font'>
                                      {isCurrentUser ? "Me" : sender ? sender.username : "Loading..."}
                                    </strong>
                                    <br></br>
                                    <img
                                      src={asset} // Replace `asset` with your PDF icon if needed
                                      alt="PDF Icon"
                                      style={{ width: '100%', height: '100%' }}
                                    />
                                    <br />
                                    <a
                                      href={`${process.env.REACT_APP_API_URL}${msg.content}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ marginRight: '10px' }}
                                    >
                                      View PDF
                                    </a>
                                    <a
                                      href={`${process.env.REACT_APP_API_URL}${msg.content}`}
                                      download
                                      style={{ marginLeft: '10px' }}
                                    >
                                      Download
                                    </a>
                                    <div>
                                      {msg.reactions.map((reaction) => (
                                        <span key={reaction.emoji} onClick={() => handleReactionClick(msg._id, reaction.emoji)} style={{ cursor: "pointer" }} className='span'>
                                          {reaction.emoji} {reaction.users.length}
                                          <span className="d-flex align-items-center">
                                            {reaction.users.map((senderId) => {
                                              const user = userProfiles[senderId]; // Get user info from userProfiles using userId

                                              return (
                                                <span key={senderId} className="me-1 d-flex align-items-center">
                                                  {user?.profilePhoto ? (
                                                    <img
                                                      src={`${process.env.REACT_APP_API_URL}${user.profilePhoto}`}
                                                      alt={`${user.username}'s profile`}
                                                      style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '5px' }}
                                                    />
                                                  ) : (
                                                    <span
                                                      style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: '50%',
                                                        backgroundColor: getColorFromId(senderId),
                                                        color: '#fff',
                                                        marginRight: '5px',
                                                        fontSize: '12px',
                                                      }}
                                                    >
                                                      {getInitials(user?.username)}
                                                    </span>
                                                  )}
                                                </span>
                                              );
                                            })}
                                          </span>
                                        </span>
                                      ))}
                                    </div>
                                  </div>

                                ) : (
                                  <div>
                                    <span className='fw-light msg-font me-1'>
                                      {new Date(msg.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                                    </span>
                                    <strong className='msg-font'>
                                      {isCurrentUser ? "Me" : sender ? sender.username : "Loading..."}
                                    </strong>
                                    <br></br>
                                    <img src={`${process.env.REACT_APP_API_URL}${msg.content}`} alt="sent file" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                    <br />
                                    <a
                                      href={`${process.env.REACT_APP_API_URL}${msg.content}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ marginRight: '10px' }}
                                    >
                                      View image
                                    </a>
                                    <a
                                      href={`${process.env.REACT_APP_API_URL}${msg.content}`}
                                      download
                                      style={{ marginLeft: '10px' }}
                                    >
                                      Download
                                    </a>
                                    <div>
                                      {msg.reactions.map((reaction) => (
                                        <span key={reaction.emoji} onClick={() => handleReactionClick(msg._id, reaction.emoji)}>
                                          {reaction.emoji} {reaction.users.length}
                                          <span className="d-flex align-items-center">
                                            {reaction.users.map((senderId) => {
                                              const user = userProfiles[senderId]; // Get user info from userProfiles using userId

                                              return (
                                                <span key={senderId} className="me-1 d-flex align-items-center">
                                                  {user?.profilePhoto ? (
                                                    <img
                                                      src={`${process.env.REACT_APP_API_URL}${user.profilePhoto}`}
                                                      alt={`${user.username}'s profile`}
                                                      style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '5px' }}
                                                    />
                                                  ) : (
                                                    <span
                                                      style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: '50%',
                                                        backgroundColor: getColorFromId(senderId),
                                                        color: '#fff',
                                                        marginRight: '5px',
                                                        fontSize: '12px',
                                                      }}
                                                    >
                                                      {getInitials(user?.username)}
                                                    </span>
                                                  )}
                                                </span>
                                              );
                                            })}
                                          </span>
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                )
                              ) : (
                                <div>

                                  <span className='fw-light msg-font me-1 opacity-50'>
                                    {new Date(msg.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                                  </span>
                                  <strong className='msg-font'>
                                    {isCurrentUser ? "Me" : sender ? sender.username : "Loading..."}
                                  </strong>
                                  <br></br>
                                  {msg.replyTo && (
                                    <div className="reply-content p-3">
                                      <div className='d-flex align-items-center'>
                                        <div>
                                          {!isCurrentUser && (
                                            sender?.profilePhoto ? (
                                              <>
                                                <img
                                                  src={`${process.env.REACT_APP_API_URL}${sender.profilePhoto}`}
                                                  alt={`${sender.username}'s profile`}
                                                  style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                                                />

                                              </>
                                            ) : (
                                              <span
                                                className='me-1'
                                                style={{
                                                  width: '30px',
                                                  height: '30px',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  borderRadius: '50%',
                                                  backgroundColor: getColorFromId(senderId),
                                                  color: '#fff',
                                                  marginRight: '10px',
                                                  fontSize: '16px',
                                                }}
                                              >
                                                {getInitials(sender?.username)}
                                              </span>
                                            )
                                          )}
                                        </div>
                                        <div className='text-start'>
                                          <strong>{isCurrentUser ? "Me" : sender ? sender.username : "Loading..."}</strong>
                                        </div>
                                      </div>
                                      <span>{msg.replyTo.content}</span>
                                    </div>
                                  )}
                                  <span className='fw-normal text-start fs-6' dangerouslySetInnerHTML={{ __html: msg.content }}></span>
                                  <div className='d-flex cursor-pointer '>
                                    {msg.reactions.map((reaction) => (
                                      <span key={reaction.emoji} onClick={() => handleReactionClick(msg._id, reaction.emoji)} className='d-flex cursor-pointer me-2 camp-react' style={{ cursor: "pointer" }}>

                                        {reaction.users.map((senderId) => {
                                          const user = userProfiles[senderId]; // Get user info from userProfiles using userId

                                          return (
                                            <div key={senderId} className=" d-flex align-items-center">
                                              {user?.profilePhoto ? (
                                                <img
                                                  src={`${process.env.REACT_APP_API_URL}${user.profilePhoto}`}
                                                  alt={`${user.username}'s profile`}
                                                  style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '5px' }}
                                                />
                                              ) : (
                                                <div
                                                  style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '50%',
                                                    backgroundColor: getColorFromId(senderId),
                                                    color: '#fff',
                                                    marginRight: '5px',
                                                    fontSize: '12px',
                                                  }}
                                                >
                                                  {getInitials(user?.username)}
                                                </div>
                                              )}
                                            </div>
                                          );
                                        })}{reaction.emoji}
                                        {/* {reaction.users.length} */}

                                        <span className="d-flex align-items-center">

                                        </span>
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              )}

                            </div>


                            {isCurrentUser && (
                              sender?.profilePhoto ? (
                                <img
                                  src={sender.profilePhoto}
                                  alt={``}
                                  style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '10px' }}
                                />
                              ) : (
                                <span
                                  className='ms-1'
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '50%',
                                    backgroundColor: getColorFromId(senderId),
                                    color: '#fff',
                                    marginLeft: '10px',
                                    fontSize: '16px',
                                  }}
                                >
                                  {getInitials(sender?.username)}
                                </span>
                              )
                            )}
                          </div>
                        </>

                      );
                    })}

                    <div ref={messageEndRef}></div>
                  </div>
                )}

              </div>
              {/* {console.log(replyingTo)} */}

              {replyingTo && (
                <div div className="replying-to p-4 text-white d-flex justify-content-between align-items-start text-start">
                  <div className='w-100 p-2 reply-content'>
                    <div className='d-flex align-items-center'>

                      <div> {(
                        replyingTo.sender?.profilePhoto ? (
                          <>
                            <img
                              src={`${process.env.REACT_APP_API_URL}${replyingTo.sender.profilePhoto}`}
                              alt={`${replyingTo.sender.username}'s profile`}
                              style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                            />

                          </>
                        ) : (
                          <span
                            className='me-1'
                            style={{
                              width: '30px',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '50%',
                              backgroundColor: getColorFromId(replyingTo.sender._id),
                              color: '#fff',
                              marginRight: '10px',
                              fontSize: '16px',
                            }}
                          >
                            {getInitials(replyingTo?.sender?.username)}
                          </span>
                        )
                      )}</div>
                      <div>
                        <strong>{replyingTo.sender.username}</strong>
                      </div>
                    </div>

                    <br></br>
                    <div dangerouslySetInnerHTML={{ __html: replyingTo.content }}></div> {/* assuming msg.text is the message content */}
                  </div>
                  <button onClick={() => setReplyingTo(null)} className="btn btn-close cancel-reply" aria-label="Cancel Reply">
                  </button>
                </div>
              )}
              <div className={`card-footer campfireBody w-100 justify-content-end `} style={{
                paddingTop: showEditor ? '150px' : '0',
              }}>

                {showEditor ? (
                  <>
                    {/* <div> */}

                    <div className=" inputfoot ">
                      <ReactQuill
                        value={message}
                        onChange={setMessage}
                        modules={{
                          toolbar: [
                            ['bold', 'italic', 'strike'],
                            ['blockquote', 'code'],
                            [{ 'color': ['red', 'green'] }, { 'background': [] }],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            ['link'],
                            ['clean']
                          ],
                        }}
                      // formats={formats}
                      />

                    </div>

                    {/* </div> */}

                  </>
                ) : (
                  <input
                    type="text"
                    className='inputfoot'
                    ref={inputRef}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        sendMessage(); // Send message on Enter key
                      }
                    }}
                  />
                )}


                <div className="input-group-append">
                  <div className={`emoji-picker-container ${showEmojiPicker ? 'open' : ''}`}>
                    <EmojiPicker onEmojiClick={handleEmojiClick} onChange={setMessage} />
                  </div>
                  <button className="iconbtn p-2 text-white" type="button" onClick={toggleEmojiPicker}>
                    <FontAwesomeIcon icon={faSmile} />
                  </button>

                  <button className="iconbtn p-2 text-white" type="button" onClick={toggleEditor}>
                    <FontAwesomeIcon icon={faFont} />
                  </button>

                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <button className="iconbtn p-2 text-white" type="button" onClick={handleButtonClick} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      sendMessage(); // Send message on Enter key
                    }
                  }}>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </button>
                </div>


              </div>
              {showEditor &&
                <div className='d-flex justify-content-start' style={{ width: "90%" }}>
                  <button className="loginBtn px-3 mb-2 text-black fw-normal" type="button" onClick={sendMessage} >
                    Post
                  </button>
                </div>}
            </div>
            <div className="text-start">
              <div id="mySidenav" className={`CampfireSidenav ${isOpen ? 'open' : ''} pt-5`}>
                <span className="closebtn" onClick={() => setIsOpen(false)}>&times;</span>
                <div className="P-2">
                  <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                    <button className='me-2 sidebarBtn'><FaRegBookmark /></button>Bookmark
                  </Link>
                </div>
                <div className="P-2">
                  <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                    <button className='me-2 sidebarBtn'><IoMdPhotos /></button>See all files posted here
                  </Link>
                </div>
                <div className="P-2">
                  <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                    <button className='me-2 sidebarBtn'><TbHandClick /></button>Don't forget
                  </Link>
                </div>
                <div className="P-2">
                  <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                    <button className='me-2 sidebarBtn'><IoMdPeople /></button>Notifying 38 people
                  </Link>
                </div>
                <hr className="line" />
                <p className='sideEvent ps-2 m-0'>For developers</p>
                <div className="P-2">
                  <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                    <button className='me-2 sidebarBtn'><FaMicrochip /></button>Configure chatbots
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div>

  );
};

export default Campfire;