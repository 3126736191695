import React from 'react'
import { RiMailSendLine } from "react-icons/ri";

const Invitation_Identifier = () => {
  return (
    <div className=' d-flex justify-content-center text-center mt-4'>
        <div className='Invitation_Identifier p-3'>
            <div className='mail-icn'><RiMailSendLine /></div>
            <div className='h3 fw-bold'>Invitation emailed to ...</div>
            <div className='px-4'>They’ll get an email with instructions on how to sign in and join your TeamNex account. Add them to existing projects now or do that later.</div>
            <button className='w-100 p-2 mb-2 btn-1'>Set up which project they can see</button>
            <button className='w-100 p-2 mb-3 btn-2'>Not now,I'll do this later</button>
        </div>
    </div>
  )
}

export default Invitation_Identifier