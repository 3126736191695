import React from 'react'

const NotificationSetting = () => {
    return (
        <div className='container NotificationSetting '>
            <h1 className='text-center'>Notification settings and Work Can Wait</h1>

            <h2 className='mt-3'>What?<hr style={{ display: 'inline-block', width: '85%', verticalAlign: 'middle', margin: '0 10px' }} /> </h2>

            <div className="notification-option-1">
                <label className='d-flex align-item-center'>
                    <div>
                        <input type="radio" name="notification" />
                        <strong className='ms-2'>Notify me about everything</strong>
                        <p className="description ms-4">
                            This includes new messages and comments, to-dos assigned to you, when someone @mentions you, and Chats and Pings you're part of.
                        </p>
                    </div>
                </label>
            </div>
            <div className="notification-option-2 mt-3">
                <label className='d-flex align-item-center'>
                    <div >
                        <input type="radio" name="notification" />
                        <strong className='ms-2'>Only notify me when someone sends me a Ping or @mentions me</strong>
                        <p className="description ms-4">
                            TeamNex will only send you a notification when someone sends you a Ping or @mentions you. You can check the Hey! menu anytime to see what else is new for you.
                        </p>
                    </div>
                </label>
            </div>

            <h4 className='m-3'>Reminders</h4>

            <div className="notification-option-3 mt-3">
                <label className='d-flex align-item-center'>
                    <div >
                        <input type="checkbox" name="notification" />
                        <strong className='ms-2'>Notify me about upcoming events</strong>
                        <div className="description ms-4">
                            You'll receive notifications the day before an event and an hour before it starts. For all-day events, you'll be notified on the day of the event.
                        </div>
                    </div>
                </label>
            </div>

            <div className="notification-option-4 mt-3">
                <label className='d-flex align-item-center'>
                    <div >
                        <input type="checkbox" name="notification" />
                        <strong className='ms-2'>Notify me about upcoming assignments</strong>
                        <div className="description ms-4">
                            You'll receive notifications the day before an assignment is due and each day after until it's completed.
                        </div>
                    </div>
                </label>
            </div>

            <h2 className='mt-3'>How?<hr style={{ display: 'inline-block', width: '85%', verticalAlign: 'middle', margin: '0 10px' }} /> </h2>

            <div className="notification-option-5 mt-3">
                <label className='d-flex align-item-center'>
                    <div >
                        <input type="checkbox" name="notification" />
                        <strong className='ms-2'>Send me email notifications</strong>
                        <div className="description ms-4">
                            You'll receive notifications the day before an assignment is due and each day after until it's completed.
                        </div>
                    </div>
                </label>
            </div>

            <div className="notification-option-6 mt-3">
                <label className='d-flex align-item-center'>
                    <div >
                        <input type="checkbox" name="notification" />
                        <strong className='ms-2'>Show the number of unread items</strong>
                        <div className="description ms-4">
                            You'll receive notifications the day before an assignment is due and each day after until it's completed.
                        </div>
                    </div>
                </label>
            </div>

            <div className="notification-option-7 mt-3">
                <label className='d-flex align-item-center'>
                    <div >
                        <input type="checkbox" name="notification" />
                        <strong className='ms-2'>Notify me about upcoming assignments</strong>
                        <div className="description ms-4">
                            You’ll see the unread count for both Ping and Hey! notifications when TeamNex is open in your browser.
                        </div>
                    </div>
                </label>
            </div>

            <div className='p-2 mt-3 textlink'>
                You can also get notifications/alerts from TeamNex on your phone or tablet. <a href="">Download our free app for iOS or Android</a>
            </div>

            <h2 className='mt-4'>When?<hr style={{ display: 'inline-block', width: '85%', verticalAlign: 'middle', margin: '0 10px' }} /> </h2>

            <div className="notification-option-7 mt-3">
                <label className='d-flex align-item-center'>
                    <div >
                        <input type="radio" name="notification" />
                        <strong className='ms-2'>Always! 24/7/365 no matter what.</strong>
                    </div>
                </label>
            </div>
            <div className="notification-option-7 mt-3">
                <label className='d-flex align-item-center'>
                    <div >
                        <input type="radio" name="notification" />
                        <strong className='ms-2'>Work Can Wait! Only during my work hours…</strong>
                    </div>
                </label>
            </div>

            <button type='submit' className='savebtn mt-4'>Save My Setting</button>

        </div>
    )
}

export default NotificationSetting