import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaPlus } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaRegBookmark } from 'react-icons/fa6';
import { MdEdit, MdOutlineSort } from "react-icons/md";
import { GoDash } from "react-icons/go";
import ProfileCircles from "../ProfileCircles";
import { useCookies } from 'react-cookie';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const MessageBoard = ({ currentUserId }) => {
    const [selectedCategory, setSelectedCategory] = useState('All Messages'); // Default to 'All Messages'
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    // const [projectData, setProjectData] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();
    const [cookies] = useCookies(['user']);
    const userId = cookies.projectId || currentUserId;
    const location = useLocation();
    const projectData = location.state?.projectData;
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [showCategories, setShowCategories] = useState(false);

    const [categories, setCategories] = useState([
        { name: 'None', name: 'Pick a category (optional)' },
        { id: 1, name: '📢 Announcement' },
        { id: 2, name: '✨ FYI' },
        { id: 3, name: '❤️ Heartbeat' },
        { id: 4, name: '💡 Pitch' },
        { id: 5, name: '🔥 Question' },
        { id: 6, name: '👍 vanakkam' },
        { id: 7, name: "edit categories" }

    ]);

    const [newCategoryName, setNewCategoryName] = useState('');
    const [selectedIcon, setSelectedIcon] = useState('❓'); // Default icon for new category
    const [isEditing, setIsEditing] = useState(null);
    const [isAdding, setIsAdding] = useState(false); // Track adding mode
    const handleBack = () => navigate(-1);
    // Predefined icons (emoji)
    const availableIcons = ['📢', '✨', '❤️', '💡', '🔥', '👍', '🎉', '🔔', '💬', '❓'];

    // Add Category with Emoji
    const addCategory = () => {
        if (newCategoryName.trim() && selectedIcon) {
            const newCategory = { id: Date.now(), name: newCategoryName, icon: selectedIcon };
            setCategories([...categories, newCategory]);
            setNewCategoryName(''); // Reset input
            setSelectedIcon('❓'); // Reset icon
            setIsAdding(false); // Exit add mode
        }
    };

    // Edit Category Function
    const editCategory = (id, updatedName) => {
        setCategories(categories.map(cat =>
            cat.id === id ? { ...cat, name: updatedName } : cat
        ));
        setIsEditing(null);
    };

    // Delete Category Function
    const deleteCategory = (id) => {
        setCategories(categories.filter(cat => cat.id !== id));
    };
    // Category options
    // const categories = [
    //     { id: 0, name: 'All Messages' },
    //     { id: 1, name: 'Announcement', icon: '📢' },
    //     { id: 2, name: 'FYI', icon: '✨' },
    //     { id: 3, name: 'Heartbeat', icon: '❤️' },
    //     { id: 4, name: 'Pitch', icon: '💡' },
    //     { id: 5, name: 'Question', icon: '🔥' },
    //     { id: 6, name: 'Vanakkam', icon: '👍' },
    // ];

    // Handle category change
    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);

    // const handleClickMessage = (messageId) => {
    //     navigate(`/message/${messageId}`);
    // };
    // Fetch messages
    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/messages`);
                if (!response.ok) throw new Error('Failed to fetch messages');
                const data = await response.json();
                setMessages(data);
                setFilteredMessages(data.filter((message) => message.userId === userId));
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };
        fetchMessages();
    }, [userId]);


    // Fetch project data
    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${userId}`);
                if (!response.ok) throw new Error('Failed to fetch project data');
                const project = await response.json();
                // setProjectData(project);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        if (userId) fetchProjectData();
    }, [userId]);

    // Fetch user information
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${userId}`);
                if (!response.ok) throw new Error('Failed to fetch user info');
                const user = await response.json();
                setUserInfo(user);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        if (userId) fetchUserInfo();
    }, [userId]);

    // Filter messages based on the selected category
    const filteredMessagess = selectedCategory === 'All Messages'
        ? messages // Show all messages if "All Messages" is selected
        : messages.filter(message => message.category === selectedCategory); // Show only messages matching the selected category

    return (
        <div className='campfire mt-5'>
            <div className='text-center d-block m-auto campfire card--head camp' style={{ width: '65vw' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <span onClick={handleBack} className="fw-bold text-primary text-decoration-underline" style={{ cursor: 'pointer' }}>
                        {projectData?.name}
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="campfireBody text-center" style={{ width: '75vw' }}>
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="text-start">
                                <Link to="newMessage">
                                    <button className="newEvent px-2 py-1 text-dark">
                                        <FaPlus className='me-1' />New message
                                    </button>
                                </Link>
                            </div>
                            <div className="text-center">
                                <h3 className="mb-0 text-light fw-bold">Message Board</h3>
                            </div>
                            <div className="text-end">
                                <select
                                    className='allMessages text-light'
                                    id="category-selector"
                                    value={selectedCategory}
                                    onChange={handleChange}
                                >
                                    {categories.map((category) => (
                                        <option key={category.id} value={category.name}>
                                            {category.name} {category.icon}
                                        </option>
                                    ))}
                                </select>
                                <button className="Notifybtn text-light" onClick={openNav}>
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                </button>
                            </div>
                        </div>
                        <hr className="line" />
                    </div>
                    <div className="d-flex justify-content-center">
                        <div
                            id="messages"
                            className="messagebody text-start ms-5 me-5 p-4"
                            style={{ cursor: 'pointer' }}
                        >
                            {filteredMessages.length === 0 ? (
                                <div className="text-light">
                                    <p className="text-center">No matches!</p>
                                </div>
                            ) : (
                                filteredMessages.map((message) => {
                                    // Find the author based on authorId in projectData.members
                                    const sender = projectData?.members?.find(member => member.id === message.username);

                                    return (
                                        <Link
                                            key={message._id}
                                            className="messageBoard text-decoration-none"
                                            to={{
                                                pathname: `message/${message._id}`,
                                            }}
                                        >
                                            <div className="profileCircles">
                                                {projectData?.members && (
                                                    <ProfileCircles className="circles" accounts={projectData.members} />
                                                )}
                                            </div>
                                            <div className="d-flex flex-column ms-5">
                                                <h4 className="text-light">{message.title || 'Unknown Title'}</h4>
                                                <p className="text-light m-0 message-content">
                                                    <span className="pfusername">
                                                        {sender?.username} {message.category} by{' '}
                                                        {new Date(message.createdDate).toLocaleDateString('en-US', {
                                                            month: 'long',
                                                            day: 'numeric',
                                                        })}
                                                        <GoDash />
                                                    </span>
                                                    {message.content || 'No content available.'}
                                                </p>
                                            </div>
                                            <hr className="line m-0" />
                                        </Link>

                                    );
                                })
                            )}
                        </div>
                        <div>
                            {/* Sidebar Navigation */}
                            <div className="text-start">
                                <div id="mySidenav" className={`CampfireSidenav messageboardSidenav ${isOpen ? 'open' : ''}`}>
                                    <span className="closebtn" onClick={closeNav}>&times;</span>
                                    <div className="P-2">
                                        <Link to="#" className="sideBar text-decoration-none">
                                            <button className="me-2 sidebarBtn"><FaRegBookmark /></button>Bookmark
                                        </Link>
                                    </div>
                                    <div className="P-2">
                                        <Link to="#" className="sideBar text-decoration-none">
                                            <button className="me-2 sidebarBtn"><MdOutlineSort /></button>Sort messages by...
                                        </Link>
                                    </div>
                                    <div className="P-2">
                                        <span className="sideBar text-decoration-none" onClick={() => setShowCategories(!showCategories)}>
                                            <button className="me-2 sidebarBtn"><MdEdit /></button>Edit categories...
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* Message Board Categories */}
                            {showCategories && (
                                <div className="message-board-categories">
                                    <h3>Message Board Categories</h3>
                                    <p>Set up categories for this Message Board below.</p>
                                    <ul>
                                        {categories.map(category => (
                                            <li key={category.id}>
                                                <span>{category.icon}</span>
                                                {isEditing === category.id ? (
                                                    <input
                                                        type="text"
                                                        value={category.name}
                                                        onChange={(e) => editCategory(category.id, e.target.value)}
                                                    />
                                                ) : (
                                                    <span>{category.name}</span>
                                                )}
                                                {isEditing === category.id ? (
                                                    <button onClick={() => editCategory(category.id, category.name)}>✔</button>
                                                ) : (
                                                    <>
                                                        <button className="me-2" onClick={() => setIsEditing(category.id)}><FaEdit /></button>
                                                        <button onClick={() => deleteCategory(category.id)}><FaTrashAlt /></button>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ul>

                                    {/* Add Category Section */}
                                    {isAdding ? (
                                        <div className="add-category">
                                            <input
                                                type="text"
                                                placeholder="Name it..."
                                                value={newCategoryName}
                                                onChange={(e) => setNewCategoryName(e.target.value)}
                                            />
                                            <div className="icon-selection">
                                                <p>Select an icon:</p>
                                                {availableIcons.map(icon => (
                                                    <span
                                                        key={icon}
                                                        onClick={() => setSelectedIcon(icon)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: '1.5rem',
                                                            margin: '0 5px',
                                                            border: selectedIcon === icon ? '2px solid blue' : 'none',
                                                        }}
                                                    >
                                                        {icon}
                                                    </span>
                                                ))}
                                            </div>
                                            <button onClick={addCategory}>✔</button>
                                            <button onClick={() => setIsAdding(false)}>✖</button>
                                        </div>
                                    ) : (
                                        <button className="add-category-btn" onClick={() => setIsAdding(true)}>
                                            Add a category
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageBoard;
