import React, { useState } from 'react'
import { RiKey2Fill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const Administrators = () => {
    const [administrators, setAdministrators] = useState(['Arunkumar']);
    const [newAdmin, setNewAdmin] = useState('');

    const handleAddAdmin = () => {
        if (newAdmin && !administrators.includes(newAdmin)) {
            setAdministrators([...administrators, newAdmin]);
            setNewAdmin('');
        }
    };
    return (
        <div className='campfire todo-contain'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <Link to="/:organizationId/adminland" className="fw-bold"><RiKey2Fill style={{
                        fontSize: "20"
                    }} />Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card " style={{ width: '75vw' }}>
                    <div className="campfireBody">
                        <div className="container">
                            <div className=" text-center mt-3">
                                <a href="" className='headcamp fw-bold'> <h3 className="mb-0 t-color1">Add/Remove Administrators</h3> </a>
                            </div>
                            <hr className="line" />
                        </div>
                        <div className="card-body p-0">
                            <div className="admin-panel t-color1">
                                <p>
                                    It’s nice to have some trusted people available to help with administrative tasks on your account. Here’s what Administrators can do:
                                </p>
                                <ul>
                                    <li>Add or remove people from the account</li>
                                    <li>Change what people can access</li>
                                    <li>Move people between companies/organizations</li>
                                    <li>Rename a company/organization</li>
                                    <li>Add or remove other administrators</li>
                                </ul>
                                <div className="admin-list">
                                    <h5>Administrators on this account</h5>
                                    <hr className="line" />
                                    {administrators.map((admin, index) => (
                                        <div key={index} className="admin-item">
                                            <div className="admin-icon">{admin.charAt(0)}</div>
                                            <div className="admin-name">{admin}</div>
                                            <div className="admin-role">Owner</div>
                                        </div>
                                    ))}
                                    <hr className="line" />
                                </div>
                                <h5>Who else should be an administrator?</h5>
                                <div className="add-admin">
                                    <input
                                        className='administrators'
                                        type="text"
                                        value={newAdmin}
                                        onChange={(e) => setNewAdmin(e.target.value)}
                                        placeholder="Type a person's name..."
                                    />
                                </div>
                                <p>
                                    Administrators must be part of TemNex. You can change someone’s company/organization in <a href='#'>Adminland</a>.
                                </p>
                                <div className='add-admin'>
                                    <button className='adminbton' onClick={handleAddAdmin}>Grant administrator powers</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Administrators
