import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaRegBookmark } from 'react-icons/fa';
import { FaBold, FaHeading, FaItalic, FaLink, FaStrikethrough } from 'react-icons/fa';
import { IoIosAttach, IoIosColorFill, IoMdCode } from 'react-icons/io';
import { BiSolidQuoteAltRight } from 'react-icons/bi';
import { LuRedo, LuUndo } from 'react-icons/lu';
import { MdFormatListBulleted, MdFormatListNumbered } from 'react-icons/md';
import { AiOutlineAlignCenter } from 'react-icons/ai';
import { MdAreaChart, MdNavigateNext } from "react-icons/md";
import { HiOutlineLink } from "react-icons/hi";
import { Link, useLocation, useParams } from 'react-router-dom';
import ProfileCircles from '../ProfileCircles';
import SubscriberList from "./SubscriberList"

const Header = () => (
    <div className='campfire text-center d-block m-auto campfire card--head camp' style={{ width: '63vw', height: '6vh' }}>
        <div className='d-flex justify-content-center align-items-center mt-2'>
            <div className="icon--div me-2">
                <FontAwesomeIcon className='me-1' icon={faTh} />
                <FontAwesomeIcon icon={faChevronDown} />
            </div>
            <Link to="/project" className="fw-bold">Webnexs</Link>
            <MdNavigateNext />
            <Link to="" className="fw-bold">MessageBoard</Link>
        </div>
    </div>
);

const MessageItem = ({
    message,
    commentContent,
    setCommentContent,
    handleCommentSubmit,
    textareaRef,
    handleBold,
    handleItalic,
    handleStrikethrough,
    handleLink,
    handleColorFill,
    handleHeading,
    handleBlockquote,
    handleAlignCenter,
    handleCode,
    handleBulletedList,
    handleNumberedList,
    handleAttach,
    handleUndo,
    handleRedo
}) => {

    const formattedDate = new Date(message.createdDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const location = useLocation();
    const projectData = location.state?.projectData;
    const sender = projectData?.members?.find(member => member.id === message.username);
    return (
        <div className='d-flex justify-content-center'>
            <div className="main-content text-light">
                <p className='m-0'>{message.category || 'Untitled'}</p>
                <h3 className="text-light">{message.title || 'Untitled'}</h3>

                <p className='text-light'>
                    {projectData?.members && (
                        <ProfileCircles className="circles" accounts={projectData.members} />
                    )}
                    {sender?.username} . {new Date(message.createdDate).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                    })} .
                    <a href="#" className='text-decoration-none'> Notified 1 people</a>
                </p>
                <ul className="ms-5 text-start">
                    <li className='text-light'>{message.content || 'No content available.'}</li>
                </ul>
                <hr className="line" />

                {/* Display Comments */}
                <div className='comment-display-section'>
                    {message.comments && message.comments.length > 0 ? (
                        message.comments.map((comment, index) => (
                            <div key={index} className="comment-item text-light">
                                <p className="comment-author">{comment.author || 'Anonymous'}</p>
                                <p className="comment-content">{comment.content}</p>
                                <p className="comment-date">
                                    {new Date(comment.date).toLocaleDateString('en-US')}
                                </p>
                            </div>
                        ))
                    ) : (
                        <p className="text-light"></p>
                    )}
                </div>

                {/* Comment Section */}
                <CommentSection
                    content={commentContent[message._id] || ''}
                    setContent={(content) => setCommentContent(prev => ({ ...prev, [message._id]: content }))}
                    handleCommentSubmit={() => handleCommentSubmit(message._id)}
                    textareaRef={textareaRef}
                    handleBold={handleBold}
                    handleItalic={handleItalic}
                    handleStrikethrough={handleStrikethrough}
                    handleLink={handleLink}
                    handleColorFill={handleColorFill}
                    handleHeading={handleHeading}
                    handleBlockquote={handleBlockquote}
                    handleAlignCenter={handleAlignCenter}
                    handleCode={handleCode}
                    handleBulletedList={handleBulletedList}
                    handleNumberedList={handleNumberedList}
                    handleAttach={handleAttach}
                    handleUndo={handleUndo}
                    handleRedo={handleRedo}
                />
            </div>
        </div>
    );
};

const CommentSection = ({
    content,
    setContent,
    handleCommentSubmit,
    textareaRef,
    handleBold,
    handleItalic,
    handleStrikethrough,
    handleLink,
    handleColorFill,
    handleHeading,
    handleBlockquote,
    handleAlignCenter,
    handleCode,
    handleBulletedList,
    handleNumberedList,
    handleAttach,
    handleUndo,
    handleRedo
}) => (
    <div className="comment-section">
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleCommentSubmit();
            }}
        >
            <div className="toolbar text-start">
                <hr className="line m-0" />
                <button className='toolbarBtn' onClick={handleBold} aria-label="Bold"><b><FaBold /></b></button>
                <button className='toolbarBtn' onClick={handleItalic}><i><FaItalic /></i></button>
                <button className='toolbarBtn' onClick={handleStrikethrough}><FaStrikethrough /></button>
                <button className='toolbarBtn' onClick={handleLink}><FaLink /></button>
                <button className='toolbarBtn' onClick={handleColorFill}><IoIosColorFill /></button>
                <button className='toolbarBtn' onClick={handleHeading}><FaHeading /></button>
                <button className='toolbarBtn' onClick={handleBlockquote}><BiSolidQuoteAltRight /></button>
                <button className='toolbarBtn' onClick={handleAlignCenter}><AiOutlineAlignCenter /></button>
                <button className='toolbarBtn' onClick={handleCode}><IoMdCode /></button>
                <button className='toolbarBtn' onClick={handleBulletedList}><MdFormatListBulleted /></button>
                <button className='toolbarBtn' onClick={handleNumberedList}><MdFormatListNumbered /></button>
                <button className='toolbarBtn' onClick={handleAttach}><IoIosAttach /></button>
                <span className="text-end">
                    <button className='toolbarBtn' onClick={handleUndo}><LuUndo /></button>
                    <button className='toolbarBtn' onClick={handleRedo}><LuRedo /></button>
                </span>
                <hr className="line m-0" />
            </div>
            <textarea
                className="awayText pt-1"
                placeholder="Add a comment here…"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                ref={textareaRef}
            />
            <div className='text-start'>
                <button className='commentBtn' type="submit">Add This Comment</button>
            </div>
        </form>
    </div>
);


const SideNav = ({ isOpen, closeNav }) => (
    <div className={`CampfireSidenav messageboardSidenav ${isOpen ? 'open' : ''}`}>
        <span className="closebtn" onClick={closeNav}>&times;</span>
        <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'>
                <div className='me-2 sidebarBtn'><FaRegBookmark /></div>Bookmark
            </Link>
        </div>
        <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'>
                <div className='me-2 sidebarBtn'><HiOutlineLink /></div>Get a public link
            </Link>
        </div>
        <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'>
                <div className='me-2 sidebarBtn'><MdAreaChart /></div>Hill chart settings
            </Link>
        </div>
        <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'>
                <div className='me-2 sidebarBtn'><FaRegBookmark /></div>Bookmark
            </Link>
        </div>
        <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'>
                <div className='me-2 sidebarBtn'><HiOutlineLink /></div>Get a public link
            </Link>
        </div>
        <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'>
                <div className='me-2 sidebarBtn'><MdAreaChart /></div>Hill chart settings
            </Link>
        </div>
        <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'>
                <div className='me-2 sidebarBtn'><FaRegBookmark /></div>Bookmark
            </Link>
        </div>
        <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'>
                <div className='me-2 sidebarBtn'><HiOutlineLink /></div>Get a public link
            </Link>
        </div>
        <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'>
                <div className='me-2 sidebarBtn'><MdAreaChart /></div>Hill chart settings
            </Link>
        </div>
    </div>
);

const MessageShow = () => {
    console.log("test")
    const [content, setContent] = useState('');
    const [history, setHistory] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [messages, setMessages] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [commentContent, setCommentContent] = useState({});
    const [message, setMessage] = useState(null);
    const textareaRef = useRef(null);
    const [subscribers, setSubscribers] = useState([
        { name: 'User1', id: 1, avatar: 'user1-avatar.png' },
        { name: 'User2', id: 2, avatar: 'user2-avatar.png' },
        // Add other subscribers
    ]);

    const [comment, setComment] = useState('');

    const handleInputChange = (e) => {
        setComment(e.target.value);
    };
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const openNav = () => {
        setIsOpen(true);
    };

    const closeNav = () => {
        setIsOpen(false);
    };
    const saveState = () => {
        // Save the current state before making changes, providing feedback to the user
        setHistory((prevHistory) => [...prevHistory, content]);
        setRedoStack([]); // Clear redoStack when a new action is performed
    };

    const handleUndo = () => {
        setHistory((prevHistory) => {
            if (prevHistory.length > 0) {
                const previousContent = prevHistory[prevHistory.length - 1];
                setRedoStack((prevRedoStack) => [content, ...prevRedoStack]);
                setContent(previousContent);
                return prevHistory.slice(0, -1);
            }
            return prevHistory;
        });
    };

    const handleRedo = () => {
        setRedoStack((prevRedoStack) => {
            if (prevRedoStack.length > 0) {
                const nextContent = prevRedoStack[0];
                saveState();
                setContent(nextContent);
                return prevRedoStack.slice(1);
            }
            return prevRedoStack;
        });
    };


    const handleBold = () => wrapSelectedText('<b>', '</b>');
    const handleItalic = () => wrapSelectedText('<i>', '</i>');
    const handleStrikethrough = () => wrapSelectedText('<s>', '</s>');
    const handleLink = () => {
        const url = prompt('Enter the URL:');
        if (url) {
            wrapSelectedText(`<a href="${url}">`, '</a>');
        }
    };

    const handleColorFill = () => {
        const color = prompt('Enter the color (e.g., #ff0000):');
        if (color) {
            wrapSelectedText(`<span style="color: ${color};">`, '</span>');
        }
    };

    const handleHeading = () => {
        const level = parseInt(prompt('Enter heading level (1-6):'), 10);
        if (level >= 1 && level <= 6) {
            wrapSelectedText(`<h${level}>`, `</h${level}>`);
        } else {
            alert('Invalid heading level.');
        }
    };

    const handleBlockquote = () => wrapSelectedText('<blockquote>', '</blockquote>');
    const handleAlignCenter = () => wrapSelectedText('<div style="text-align: center;">', '</div>');
    const handleCode = () => wrapSelectedText('<code>', '</code>');
    const handleBulletedList = () => wrapSelectedText('<ul><li>', '</li></ul>');
    const handleNumberedList = () => wrapSelectedText('<ol><li>', '</li></ol>');
    const handleAttach = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.addEventListener('change', (event) => {
            const file = event.target.files[0];
            if (file) {
                console.log('File selected:', file);
            }
        });
        input.click();
    };
    const wrapSelectedText = (tagOpen, tagClose = tagOpen) => {
        const textarea = textareaRef.current;
        if (!textarea) {
            console.error('Textarea reference is not defined');
            return;
        }
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const content = textarea.value; // Ensure content is fetched correctly

        if (start !== end) {
            const selectedText = content.substring(start, end);
            const newText = `${content.substring(0, start)}${tagOpen}${selectedText}${tagClose}${content.substring(end)}`;
            setContent(newText);
        }
    };

    const handleCommentSubmit = async (messageId) => {
        const content = commentContent[messageId];

        // Validate the comment content
        if (typeof content !== 'string' || !content.trim()) {
            console.error('Comment is not defined or is not a valid string');
            return;
        }

        try {
            // Send the comment to the backend
            const response = await fetch(`${process.env.REACT_APP_API_URL}/messages/${messageId}/comment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content }), // Pass the comment content
            });

            // Check for errors in the response
            if (!response.ok) {
                const errorMessage = await response.text();
                console.error('Failed to submit comment:', response.status, errorMessage);
                throw new Error(`Failed to submit comment: ${response.statusText}`);
            }

            // Update the message with the new comment data
            const updatedComment = await response.json();
            setMessages((prevMessages) =>
                prevMessages.map((msg) => (msg._id === messageId ? { ...msg, comments: [...msg.comments, updatedComment] } : msg))
            );

            // Clear the comment input field after successful submission
            setCommentContent((prev) => ({ ...prev, [messageId]: '' }));
        } catch (error) {
            console.error('Error submitting comment:', error);
            setError(error.message);
        }
    };

    useEffect(() => {
        const controller = new AbortController(); // Create an abort controller for cleanup
        const fetchMessages = async () => {
            setError(null); // Reset error before fetching
            setLoading(true);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/messages`, {
                    signal: controller.signal, // Attach the abort signal
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch messages');
                }

                const data = await response.json();
                setMessages(data);
            } catch (error) {
                if (error.name !== 'AbortError') { // Check if error is not from the abort
                    setError(error.message);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();

        return () => controller.abort(); // Cleanup fetch on component unmount
    }, []);


    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className='campfire mt-5'>
            <Header />
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="text-center" style={{ width: '75vw' }}>
                    <div className="campfireBody">
                        <div className="card-body">
                            <div className="container">
                                <div className="d-flex justify-content-between">
                                    <div className="mt-3"></div>
                                    {/* <div className="text-center mt-3">
                                        <h3 className="mb-0 text-light">
                                            {messages[0]?.title || 'TeamNex Project'}
                                        </h3>
                                    </div> */}
                                    <div className="text-end">
                                        <div className="Notifybtn text-light" onClick={openNav}>
                                            <FontAwesomeIcon icon={faEllipsisH} />
                                        </div>
                                    </div>
                                </div>

                                {messages.map((message) => (
                                    <MessageItem
                                        key={message._id}
                                        message={message}
                                        commentContent={commentContent}
                                        setCommentContent={setCommentContent}
                                        handleCommentSubmit={handleCommentSubmit}
                                        textareaRef={textareaRef}
                                        handleBold={handleBold}
                                        handleItalic={handleItalic}
                                        handleStrikethrough={handleStrikethrough}
                                        handleLink={handleLink}
                                        handleColorFill={handleColorFill}
                                        handleHeading={handleHeading}
                                        handleBlockquote={handleBlockquote}
                                        handleAlignCenter={handleAlignCenter}
                                        handleCode={handleCode}
                                        handleBulletedList={handleBulletedList}
                                        handleNumberedList={handleNumberedList}
                                        handleAttach={handleAttach}
                                        handleUndo={handleUndo}
                                        handleRedo={handleRedo}
                                    />
                                ))}
                                <hr className="line" />
                                <div className='d-flex justify-content-center'>
                                    <SubscriberList subscribers={subscribers} />
                                </div>
                                <SideNav isOpen={isOpen} closeNav={closeNav} />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default MessageShow;
