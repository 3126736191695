import React from 'react'
import { Link } from 'react-router-dom'
import { LuKey } from "react-icons/lu";

const MoveProject = () => {
  return (
    <div className='MergePeople todo-contain'>
      <div className=' text-center d-block m-auto card--head secondary-class b-shad' style={{ width: '65vw', height: '6vh' }}>
        <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
          <Link to='/adminland' className='m-2 fw-bold'><LuKey /> Adminland</Link>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="body-bottom text-center secondary-class b-shad" style={{ width: '75vw' }}>
          <div className="container ">
            <div className="">
              <p className='h2 fw-bold pt-3'>Move projects from Teamnex 2</p>
            </div>
            <hr />
            <div className='body-content'>
              <p className='px-5 pt-3 text-start'>It looks like you’re not listed as an owner on any Teamnex 2 accounts. Only the owner of a Teamnex 2 account can move their projects to a Teamnex 4 account.</p>
              <p className='px-5 py-2 text-start'>Need help? <Link>Contact our support team</Link> and we’ll take a peek!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MoveProject