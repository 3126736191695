import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaFile, FaPlus } from "react-icons/fa";
import { FaRegBookmark } from 'react-icons/fa6';
import { MdEdit, MdOutlineSort } from "react-icons/md";
import { Link } from 'react-router-dom';
import { HiOutlineLink } from 'react-icons/hi';
import { FiArchive } from "react-icons/fi";

const CardTable = () => {
    const [isOpen, setIsOpen] = useState(false);

    const openNav = () => {
        setIsOpen(true);
    };

    const closeNav = () => {
        setIsOpen(false);
    };
    return (
        <div className='campfire todo-contain'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <Link to="/project" className="fw-bold">Webnexs HQ</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center" style={{ width: '75vw' }}>
                    <div className="card secondary-class campfireBody">
                        <div className="container ">
                            <div className="d-flex justify-content-between">
                                <div className=" text-start">
                                    <button className="newEvent px-2 mt-3 py-1 text-dark">
                                        <FaPlus className='me-1' />Add a card
                                    </button>
                                </div>
                                <div className=" text-center mt-3">
                                    <a href="" className='headcamp fw-bold'> <h3 className="mb-0 t-color1">Card Table</h3> </a>
                                </div>
                                <div className=" text-end">
                                    <button className="Notifybtn " onClick={openNav}>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </button>
                                </div>
                            </div>
                            <hr className="line" />
                        </div>
                        <div className=' p-3'>
                            <p className=' text-start'>Triage(0)</p>
                            <div tabIndex="" className='p-5 cardDot'></div>
                        </div>
                        <hr className="cardline" />
                        <div className="" style={{ height: '70vh' }} />
                        <div className="text-start">
                            <div id="mySidenav" className={`CampfireSidenav messageboardSidenav ${isOpen ? 'open' : ''}`}>
                                <span className="closebtn" onClick={closeNav}>&times;</span>
                                <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaRegBookmark /></button>Bookmark</Link>
                                </div>
                                <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><HiOutlineLink /></button>Get a public link </Link>
                                </div>
                                <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FiArchive /></button>View archived columns</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardTable
