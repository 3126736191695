// import React, { useRef, useState, useEffect } from 'react';
// import { SiVerizon } from "react-icons/si";
// import { FiMonitor } from "react-icons/fi";
// import { CiLight } from "react-icons/ci";
// import { MdDarkMode } from "react-icons/md";
// import moment from 'moment-timezone';
// import 'react-datepicker/dist/react-datepicker.css';
// import { FaCheck } from "react-icons/fa";
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// const Preferences = ({ userPreferences, setUserPreferences }) => {
//     const [timeZone, setTimeZone] = useState('Asia/Kolkata');
//     const [selectedDay, setSelectedDay] = useState('');
//     const [themeColor, setThemeColor] = useState('#e1e5e8'); // Default color theme
//     const [appearanceMode, setAppearanceMode] = useState('Always Dark'); // Default to 'Same as OS'
//     const [timeFormat, setTimeFormat] = useState('12-hour');
//     const ref = useRef();
//     const [modeColors, setModeColors] = useState({});
// const location = useLocation();
// const userDataFromState = location.state?.userData || null;
// const storedUserData = localStorage.getItem('userData');
// const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
// const userId = userData?.user?._id
//     useEffect(() => {
//         if (userPreferences) {
//             // Set initial UI based on userPreferences
//             const { colorTheme, appearanceMode } = userPreferences;
//             document.documentElement.style.setProperty('--theme-color', colorTheme);
//             document.documentElement.setAttribute('data-appearance', appearanceMode);
//         }
//     }, [userPreferences]);
//     // Function to handle timezone selection change
//     const handleTimeZoneChange = (event) => {
//         setTimeZone(event.target.value);
//     };

//     // Function to handle theme color change
//     const handleColorChange = (color) => {
//         setThemeColor(color);
//         document.documentElement.style.setProperty('--theme-color', color);
//         updateUserPreferences({ colorTheme: color });
//     };

//     // Function to handle appearance mode change
//     const handleAppearanceChange = (mode) => {
//         setAppearanceMode(mode);
//         document.documentElement.setAttribute('data-appearance', mode);
//         // Call API to save appearance mode to the database
//         updateUserPreferences({ appearanceMode: mode });
//     };
//     useEffect(() => {
//         if (userId) {
//             axios.get(`${process.env.REACT_APP_API_URL}/api/get-preference`, { params: { userId } })
//                 .then(response => {
//                     const { colorTheme, appearanceMode } = response.data.preferences;
//                     setThemeColor(colorTheme);
//                     setAppearanceMode(appearanceMode);
//                     setUserPreferences(response.data.preferences);
//                 })
//                 .catch(error => console.error('Error fetching preferences:', error));
//         }
//     }, [userId]);

//     const updateUserPreferences = (updatedData) => {
//         if (!userId) return;

//         axios.put(`${process.env.REACT_APP_API_URL}/api/change-theme`, { userId, updatedData })
//             .then(response => {
//                 console.log('Preferences updated successfully:', response.data);
//                 setUserPreferences(response.data.preferences); // Update local state with the new preferences
//             })
//             .catch(error => {
//                 console.error('Error updating preferences:', error);
//             });
//     };

//     useEffect(() => {
//         document.documentElement.style.setProperty('--theme-color', themeColor);
//     }, [themeColor]);

//     useEffect(() => {
//         document.documentElement.setAttribute('data-appearance', appearanceMode);
//     }, [appearanceMode]);

//     const days = [
//         { value: 'sunday', label: 'Sunday' },
//         { value: 'monday', label: 'Monday' },
//         { value: 'tuesday', label: 'Tuesday' },
//         { value: 'wednesday', label: 'Wednesday' },
//         { value: 'thursday', label: 'Thursday' },
//         { value: 'friday', label: 'Friday' },
//         { value: 'saturday', label: 'Saturday' },
//     ];

//     const handleChange = (event) => {
//         setSelectedDay(event.target.value);
//     };

//     // Helper function to get timezone options with GMT offsets
//     const getTimeZoneOptions = () => {
//         return moment.tz.names().map((tz) => {
//             const offset = moment.tz(tz).utcOffset() / 60;
//             const sign = offset >= 0 ? "+" : "-";
//             const hours = Math.floor(Math.abs(offset)).toString().padStart(2, '0');
//             const minutes = (Math.abs(offset) % 1) * 60;
//             return {
//                 value: tz,
//                 label: `(GMT ${sign}${hours}:${minutes === 0 ? "00" : minutes}) ${tz.replace("_", " ")}`
//             };
//         });
//     };

//     return (
//         <div className='mt-5 preferencesCard'>
//             <div className='card-Preferences mb-5'>
//                 <div className='m-2'>
//                     <h2 className='text-light fw-bold d-flex justify-content-center mb-3'>Preferences</h2>

//                     {/* Color Theme Section */}
//                     <h5 className='text-light fw-bold d-flex justify-content-center'>Color Theme</h5>
//                     <p className='text-light d-flex justify-content-center'>Choose a color for buttons, menus, and accents</p>
//                     <div className='d-flex justify-content-center m-2 mb-4'>
//                         {['#e1e5e8', '#bb95f1', '#67b0f9', '#6fc082', '#f78865', '#8EB1C3'].map(color => (
//                             <label key={color} className={`color-circle ${themeColor === color ? 'selected' : ''}`} onClick={() => handleColorChange(color)} style={{ backgroundColor: color }}>
//                                 {themeColor === color && (
//                                     <span className={`checkmark ${appearanceMode === 'Always Dark' ? 'checkmark-dark' : 'checkmark-light'}`}>
//                                         ✓
//                                     </span>
//                                 )}
//                             </label>
//                         ))}
//                     </div>

//                     {/* Appearance Section */}
//                     <h5 className='text-light fw-bold d-flex justify-content-center mb-4'>Appearance</h5>
//                     <div className='d-flex justify-content-center'>
//                         <button type="button" className={`appearanceBtn ${appearanceMode === 'Always Light' ? 'active' : ''}`} onClick={() => handleAppearanceChange('Always Light')}>
//                             <CiLight className='appearanceIcon' />
//                             <p className='appearanceTxt'>Always light</p>
//                         </button>
//                         <button type="button" className={`appearanceBtn ${appearanceMode === 'Always Dark' ? 'active' : ''}`} onClick={() => handleAppearanceChange('Always Dark')}>
//                             <MdDarkMode className='appearanceIcon' />
//                             <p className='appearanceTxt'>Always dark</p>
//                         </button>
// <button type="button" className={`appearanceBtn ${appearanceMode === 'Same as OS' ? 'active' : ''}`} onClick={() => handleAppearanceChange('Same as OS')}>
//     <FiMonitor className='appearanceIcon' />
//     <p className='appearanceTxt'>Same as OS</p>
// </button>
//                     </div>

//                     {/* Date/Time Section */}
//                     <h5 className=' fw-bold d-flex justify-content-center mt-4 '>Date/Time</h5>
//                     <div>
//                         <p className='text-light fw-bold m-0'>Time zone</p>
//                         <p className='text-light timezoneText m-0'>
//                             TeamNex uses your time zone setting for email notifications, event reminders, <a href="#">your profile</a>, and <a href="/notificationsetting">your notification settings</a>.
//                         </p>
//                         <span className='mb-3'>
//                             <select className='col-12 px-2 proinput mb-3 text-light' id="timezone-select" value={timeZone} onChange={handleTimeZoneChange}>
//                                 {getTimeZoneOptions().map((tz) => (
//                                     <option key={tz.value} value={tz.value}>
//                                         {tz.label}
//                                     </option>
//                                 ))}
//                             </select>
//                         </span>

//                         <p className='text-light fw-bold m-0'>First day of the week</p>
//                         <p className='text-light timezoneText m-0'>TeamNex uses this when presenting calendars.</p>
//                         <select
//                             className='px-2 preferenceinput mb-3 text-light'
//                             id="day-selector"
//                             value={selectedDay}
//                             onChange={handleChange}
//                         >
//                             {days.map((day) => (
//                                 <option key={day.value} value={day.value}>
//                                     {day.label}
//                                 </option>
//                             ))}
//                         </select>

//                         <p className='text-light fw-bold m-0'>Time format</p>
//                         <p className='text-light timezoneText m-0'>TeamNex uses this format when displaying the time.</p>
//                         <select
//                             className='px-2 preferenceinput mb-3 text-light'
//                             id="time-format-select"
//                             value={timeFormat}
//                             onChange={(e) => setTimeFormat(e.target.value)}
//                         >
//                             <option value="24-hour">24-hour</option>
//                             <option value="12-hour">12-hour</option>
//                         </select>
//                     </div>

//                     <span className='d-flex justify-content-center'>
//                         <button type="button" className="datetimeBtn mt-4">
//                             Save my Date/Time changes
//                         </button>
//                     </span>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Preferences;

import React, { useState, useEffect } from 'react';
import { SiVerizon } from "react-icons/si";
import { FiMonitor } from "react-icons/fi";
import { CiLight } from "react-icons/ci";
import { MdDarkMode } from "react-icons/md";
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCheck } from "react-icons/fa";
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const Preferences = ({ userPreferences, setUserPreferences }) => {
    const [timeZone, setTimeZone] = useState('Asia/Kolkata');
    const [selectedDay, setSelectedDay] = useState('');
    const [themeColor, setThemeColor] = useState(userPreferences?.colorTheme || '#e1e5e8'); // Default to user color theme
    const [appearanceMode, setAppearanceMode] = useState(userPreferences?.appearanceMode || 'Always Dark'); // Default to user appearance mode
    const [timeFormat, setTimeFormat] = useState('12-hour');
    const location = useLocation();
    const userDataFromState = location.state?.userData || null;
    const storedUserData = localStorage.getItem('userData');
    const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
    const userId = userData?.user?._id
    // UseEffect to apply userPreferences on initial render
    useEffect(() => {
        if (userPreferences) {
            const { colorTheme, appearanceMode } = userPreferences;
            document.documentElement.style.setProperty('--theme-color', colorTheme);
            document.documentElement.setAttribute('data-appearance', appearanceMode);
        }
    }, [userPreferences]);

    // Handle theme color change
    const handleColorChange = (color) => {
        setThemeColor(color);
        document.documentElement.style.setProperty('--theme-color', color);
        updateUserPreferences({ colorTheme: color });
    };

    // // Handle appearance mode change
    // const handleAppearanceChange = (mode) => {
    //     setAppearanceMode(mode);
    //     document.documentElement.setAttribute('data-appearance', mode);
    //     updateUserPreferences({ appearanceMode: mode });
    // };

    const handleAppearanceChange = (mode) => {
        setAppearanceMode(mode);
        document.documentElement.setAttribute('data-appearance', mode);

        // Set CSS custom properties for each color in modeColors
        const colors = modeColors[mode];
        if (colors) {
            Object.keys(colors).forEach((key) => {
                document.documentElement.style.setProperty(`--${key}`, colors[key]);
            });
        }

        // Call the API to update the appearance mode in the backend
        updateUserPreferences({ appearanceMode: mode });
    };

    // Mode colors based on appearance modes
    const modeColors = {
        'Always Light': {
            accent: '#c1c1c1',
            highlight: '#d1d1d1',
            primary: '#ffffff',
            secondary: '#f0f0f0',
        },
        'Always Dark': {
            accent: '#555555',
            highlight: '#666666',
            primary: '#333333',
            secondary: '#444444',
        },
        'Same as OS': {
            accent: '#e1e5e8', // Default light accent color
            highlight: '#f0f0f0', // Default light highlight color
            primary: '#ffffff', // Default light primary color
            secondary: '#cccccc', // Default light secondary color
        },
    };

    // Update preferences in the backend
    const updateUserPreferences = (updatedData) => {
        if (!userId) return;

        axios.put(`${process.env.REACT_APP_API_URL}/api/change-theme`, { userId, updatedData })
            .then(response => {
                console.log('Preferences updated successfully:', response.data);
                setUserPreferences(response.data.preferences); // Update local state with the new preferences
            })
            .catch(error => {
                console.error('Error updating preferences:', error);
            });
    };

    // Get timezone options
    const getTimeZoneOptions = () => {
        return moment.tz.names().map((tz) => {
            const offset = moment.tz(tz).utcOffset() / 60;
            const sign = offset >= 0 ? "+" : "-";
            const hours = Math.floor(Math.abs(offset)).toString().padStart(2, '0');
            const minutes = (Math.abs(offset) % 1) * 60;
            return {
                value: tz,
                label: `(GMT ${sign}${hours}:${minutes === 0 ? "00" : minutes}) ${tz.replace("_", " ")}`
            };
        });
    };

    // Days of the week
    const days = [
        { value: 'sunday', label: 'Sunday' },
        { value: 'monday', label: 'Monday' },
        { value: 'tuesday', label: 'Tuesday' },
        { value: 'wednesday', label: 'Wednesday' },
        { value: 'thursday', label: 'Thursday' },
        { value: 'friday', label: 'Friday' },
        { value: 'saturday', label: 'Saturday' },
    ];

    const handleDayChange = (event) => {
        setSelectedDay(event.target.value);
    };

    return (
        <div className='mt-5 preferencesCard'>
            <div className='card-Preferences mb-5'>
                <div className='m-2'>
                    <h2 className=' fw-bold d-flex justify-content-center mb-3'>Preferences</h2>

                    {/* Color Theme Section */}
                    <h5 className=' fw-bold d-flex justify-content-center'>Color Theme</h5>
                    <p className=' d-flex justify-content-center'>Choose a color for buttons, menus, and accents</p>
                    <div className='d-flex justify-content-center m-2 mb-4'>
                        {['#e1e5e8', '#bb95f1', '#67b0f9', '#6fc082', '#f78865', '#8EB1C3'].map(color => (
                            <label key={color} className={`color-circle ${themeColor === color ? 'selected' : ''}`} onClick={() => handleColorChange(color)} style={{ backgroundColor: color }}>
                                {themeColor === color && (
                                    <span className={`checkmark ${appearanceMode === 'Always Dark' ? 'checkmark-dark' : 'checkmark-light'}`}>
                                        ✓
                                    </span>
                                )}
                            </label>
                        ))}
                    </div>

                    {/* Appearance Section */}
                    <h5 className=' fw-bold d-flex justify-content-center mb-4'>Appearance</h5>
                    <div className='d-flex justify-content-center'>
                        <button type="button" className={`appearanceBtn ${appearanceMode === 'Always Light' ? 'active' : ''}`} onClick={() => handleAppearanceChange('Always Light')}>
                            <CiLight className='appearanceIcon' />
                            <p className='appearanceTxt'>Always light</p>
                        </button>
                        <button type="button" className={`appearanceBtn ${appearanceMode === 'Always Dark' ? 'active' : ''}`} onClick={() => handleAppearanceChange('Always Dark')}>
                            <MdDarkMode className='appearanceIcon' />
                            <p className='appearanceTxt'>Always dark</p>
                        </button>
                        <button type="button" className={`appearanceBtn ${appearanceMode === 'Same as OS' ? 'active' : ''}`} onClick={() => handleAppearanceChange('Same as OS')}>
                            <FiMonitor className='appearanceIcon' />
                            <p className='appearanceTxt'>Same as OS</p>
                        </button>
                    </div>

                    {/* Date/Time Section */}
                    <h5 className='fw-bold d-flex justify-content-center mt-4 '>Date/Time</h5>
                    <div>
                        <p className=' fw-bold m-0'>Time zone</p>
                        <p className=' timezoneText '>
                            TeamNex uses your time zone setting for email notifications, event reminders, <a href="#">your profile</a>, and <a href="/notificationsetting">your notification settings</a>.
                        </p>
                        <select className='col-12 px-2 proinput mb-3 ' id="timezone-select" value={timeZone} onChange={(e) => setTimeZone(e.target.value)}>
                            {getTimeZoneOptions().map((tz) => (
                                <option key={tz.value} value={tz.value}>{tz.label}</option>
                            ))}
                        </select>

                        <p className=' fw-bold m-0'>First day of the week</p>
                        <p className=' timezoneText mb-1'> Teamnex uses this when presenting calendars.</p>
                        <select className='px-2 preferenceinput mb-3 ' id="day-selector" value={selectedDay} onChange={handleDayChange}>
                            {days.map((day) => (
                                <option key={day.value} value={day.value}>{day.label}</option>
                            ))}
                        </select>

                        <p className=' fw-bold m-0'>Time format</p>
                        <p className=' timezoneText mb-1'>Teamnex uses this when presenting calendars.</p>
                        <select className='px-2 preferenceinput mb-3 ' id="time-format-select" value={timeFormat} onChange={(e) => setTimeFormat(e.target.value)}>
                            <option value="24-hour">24-hour</option>
                            <option value="12-hour">12-hour</option>
                        </select>
                    </div>

                    <span className='d-flex justify-content-center'>
                        <button type="button" className="datetimeBtn mt-4">
                            Save my Date/Time changes
                        </button>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Preferences;
