import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useLocation, useParams, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { RiKey2Fill } from 'react-icons/ri';
import { GiPin } from "react-icons/gi";
import axios from 'axios';
import Loader from '../Loader';
import { jwtDecode } from 'jwt-decode';


const Home = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem('token')
  console.log(data)
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      const decodedToken = jwtDecode(savedToken);
      setData(decodedToken)
      // Check if the token is expired      
    } else {
      console.log('No valid userData found in localStorage');
    }
  }, []);
  const organizationId = data ? data.organizationId : ""
  const location = useLocation();
  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
  const userId = userData?.user?._id
  const [cookies] = useCookies(['token']);
  const [date, setDate] = useState(new Date());
  const [projects, setProjects] = useState([]);
  const [isValidOrganization, setIsValidOrganization] = useState(true);
  const [orgName, setOrgname] = useState(null)
  const [rolePermissions, setRolePermissions] = useState(null);
  // Default projects
  // const defaultProjects = [
  //   { _id: "66bee29ef5207463205143e0", name: 'Getting Started', description: 'Quickly get up to speed with everything TeamNex' },
  //   { _id: "66bee29ef5207463205143e0", name: 'Making a podcast', description: '👋 This is a sample project to showcase how we use TeamNex to make a podcast called REWORK.' }
  // ];

  // Safeguard hook for organization validation, always called
  useEffect(() => {
    // const token = userData?.token
    // console.log(token)
    if (!token) {
      setLoading(true)
      navigate('/');
      return;
    }
    const checkOrganizationValidity = async () => {
      if (organizationId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/checkOrganization/${organizationId}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          // Check if the response indicates the organization exists
          if (response.data) {
            setOrgname(response?.data?.name)
            setIsValidOrganization(true); // Organization exists
          } else {
            setIsValidOrganization(false); // Organization does not exist
          }
        } catch (error) {
          console.error('Error checking organization validity:', error);
          setIsValidOrganization(false); // Mark as invalid if an error occurs (like 404)
        } finally {
          // setLoading(false); // Set loading to false when done
        }
      }
      else {
        <Loader />
      }
    };

    checkOrganizationValidity();
  }, [Navigate, cookies.token, organizationId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (userData && userData.token && projects.length === 0) {
        const fetchProjects = async () => {
          try {
            // const token = userData.token;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/userprojects`,

              { headers: { 'Authorization': `Bearer ${token}` }, params: { userId, organizationId } });
            const combinedProjects = response.data.length > 0
              ? [...response.data]
              : [];
            setProjects([...combinedProjects]);
            setLoading(false)
          } catch (error) {
            console.log("Error fetching projects:", error.message);
          }
        };

        fetchProjects();
      }
    }, 2000)
    return () => clearTimeout(timeoutId);

  }, [userData]);
  // useEffect(() => {
  //   const fetchRoles = async (req, res) => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/role-permissions/${userData.user._id}`);
  //       setRolePermissions(response.data);
  //     } catch (err) {
  //       console.error("Error fetching role permissions", err);
  //     }
  //   }
  //   fetchRoles()
  // }, [userData.user._id])
  const onChange = (newDate) => {
    setDate(newDate);
  };
  if (!isValidOrganization) {
    // Redirect to the login page if the organization is invalid
    setTimeout(() => {
      navigate('*'); // Adjust the route to your login page
    }, 1000);
    return <p>Invalid organization ID. Redirecting to the login page...</p>; // Or you can show a message here
  }
  // console.log(rolePermissions)
  return (

    <div className='Home home-main'>
      <div className='head1'>
        <div className="px-2 text-end">
          <Link to="adminland" className="px-2 d-contents adminlandText">
            <RiKey2Fill className='mt-1' style={{ fontSize: "15px" }} />
            Adminland
          </Link>
        </div>

        <h3 className='d-flex justify-content-center fw-bold Organization-name'>
          {orgName ? orgName : <Loader />}
        </h3>
      </div>
      <div className="head2 d-flex justify-content-center mt-3">
        <Link className='act-btn' to="project/new">
          <button className='px-4 py-2 mx-3 action-btn'>
            <span className='fw-normal '>Make a new project</span>
          </button>
        </Link>
        <Link className='act-btn' to='account/enrollments/new'>
          <button className=' px-4 py-2 action-btn'>
            <span className='fw-normal '>Invite People</span>
          </button>
        </Link>
      </div>
      <div className="d-flex justify-content-center mt-3 col-sm-12">
        <p className='p-tag'>
          <span className='t-color1'>Pinned & recent projects below &middot; </span>
          <span><Link to="projects/directory" className="mx-1 t-color1">View all in a list</Link></span>
          <span>&middot; </span>
          <span><Link to="view-template" className="mx-1 t-color1">View templates</Link></span>
          <span className='t-color1 kbd'>&middot; Press
            <Link to="quickaccess" className='text-decoration-none t-color1'> <kbd > Ctrl + J</kbd></Link>
            anytime to jump</span>
        </p>
      </div>
      <div className=" mt-5">
        <div className=" d-flex flex-column flex-md-row flex-wrap justify-content-center mt-4">
          <hr className="line" />
          <span className="label text-center recently-visited">RECENTLY VISITED</span>
        </div>
      </div>
      {loading ? <Loader /> :
        <div className="d-flex flex-column flex-md-row flex-wrap justify-content-center mt-0">
          {
            projects.map((project, index) => (
              <Link
                to={{
                  pathname: `project/${project._id}`, // Ensure this is consistent
                  state: { tools: project.tools },
                }}

                className="text-decoration-none"
                // key={project._id}
                key={`${project.id}-${index}`}
              >

                <div className="card announcement-card m-2 w-md-auto">
                  <div className="homecard-body p-3">
                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                      <div className="w-100">
                        <div className="badge bg-warning t-color1 mb-2">
                          {project.category}
                        </div>
                        <h5 className="card-title t-color1">{project.name}</h5>
                        <p className="card-text t-color1">{project.description}</p>
                      </div>
                      <div className="d-flex justify-content-end mt-2 mt-md-0">
                        <button
                          className="pin-button"
                          onClick={(e) => {
                            e.preventDefault(); // Prevents link navigation when pin button is clicked
                            // togglePin(project.id); // Ensure project.id is correctly passed
                          }}
                        >
                          {project.pinned ? <GiPin className="color-icon" /> : <GiPin className="pin-icon" />}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          }      </div>
      }
      <div className="container mt-5 mb-5 d-flex justify-content-center HomeCardCont">
        <div className="card card1 schedule-assignment">
          <div className="card-header  border-0 text-center">
            <div className="schedule-header">
              <span>YOUR SCHEDULE</span>
            </div>
          </div>
          <div className="card-body text-center">
            <div className="calendar mt-2">
              <Calendar
                className=" calendar"
                onChange={onChange}
                value={date}
              />
              <div className="calendar-footer d-flex justify-content-between mt-3 t-color1">
                <p className=' t-color1'>
                  {date.toDateString()}
                </p>
                <p className=' t-color1'>Nothing's on the schedule</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card card1 schedule-assignment">
          <div className="card-header  border-0 text-center">
            <div className="assignments-header">
              <span>YOUR ASSIGNMENTS</span>
            </div>
          </div>
          <div className="card-body text-center mt-5">
            <FontAwesomeIcon icon={faCircleCheck} className="home-shedule" />
            <p className="mt-3 text-secondary">
              You don’t have any assignments right now. To-dos and cards assigned to you will show up here.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;