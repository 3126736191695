import React, { useState, useEffect,useRef  } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaPlus } from "react-icons/fa";
import { RiPieChartFill } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import "quill/dist/quill.core.css";
import toast from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

const ToDos = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showTodoForm, setShowTodoForm] = useState(false);
    const [listName, setListName] = useState('');
    const [isTracked, setIsTracked] = useState(false);
    const [todoLists, setTodoLists] = useState([]);
    const [content, setContent] = useState('');
    const [todocontent, settodoContent] = useState('');
    const [todos, setTodos] = useState([]); 

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [task_task, settask_task] = useState('')
    const [assignedUser, setAssignedUser] = useState([]);
    const [notyfyuser, setnotyfyuser] = useState('')
    const [users, setUsers] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [todolistid, settodolistid] = useState('');
    // const [editorValue, setEditorValue] = useState(''); // State to store editor content

    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        settodolistid('')
    }
    const handleShow = (id) => {
        setShow(true);
        settodolistid(id);

    } ;

    const { id , organizationId} = useParams();

    // view_todo_list
    useEffect(() => {    
        fetchTodos();
        fetchAvailableUser();
      }, [organizationId ,id]); 

      const fetchTodos = async () => {
        var formData = {
            project : id,
            id : organizationId
        }
        try {
          const token = localStorage.getItem('authToken'); // Retrieve the token from local storage
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/userapi/view_todo_list`,
            formData
        );
          // Set fetched user info to todo

          setTodos(response.data.todos);
          console.log(response.data.todos);
          
        } catch (err) {
          // Handle errors by setting the error state
          console.log(err.response ? err.response.data.message : 'Something went wrong');
        } finally {
          console.log('Something went wrong')
        }
      };
    // view_todo_list

    //  add doodo list  
    const add_tolist = async (e) => {
        e.preventDefault(); // Prevent form reload
        try {
            var formData = {
                content : content,
                listname : listName,
                project : id,
                id : organizationId
            }
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/userapi/add_todo_list`,
            formData
          );
          if (response.data.status == true) {
            setShowTodoForm(false)
            toast.success(response.data.message);
            fetchTodos();
            closeNav();
            setContent('');
            setListName('');
          }else if(response.data.status == false){
            toast.error(response.data.message);
           
          }
        } catch (err) {
            toast.error(err.response?.data?.error || "Something went wrong!");
        }
      };
    //  add doodo list  
    //  add task 
    const add_task = async (e) => {
        e.preventDefault(); // Prevent form reload
        try {
            var formData = {
                organizationId : content,
                task_name : listName,
                assigned_to : id,
                notify : organizationId,
                from:startDate,
                to:endDate,
                note:task_task
            }
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/userapi/add_task`,
            formData
          );
          if (response.data.status == true) {
            setShowTodoForm(false)
            toast.success(response.data.message);
            fetchTodos();
            closeNav();
            setContent('');
            setListName('');
          }else if(response.data.status == false){
            toast.error(response.data.message);
           
          }
        } catch (err) {
            toast.error(err.response?.data?.error || "Something went wrong!");
        }
      };
       //  add task 
    // availableUsers
    const [availableUsers, setAvailableUsers] = useState([]) 

    console.log();
    
    const fetchAvailableUser = async () => {
    var formData = {
        projrctid : id,
        organizationId : organizationId
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/userapi/getprojectuser`,formData);
            console.log(response.data.users)
            const options = response.data.users.map(user => ({
                value: user._id,
                label: user.username
            }));

            setAvailableUsers(options);
        } catch (error) {
            console.error('Error fetching available members:', error);
            console.log(error.message)
        }
    }
    const handleSelectChange = (selectedOptions) => {
        setAssignedUser(selectedOptions)
    }

    const handleSelectChange2 = (selectedOptions) => {
        setnotyfyuser(selectedOptions)        
    }
       // availableUsers
    const quillRef = useRef(null);
    const modules = {
        toolbar: {
            container: [
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'bullet'},{'indent': '+1'}],
                ['link', 'image'],
                ['clean']
            ]
        }
    };

    const formatOptionLabel = ({ label, profilePhoto }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {profilePhoto ? (
                <img
                    src={profilePhoto}
                    alt="Profile"
                    style={{ width: 24, height: 24, borderRadius: '50%', marginRight: 10 }}
                />
            ) : (
                <span
                    style={{
                        width: 24,
                        height: 24,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        backgroundColor: '#4caf50',
                        color: '#fff',
                        marginRight: 10,
                        fontSize: '12px',
                    }}
                >
                    {getInitials(label)}
                </span>
            )}
            {label}
        </div>
    );
    function getInitials(name = "") {
        if (!name) return "?";
        const initials = name
            .split(" ")
            .map(part => part.charAt(0).toUpperCase())
            .join("");
        return initials;
    }
    return (
        <>
        <div className='todopage'>
            <div className="campfire todo-contain">
                <div className="text-center d-block m-auto campfire card--head camp" style={{ width: '65vw' }}>
                    <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                        <button className="icon--button me-2">
                            <FontAwesomeIcon className='me-1' icon={faTh} />
                            <FontAwesomeIcon icon={faChevronDown} />
                        </button>
                        <span className="fw-bold text-primary text-decoration-underline" style={{ cursor: 'pointer' }}>
                            Demo todo
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="card text-center" style={{ width: '75vw' }}>
                        <div className="card secondary-class campfireBody" >
                            <div className="container">
                                <div className="d-flex justify-content-between">
                                    <button className="newEvent addbutton px-1 mt-4 py-1 text-dark" onClick={() => setShowTodoForm(true)}>
                                        <FaPlus className='me-1' /> New list
                                    </button>
                                    <div className="text-center  mt-3">
                                        <h3 className="mb-0 ">To-dos</h3>
                                        <RiPieChartFill className='text-success ms-2 fs-3' /> 0/4
                                    </div>
                                    <div className="text-end">
                                        <button className="Notifybtn " onClick={openNav}>
                                            <FontAwesomeIcon icon={faEllipsisH} />
                                        </button>
                                    </div>
                                </div>
                                <hr className="line" />
                            </div>

                            {showTodoForm && (
                                <div className="text-center">
                                    <div className="todocontainer">
                                        <div>
                                        <input type="text" placeholder="Name this list..." value={listName} onChange={(e) => setListName(e.target.value)} className="todoinput"/>
                                        </div>
                                        <div className='todo_list_dis'>
                                            <ReactQuill
                                                ref={quillRef}
                                                value={content}
                                                onChange={setContent}
                                                modules={modules}
                                                theme="snow"
                                                placeholder="Write something amazing..."
                                            />
                                        </div>
                                        <div className="todoactions">
                                            <div>
                                                <button className="todoadd-button me-4" onClick={add_tolist}>Add this list</button>
                                                <button className="todocancel-button" onClick={() => setShowTodoForm(false)}>Cancel</button>
                                            </div>
                                            <label className="todocheckbox-label">
                                                <input
                                                    type="checkbox"
                                                    checked={isTracked}
                                                    onChange={(e) => setIsTracked(e.target.checked)}
                                                    className="todocheckbox me-2"
                                                />
                                                Track this list on the Hill Chart
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='container px-5'>
                            {/* Todo list display */}
                            {todos.length > 0 ? (
                                    <div>
                                    {todos.map(todo => (
                                        <table className='todolist_table'>
                                            <tr>
                                                <td>
                                                    <div className='d-flex align-items-baseline toodo list page'>
                                                        <input className='check_box_completed' type='checkbox'  />
                                                        <div>
                                                            <Link to="#" key={todo._id} className=''>
                                                                <h3 className='m-0'>{todo.todo_title}</h3>
                                                            </Link>
                                                            <p className='todolistdiscription' dangerouslySetInnerHTML={{ __html: todo.todo_title_description }} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className='text-start'>
                                                <button className="addbutton newEvent px-1 text-dark" key={todo._id} onClick={() => handleShow(todo._id)}>
                                                    Add a to-do
                                                </button>
                                            </tr>
                                        </table>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No todos available</p>
                                )}
                            
                            </div>

                            {/* Sidebar for settings */}
                            <div id="mySidenav" className={`CampfireSidenav messageboardSidenav ${isOpen ? 'open' : ''}`}>
                                <span className="closebtn" onClick={closeNav}>&times;</span>
                                <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none'>
                                        <button className='me-2 sidebarBtn'><FaPlus /> Bookmark</button>
                                    </Link>
                                </div>
                                <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none'>
                                        <button className='me-2 sidebarBtn'><FaPlus /> Get a public link</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} dialogClassName="modal-90w"  aria-labelledby="example-custom-modal-styling-title">
            <div className='add_task_list'>
            <Modal.Header closeButton>
                <Modal.Title>Add Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                <input type="text" placeholder="Name this task..." value={listName} onChange={(e) => setListName(e.target.value)} className="todoinput form-control"/>
                <div>
                <lable>Assigned to</lable>
                <div className="mt-2 cre-select-user">
                <Select
                    closeMenuOnSelect={false}
                    className="proinput w-100"
                    placeholder="Type their names here..."
                    isMulti
                    options={availableUsers}
                    getOptionValue={(option) => option.value}
                    onChange={handleSelectChange}
                    formatOptionLabel={formatOptionLabel}
                />
                </div>
                <lable>When done, notify</lable>
                <div className="mt-2 cre-select-user">
                <Select
                    // closeMenuOnSelect={false}
                    className="proinput w-100"
                    placeholder="Type their names here..."
                    isMulti
                    options={availableUsers}
                    getOptionValue={(option) => option.value}
                    onChange={handleSelectChange2}
                    formatOptionLabel={formatOptionLabel}
                /> 
                </div>

                <lable>Due on</lable> 
                <div className='row'>
                <div className='col-6'>
                    <input type='date' className='form-control' onChange={(e) => setStartDate(e.target.value)} value={startDate} />
                </div>
                <div className='col-6'>
                    <input type='date' className='form-control' onChange={(e) => setEndDate(e.target.value)} value={endDate} />
                </div>
                </div>
                <div className='mt-5'>
                    <ReactQuill
                    ref={quillRef}
                    value={todocontent}
                    onChange={settask_task}
                    modules={modules}
                    theme="snow"
                    placeholder="Write something amazing..."
                    />
                </div>

                </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
            </Modal.Footer>        
            </div>
            </Modal>
        </div>
        </>
    );
};

export default ToDos;
