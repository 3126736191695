import React from 'react'
import { Rings } from "react-loader-spinner";
const Loader = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
            <Rings
                visible={true}
                height="80"
                width="80"
                color="green"
                ariaLabel="rings-loading"
            />
        </div>
    )
}

export default Loader
