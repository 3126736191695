import React, { createContext, useState, useContext, useEffect } from "react";
import { getUser } from "../Auth/api";
import axios from "axios";

const UserContext = createContext()


const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     const fetchUser = async () => {
    //         const token = localStorage.getItem("token");
    //         // console.log("checinf", token)
    //         setLoading(true)
    //         if (!token) return;
    //         try {
    //             const response = await axios.get(`${process.env.REACT_APP_API_URL}/register/user`, {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`
    //                 }
    //             })
    //             setUser(response.data);
    //             // console.log(response.data)
    //         } catch (error) {
    //             setError(error.message);
    //         }
    //         finally {
    //             setLoading(false);
    //         }
    //     };
    //     fetchUser()
    // }, []);
    return (
        <UserContext.Provider value={{ user, error, loading }}>
            {children}
        </UserContext.Provider>
    );
}
export { UserContext, UserProvider }