import React from 'react'
import { RiKey2Fill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import PlanCard from './PlanCard';
import FAQSection from './FAQSection';

const PlanSelection = () => {
    return (
        <div className='campfire todo-contain'>
            <div className=' text-center d-block m-auto campfire card--head camp secondary-class' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <Link to="/adminland" className="fw-bold"><RiKey2Fill style={{
                        fontSize: "20"
                    }} />Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card " style={{ width: '75vw' }}>
                    <div className="card secondary-class b-shad campfireBody">
                        <div className="container ">
                            <div className=" text-center mt-3">
                                <a href="a" className='headcamp fw-bold'> <h3 className="mb-0 ">Choose the plan that fits you best</h3> </a>
                            </div>
                            <hr className="line" />
                        </div>
                        <div className="card-body secondary-class p-0" >
                            <div className="adminPlan ">
                                <section className="plan-selection">
                                    <PlanCard
                                        planTitle="TeamNex"
                                        planSubtitle="PRO UNLIMITED"
                                        price="$59/mo"
                                        billingDetails="Billed annually at $708"
                                        features={[
                                            "Every feature we offer, plus...",
                                            "10x file & document storage (1 TB)",
                                            "First-in-line 24/7/365 priority support",
                                            "1:1 onboarding tour with our team",
                                            "Annual billing for simplified accounting",
                                            "Includes the Timesheet add-on to give your team the power to track time spent on projects",
                                            "Includes the Admin Pro Pack add-on for greater control over access and permissions",
                                        ]}
                                        includedAddOns={["Timesheet", "Admin Pro Pack"]}
                                    />
                                </section>
                                <section className="faq-section">
                                    <FAQSection />
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlanSelection;
