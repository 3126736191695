import React, { useState, useEffect } from 'react';
import { SiVerizon } from "react-icons/si";
import { FiMonitor } from "react-icons/fi";
import { CiLight } from "react-icons/ci";
import { MdDarkMode } from "react-icons/md";
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCheck } from "react-icons/fa";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const darkModeColors = ['#e1e5e8', '#bb95f1', '#67b0f9', '#6fc082', '#f78865', '#8EB1C3'];
const lightModeColors = ['#2b373e', '#8c5bd1', '#2377D2', '#19874d', '#cf432e', '#6f787c'];

const Preferences = ({ userPreferences, setUserPreferences }) => {
    const [timeZone, setTimeZone] = useState('Asia/Kolkata');
    const [selectedDay, setSelectedDay] = useState('');
    const [themeColor, setThemeColor] = useState(userPreferences?.colorTheme || '#e1e5e8');
    const [appearanceMode, setAppearanceMode] = useState(userPreferences?.appearanceMode || 'Always Dark');
    const [timeFormat, setTimeFormat] = useState('12-hour');
    const [colorOptions, setColorOptions] = useState(darkModeColors);
    const [selectedColorIndex, setSelectedColorIndex] = useState(darkModeColors.indexOf(themeColor));

    const location = useLocation();
    const userDataFromState = location.state?.userData || null;
    const storedUserData = localStorage.getItem('userData');
    const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
    const userId = userData?.user?._id;
    const [data, setData] = useState(null)
    console.log(data)
    useEffect(() => {
        const savedToken = localStorage.getItem('token');
        if (savedToken) {
            const decodedToken = jwtDecode(savedToken);
            setData(decodedToken)
        } else {
            console.log('No valid userData found in localStorage');
        }
    }, []);
    const organizationId = data ? data.organizationId : ""

    useEffect(() => {
        if (userPreferences) {
            const { colorTheme, appearanceMode } = userPreferences;
            document.documentElement.style.setProperty('--theme-color', colorTheme);
            document.documentElement.setAttribute('data-appearance', appearanceMode);
            setColorOptions(appearanceMode === 'Always Dark' ? darkModeColors : lightModeColors);
            setSelectedColorIndex(appearanceMode === 'Always Dark' ? darkModeColors.indexOf(colorTheme) : lightModeColors.indexOf(colorTheme));
        }
    }, [userPreferences]);

    const handleColorChange = (color, index) => {
        setThemeColor(color);
        setSelectedColorIndex(index);
        document.documentElement.style.setProperty('--theme-color', color);
        updateUserPreferences({ colorTheme: color });
    };

    const handleAppearanceChange = (mode) => {
        setAppearanceMode(mode);
        document.documentElement.setAttribute('data-appearance', mode);

        const newColorOptions = mode === 'Always Dark' ? darkModeColors : lightModeColors;
        setColorOptions(newColorOptions);

        const newIndex = Math.min(selectedColorIndex, newColorOptions.length - 1);
        setSelectedColorIndex(newIndex);
        setThemeColor(newColorOptions[newIndex]);

        updateUserPreferences({ appearanceMode: mode, colorTheme: newColorOptions[newIndex] });
    };

    const updateUserPreferences = (updatedData) => {
        if (!userId) return;

        axios.put(`${process.env.REACT_APP_API_URL}/api/change-theme`, { userId, updatedData, organizationId })
            .then(response => {
                console.log('Preferences updated successfully:', response.data);
                setUserPreferences(response.data.preferences);
            })
            .catch(error => {
                console.error('Error updating preferences:', error);
            });
    };
    const getTimeZoneOptions = () => {
        return moment.tz.names().map((tz) => {
            const offset = moment.tz(tz).utcOffset() / 60;
            const sign = offset >= 0 ? "+" : "-";
            const hours = Math.floor(Math.abs(offset)).toString().padStart(2, '0');
            const minutes = (Math.abs(offset) % 1) * 60;
            return {
                value: tz,
                label: `(GMT ${sign}${hours}:${minutes === 0 ? "00" : minutes}) ${tz.replace("_", " ")}`
            };
        });
    };

    // Days of the week
    const days = [
        { value: 'sunday', label: 'Sunday' },
        { value: 'monday', label: 'Monday' },
        { value: 'tuesday', label: 'Tuesday' },
        { value: 'wednesday', label: 'Wednesday' },
        { value: 'thursday', label: 'Thursday' },
        { value: 'friday', label: 'Friday' },
        { value: 'saturday', label: 'Saturday' },
    ];

    const handleDayChange = (event) => {
        setSelectedDay(event.target.value);
    };

    return (
        <div className='mt-5 preferencesCard'>
            <div className='card-Preferences mb-5'>
                <div className='m-2'>
                    <h2 className='fw-bold d-flex justify-content-center mb-3'>Preferences</h2>

                    {/* Color Theme Section */}
                    <h5 className='fw-bold d-flex justify-content-center'>Color Theme</h5>
                    <p className='d-flex justify-content-center'>Choose a color for buttons, menus, and accents</p>
                    <div className='d-flex justify-content-center m-2 mb-4'>
                        {colorOptions.map((color, index) => (
                            <label
                                key={color}
                                className={`color-circle ${themeColor === color ? 'selected' : ''}`}
                                onClick={() => handleColorChange(color, index)}
                                style={{ backgroundColor: color }}
                            >
                                {themeColor === color && (
                                    <span className={`checkmark ${appearanceMode === 'Always Dark' ? 'checkmark-dark' : 'checkmark-light'}`}>
                                        ✓
                                    </span>
                                )}
                            </label>
                        ))}
                    </div>

                    {/* Appearance Section */}
                    <h5 className='fw-bold d-flex justify-content-center mb-4'>Appearance</h5>
                    <div className='d-flex justify-content-center'>
                        <button type="button" className={`appearanceBtn ${appearanceMode === 'Always Light' ? 'active' : ''}`} onClick={() => handleAppearanceChange('Always Light')}>
                            <CiLight className='appearanceIcon' />
                            <p className='appearanceTxt'>Always light</p>
                        </button>
                        <button type="button" className={`appearanceBtn ${appearanceMode === 'Always Dark' ? 'active' : ''}`} onClick={() => handleAppearanceChange('Always Dark')}>
                            <MdDarkMode className='appearanceIcon' />
                            <p className='appearanceTxt'>Always dark</p>
                        </button>
                        <button type="button" className={`appearanceBtn ${appearanceMode === 'Same as OS' ? 'active' : ''}`} onClick={() => handleAppearanceChange('Same as OS')}>
                            <FiMonitor className='appearanceIcon' />
                            <p className='appearanceTxt'>Same as OS</p>
                        </button>
                    </div>

                    {/* Date/Time Section */}
                    {/* Rest of your Date/Time code */}
                    <h5 className='fw-bold d-flex justify-content-center mt-4 '>Date/Time</h5>
                    <div>
                        <p className=' fw-bold m-0'>Time zone</p>
                        <p className=' timezoneText '>
                            TeamNex uses your time zone setting for email notifications, event reminders, <a href="#">your profile</a>, and <a href="/notificationsetting">your notification settings</a>.
                        </p>
                        <select className='col-12 px-2 proinput mb-3 ' id="timezone-select" value={timeZone} onChange={(e) => setTimeZone(e.target.value)}>
                            {getTimeZoneOptions().map((tz) => (
                                <option key={tz.value} value={tz.value}>{tz.label}</option>
                            ))}
                        </select>

                        <p className=' fw-bold m-0'>First day of the week</p>
                        <p className=' timezoneText mb-1'> Teamnex uses this when presenting calendars.</p>
                        <select className='px-2 preferenceinput mb-3 ' id="day-selector" value={selectedDay} onChange={handleDayChange}>
                            {days.map((day) => (
                                <option key={day.value} value={day.value}>{day.label}</option>
                            ))}
                        </select>

                        <p className=' fw-bold m-0'>Time format</p>
                        <p className=' timezoneText mb-1'>Teamnex uses this when presenting calendars.</p>
                        <select className='px-2 preferenceinput mb-3 ' id="time-format-select" value={timeFormat} onChange={(e) => setTimeFormat(e.target.value)}>
                            <option value="24-hour">24-hour</option>
                            <option value="12-hour">12-hour</option>
                        </select>
                    </div>

                    <span className='d-flex justify-content-center'>
                        <button type="button" className="datetimeBtn mt-4">
                            Save my Date/Time changes
                        </button>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Preferences;
