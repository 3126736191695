import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const VerifyMail = () => {
    const { verifyId: token } = useParams();
    const [StatusMessage, setStatusMessage] = useState('Verifying...');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const isApiCalled = useRef(false);
    console.log(token);

    useEffect(() => {
        if (!token) {
            setStatusMessage("Invalid or missing token.");
            setLoading(false);
            return;
        }

        if (isApiCalled.current) return;

        // Mark that API call is in progress
        isApiCalled.current = true;

        const verifyMail = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/register/verify-email/${token}`);

                if (response.data.success) {
                    setStatusMessage("Email verified successfully! Click below to login");

                } else {
                    setStatusMessage(response.data.message || "Verification failed.");
                }
            } catch (error) {
                console.log("Error:", error);
                setStatusMessage("Verification failed. Token may have expired.");
            } finally {
                setLoading(false);
            }
        };

        verifyMail();
    }, [token, navigate]); // Only depend on token and navigate, not loading

    return (
        <div className="d-flex justify-content-center align-items-center mt-5 password">
            <div className="Loginpage text-center" style={{ maxWidth: '448px', width: '100%' }}>
                <img
                    src="https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public"
                    alt="Logo"
                    className="d-block m-auto"
                    style={{ width: '100px' }}
                />
                <h3 className="text-center card-title text-black text-m">Teamnex</h3>
                <div className="card p-4 mt-4 b-shad">
                    <div className="card-body">
                        <h1>Thank You</h1>
                        <h4 className="h6 mt-0 mb-2 text-black text-center fw-bold">{StatusMessage}</h4>

                        <button
                            className="loginBtn custom-btn btn-block mt-3 w-100"
                            onClick={() => (window.location.href = "/login")}
                        >
                            Login Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyMail;
