import React from 'react'
import { Link } from 'react-router-dom'
import { LuKey } from "react-icons/lu";

const ManageStorage = () => {
    return (
        <div className='MergePeople todo-contain'>
            <div className=' text-center d-block m-auto card--head secondary-class' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <Link to='/adminland' className='m-2 fw-bold'><LuKey /> Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="body-bottom text-center secondary-class b-shad" style={{ width: '75vw' }}>
                    <div className="container ">
                        <div className="">
                            <p className='h2 fw-bold pt-3'>Manage storage</p>
                        </div>
                        <hr />
                        <div className='body-content'>
                            <p className='px-5 pt-3 text-center'>This account using 1.49MB/1 TB available</p>
                            <div className='storage-bar text-center '>
                                <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                    <div class="progress-bar bg-success   " style={{ width: '20%' }}    ></div>
                                </div>
                            </div>
                            <p className='px-5 pt-3 text-start'>As the owner, you can access any project on this account — even if you weren’t specifically invited to it. But, it looks like you already have access to everything!</p>
                        </div>
                        <div className='body-content'>
                            <h4 className='px-5 pt-3 text-start'>Largest files</h4>
                            <p className='px-5  text-start m-0'>Click a file below to view or delete it. We’ll open the project first and add you to it if you’re not already on it.
                                As an Owner, you can see all files in all projects, except Pings and current drafts.</p>
                            <p className='px-5  text-start fw-light optionalNote'>Note: It may take a few minutes for this list to update.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ManageStorage
