

const SubscriberList = ({ subscribers }) => (
    <div className="subscribers text-start text-light p-3" >
        <p className="fw-bold m-0" style={{ borderBottom: '1px solid #2d3748', paddingBottom: '10px' }}>Subscribers</p>
        <p>{subscribers.length} people will be notified when someone comments on this message.</p>
        <div className="avatars d-flex align-items-center mb-3">
            {subscribers.map(subscriber => (
                <img key={subscriber.id} src={subscriber.avatar} alt={subscriber.name} className="rounded-circle me-2" style={{ width: '40px', border: '2px solid #2d3748' }} />
            ))}
            <button className="subscribers btn-sm ms-2">Add/remove people...</button>
        </div>
        <p className="fw-bold m-0" style={{ borderTop: '1px solid #2d3748', paddingTop: '10px' }}>You're subscribed</p>
        <p className='m-0'>You’ll get a notification when someone comments on this message.</p>
        <button className="subscribers btn-sm">Unsubscribe me</button>
    </div>
);

export default SubscriberList;