
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FaLongArrowAltLeft } from "react-icons/fa";

const AddPeopleToProject = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isEditorActive, setIsEditorActive] = useState(false); // State to track if editor is active

    const handleFocus = () => {
        setIsEditorActive(true); // Activate editor on focus
    };

    return (
        <div className='addPeopleContainer mt-5'>
            <div className='addPeopleSubContainer'>
                <Link to="/setup-people" className="fs-6"><FaLongArrowAltLeft className='text-light fs-6'/> Back to People on Webnexs</Link>
                <div className='my-3'>
                    <h2 className='fw-bold' style={{ color: 'white' }}>Add people to this project</h2>
                </div>
                <div className='p-4 add-bg rounded-2'>
                    {/* Input field for adding people */}
                    <div className="mb-3">
                        <label htmlFor="addPeopleInput" className="fw-bold" style={{ color: 'white' }}>
                            Who do you want to add to this project?
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="addPeopleInput"
                            placeholder="Type their names here..."
                            style={{ color:'white'}}
                        />
                        <Link to="" className="text-info fs-6">
                            Or pick people from a company...
                        </Link>
                    </div>
                    {/* Conditionally render either input or editor */}
                    <div className="mb-3">
                        <label htmlFor="invitationNote" className="fw-bold " style={{ color: 'white' }}>
                            Add an optional note to the invitation email
                        </label>
                        <div className='addtextAreaEditor'>
                            {isEditorActive ? (
                                <Editor
                                    className="textAreaEditor"
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    toolbar={{
                                        options: ['inline', 'list', 'link', 'history'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                                        },
                                        list: { options: ['unordered', 'ordered'] },
                                        link: { options: ['link'] },
                                    }}
                                    editorStyle={{ color: 'white', backgroundColor: '#transparent', border: '1ps splid white' }}
                                    toolbarStyle={{ backgroundColor: 'transparent', borderbottom: '1px solid white' }}
                                />
                                
                            ) : (
                                <input
                                    type="text"
                                    className="form-control "
                                    id='textAreaEditor'
                                    placeholder="Add an optional note here..."
                                    style={{ color: 'white', border: '1px solid rgb(56, 62, 68)' }}
                                    onFocus={handleFocus}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {/* Instructions for what happens next */}
                <div className="mt-3 text-muted" style={{ width: "47vw" }}>
                    <p className='text-center w-60 text-light'>
                       <span className='fw-bold'> What happens next?</span> Everyone above will be immediately added to the project and receive an email with instructions to join. They'll be able to see everything posted so far.
                    </p>
                </div>
                {/* Button to add people */}
                <div className="text-center">
                    <button className="h5 border rounded-pill border-dark px-3 py-3 addProjectPeopleBtn">
                        Add them to the project
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddPeopleToProject;
