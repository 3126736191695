import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaPlus } from "react-icons/fa";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { FaRegBookmark } from 'react-icons/fa6';
import { MdEdit } from "react-icons/md";
import { HiOutlineLink } from "react-icons/hi";
import { Link } from 'react-router-dom';

const ProjectSchedule = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    // const [date, setDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [notificationOption, setNotificationOption] = useState('noone');
    const [showEventForm, setShowEventForm] = useState(false);

    // const onChange = (newDate) => {
    //     setDate(newDate);
    // };

    const handleNotificationChange = (event) => {
        setNotificationOption(event.target.value);
    };

    const toggleEventForm = () => {
        setShowEventForm(!showEventForm);
    };

    const [isOpen, setIsOpen] = useState(false);

    const openNav = () => {
        setIsOpen(true);
    };

    const closeNav = () => {
        setIsOpen(false);
    };
    return (
        <div className='campfire'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <Link to="/project" className="fw-bold">Webnexs HQ</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center" style={{ width: '75vw' }}>
                    <div className="card bg-dark campfireBody">
                        <div className="container ">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className=" text-start">
                                    <button className="newEvent px-2 py-1   " onClick={toggleEventForm}>
                                        <FaPlus className='me-1' />New event
                                    </button>
                                </div>
                                <div className=" text-center">
                                    <a href="Schedule" className='headcamp fw-bold'> <h3 className="mb-0 text-light">Schedule</h3> </a>
                                </div>
                                <div className=" text-end">
                                    <button className="Notifybtn text-light" onClick={openNav}>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </button>
                                </div>
                            </div>
                            <hr className="line" />
                            <a href='pickCalendar' className='m-0'>Add this schedule to HEY Calendar, Google Calendar, Outlook, or iCal…</a>
                        </div>
                        <div className="Calendar-cont d-block mx-auto p-3">
                            <div className="text-start">
                                <div id="mySidenav" className={`CampfireSidenav scheduleSidenav ${isOpen ? 'open' : ''}`}>
                                    <span className="closebtn" onClick={closeNav}>&times;</span>
                                    <div className="P-2">
                                        <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaRegBookmark /></button>Bookmark</Link>
                                    </div>
                                    <div className="P-2">
                                        <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><HiOutlineLink /></button>Get a public link </Link>
                                    </div>
                                    <div className="P-2">
                                        <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><MdEdit /></button>choose what to show...</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="calendar">
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                        inline
                                        monthsShown={2}
                                        calendarClassName="calendar"
                                    />
                                    <div className="calendar-footer row ">
                                        {/* <p className='text-light col'> <FontAwesomeIcon className='text-warning' icon={faCalendarDays} />{selectedDate.toDateString()}</p> */}
                                        <p className='text-light col'>Nothing's on the schedule</p>
                                    </div>
                                    <div>
                                    </div>
                                    <button className="addEvent px-2 py-1 text-light" onClick={toggleEventForm}>
                                        Add an event
                                    </button>
                                </div>
                                {showEventForm && (
                                    <div className="event-form text-start">
                                        <h5 className='nameEvent'>Type the name of the event...</h5>
                                        <hr className="line" />
                                        <label className=''>
                                            All day?
                                            <input className='ms-3' type="checkbox" />
                                        </label>
                                        <div className='d-flex align-items-center'>
                                            <p className='me-1'>Starts</p>
                                            <DatePicker
                                                className='DateBorder'
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                showTimeSelect
                                                dateFormat="Pp"
                                            />
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <p className='me-1'>Ends</p>
                                            <DatePicker
                                                className='DateBorder'
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                showTimeSelect
                                                dateFormat="Pp"
                                            />
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <p className='me-1'>Repeat</p>
                                            <select>
                                                <option value="none">Don't repeat</option>
                                                <option value="daily">Daily</option>
                                                <option value="weekly">Weekly</option>
                                                <option value="monthly">Monthly</option>
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <p className='me-1'>With</p>
                                            <input className='inputBorder' type="text" placeholder="Type names here..." disabled />
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <p className='me-1'>Notes</p>
                                            <input className='inputBorder' type="text" placeholder="Add extra details or attach a file..." />
                                        </div>
                                        <div className='text-start'>
                                            <h5 className='text-start'>When I post this, notify...</h5>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="39people"
                                                    checked={notificationOption === '39people'}
                                                    onChange={handleNotificationChange}
                                                />
                                                Just the 39 people working on this Company HQ
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="40people"
                                                    checked={notificationOption === '40people'}
                                                    onChange={handleNotificationChange}
                                                />
                                                All 40 people who can see this Company HQ
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="selectedpeople"
                                                    checked={notificationOption === 'selectedpeople'}
                                                    onChange={handleNotificationChange}
                                                />
                                                Only the people I select...
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="noone"
                                                    checked={notificationOption === 'noone'}
                                                    onChange={handleNotificationChange}
                                                />
                                                No one
                                            </label>
                                        </div>
                                        <button type="button">Post this event</button>
                                        <button type="button" onClick={toggleEventForm}>Cancel</button>
                                    </div>
                                )}
                                <hr className="line" />
                                <a href="schedule">Show everything up to {format(selectedDate, 'MMM dd')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectSchedule;
