import React, { useState } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faClock, faCircleUser, faGaugeHigh, faCircleCheck, faBell, faCalendar, faStopwatch } from '@fortawesome/free-solid-svg-icons';

import Someones_Activity from './Someones_Activity';
import Latest_activity from './Latest_activity';
import Someones_Assignment from './Someones_Assignment';
import Mission_control from './Mission_control';
import To_dos from './To_dos';
import Overdue from './Overdue';
import UnassignedWork from './UnassignedWork';
import UpcomingDates from './UpcomingDates';
import TimeSheet from './TimeSheet';

const Activity = () => {
    const [activeComponent, setActiveComponent] = useState('latest-activity');

    // Define options for the dropdown with labels and values
    const options = [
        { value: 'latest-activity', label: <><FontAwesomeIcon className='text-warning' icon={faClock} /> All the latest activity</> },
        { value: 'someones-activity', label: <><FontAwesomeIcon className='text-danger' icon={faBriefcase} /> Someone’s activity</> },
        { value: 'someones-assignment', label: <><FontAwesomeIcon className='text-info' icon={faCircleUser} /> Someone’s assignments</> },
        { value: 'mission-control', label: <><FontAwesomeIcon className='text-info' icon={faGaugeHigh} /> Mission Control</> },
        { value: 'to-dos', label: <><FontAwesomeIcon className='text-success' icon={faCircleCheck} /> To-dos added & completed</> },
        { value: 'overdue', label: <><FontAwesomeIcon className='text-primary' icon={faBriefcase} /> Overdue to-dos</> },
        { value: 'unassignedwork', label: <><FontAwesomeIcon className='text-warning' icon={faBell} /> Unassigned work</> },
        { value: 'upcomingdates', label: <><FontAwesomeIcon className='text-danger' icon={faCalendar} /> Upcoming dates</> },
        { value: 'timesheet', label: <><FontAwesomeIcon className='text-success' icon={faStopwatch} /> Timesheets</> },
    ];

    const handleSelectChange = (selectedOption) => {
        setActiveComponent(selectedOption.value);
    };

    return (
        <div className='Activity'>
            {/* Dropdown using react-select with icons in options */}
            <div className='header-1 m-0 row'>
                <div className="col-12 d-flex justify-content-center align-item-center activity-head">
                    <Select
                        options={options}
                        onChange={handleSelectChange}
                        placeholder="Choose a report"
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                width: 'auto', // Avoid setting width in css-b62m3t-container
                            }),
                            control: (provided) => ({
                                ...provided,
                                minWidth: '200px', // Set a minimum width for the control
                            }),
                        }}
                    />
                </div>
            </div>

            {/* Render the active component */}
            {activeComponent === 'latest-activity' && <Latest_activity />}
            {activeComponent === 'someones-activity' && <Someones_Activity />}
            {activeComponent === 'someones-assignment' && <Someones_Assignment />}
            {activeComponent === 'mission-control' && <Mission_control />}
            {activeComponent === 'to-dos' && <To_dos />}
            {activeComponent === 'overdue' && <Overdue />}
            {activeComponent === 'unassignedwork' && <UnassignedWork />}
            {activeComponent === 'upcomingdates' && <UpcomingDates />}
            {activeComponent === 'timesheet' && <TimeSheet />}
        </div>
    );
}

export default Activity;
