import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket,faCheck} from '@fortawesome/free-solid-svg-icons';

const Boosts = () => {
  return (
    <div className='Boosts container w-75 p-3'>
                
    <div className="row align-items-center p-2">
        <div className="col">
                    
        </div>
        <div className="col text-center mt-3">
            <button className="rounded-circle d-inline-block text-center text-white h3 profilebtn" style={{ width: '60px', height: '60px', lineHeight: '40px' }}>PJ</button>
        </div>
        <div className="col text-end">
                       
            <button className="emailbtn">
                {/* <span><FontAwesomeIcon icon={faCheck} /></span> */}
                Notifying you of new boosts every 3 hours
            </button>
        </div>
    </div>
   <div className='d-flex justify-content-center h3 fw-bold'>
         <p>Your <span> <FontAwesomeIcon icon={faRocket} /> </span> Boosts</p>
    </div>

    <hr />

   <div className="tab-content d-block mx-auto w-50 p-3">
        <div className='content d-flex justify-content-center align-item-center'>
                <FontAwesomeIcon className='me-3 mt-2 Bmicon' icon={faRocket} />
                <p> <strong>No boosts just yet </strong><br />
                     When you post something to TeamNex, others can<br /> 
                     send boosts (little notes of encouragement) to show <br />
                     they enjoyed your post.
                </p>
        </div>

</div>
</div>
  )
}

export default Boosts