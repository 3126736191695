import ProfileCircles from './ProfileCircles';
import { TiMessages } from "react-icons/ti";
import { FaCircleQuestion } from "react-icons/fa6";

const AutocheckinShow = ({ data }) => {
    const acc = [
        {
            username: "Arun Pandiyan",
            profilePic: null
        },
        {
            username: "Pragatheeshwaran",
            profilePic: null
        },
        {
            username: "Arun Kmumar",
            profilePic: null
        }
    ];

    return (
        <div className="cardbox">
            <h2 className="card-title  fw-bold fs-5 text-center py-2 t-color1">Automatic Check-ins</h2>
            <div className="card-body px-3 pt-2 overflow-hidden project-in-card">
                <div className="card-item">
                    <div className="d-flex justify-content-center pt-3">
                        <div className="email-icon">
                            <FaCircleQuestion style={{ fontSize: 50 }} />
                        </div>
                    </div>
                    <p className="fw-normal fs-6 text-secondary text-center">

                        Send questions to your team on a schedule to get fresh insights.</p>
                </div>
            </div>
        </div>
    )
}
export default AutocheckinShow;