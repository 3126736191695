import React from 'react';
import { LuKey } from "react-icons/lu";
import { Link, useLocation } from 'react-router-dom';

const Account_Rename = ({ userData }) => {
    const location = useLocation();
    const currentUserData = location.state?.userData.username || userData;
    return (
        <div className='EditProject todo-contain'>
            <div className=' text-center d-block m-auto card--head secondary-class' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <Link to='/adminland' className='m-2 fw-bold'><LuKey /> Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="body-bottom text-center secondary-class b-shad" style={{ width: '75vw' }}>
                    <div className="container p-3">
                        <div className="">
                            <p className='h2 fw-bold pt-3'>Rename this account</p>
                        </div>
                        <hr />
                        <div className='body-content w-75 mx-auto'>
                            <div className=' text-center'>
                                <p className='text-start'>Your account name appears on invoices and exports. People who belong to more than one Teamnex account will see your account name listed in their Teamnex menu.</p>
                                <input type="text"
                                    value="Teamnex"
                                    className='form-control '
                                />

                                <div class="content-between-lines">
                                    <hr class="linee" />
                                    <span class="textt">This can’t be deleted because it’s your account’s primary company</span>
                                    <hr class="linee" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Account_Rename