// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import { useLocation, useParams } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

// const InviteNewuser = () => {
//     const { token } = useParams(); // Get token from URL params
//     const location = useLocation();
//     const navigate = useNavigate(); // Use useNavigate hook for navigation

//     const [formData, setFormData] = useState({
//         fullName: '',
//         email: '',
//         profilePhoto: '',
//         password: '',
//         confirmPassword: '',
//         organizationId: '',
//         role: '',
//     });
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [selectedFile, setSelectedFile] = useState(null);

//     // Fetch invitation data when component mounts
//     useEffect(() => {
//         const fetchInviteData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/new-member/${token}`);
//                 if (response.data) {
//                     const { fullName, email, organizationId, role } = response.data;
//                     setFormData({
//                         fullName,
//                         email,
//                         password: '',
//                         confirmPassword: '',
//                         organizationId,
//                         role,
//                     });
//                 } else {
//                     setFormData({
//                         fullName: '',
//                         email: '',
//                         password: '',
//                         confirmPassword: '',
//                         organizationId: '', // Empty if no invite
//                         role: 'Member', // Default role if no invite
//                     });
//                 }
//             } catch (error) {
//                 console.error('Error fetching invite data:', error);
//                 setError('Error fetching invite data.');
//             }
//         };

//         fetchInviteData();
//     }, [token]);

//     // Handle form field changes
//     const handleChange = (e) => {
//         const { id, value, files } = e.target;
//         if (files) {
//             setSelectedFile(files[0]); // Set selected file for profile photo
//         } else {
//             setFormData({ ...formData, [id]: value }); // Update formData state
//         }
//     };

//     // Handle form submission
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);
//         setError(null);

//         // Prepare the form data for submission
//         const data = new FormData();
//         data.append('token', token);  // Add the token here
//         data.append('fullName', formData.fullName);
//         data.append('email', formData.email);
//         data.append('password', formData.password);
//         data.append('confirmPassword', formData.confirmPassword);
//         data.append('organizationId', formData.organizationId);
//         data.append('role', formData.role);

//         console.log(formData.confirmPassword)
//         console.log("Token value is ", token)
//         if (selectedFile) {
//             data.append('profilePhoto', selectedFile); // Add profile photo if selected
//         }
//         if (!formData.password || !formData.confirmPassword) {
//             setError('Password and confirm password are required.');
//             setLoading(false);
//             return;
//         }
//         if (formData.password.length < 8) {  // Example: requiring minimum 8 characters
//             setError('Password must be at least 8 characters.');
//             setLoading(false);
//             return;
//         }
//         console.log(data)
//         try {
//             // Send the request to register the user (assuming you have a registration API)
//             const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/accept-invite/new-user`, data);
//             console.log('User registered:', response.data);
//             navigate('/success'); // Redirect to success page after registration
//         } catch (error) {
//             console.error('Error during registration:', error);
//             setError('Error during registration.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Extract initials for the placeholder if profilePhoto is not available
//     const getInitials = (name) => {
//         if (!name) return '?'; // Default initials if no name is provided
//         const splitName = name.trim().split(' ');
//         const initials = splitName.map(part => part[0].toUpperCase()).join('');
//         return initials;
//     };

//     return (
//         <div className="container profile-form-container mt-2 d-flex justify-content-center align-items-center">
//             <div style={{ width: '30rem' }}>
//                 <div className="card-body profileForm p-3">
//                     <form onSubmit={handleSubmit}>
//                         <div className="profileformGroup">
//                             <div className="text-center mb-4">
//                                 <div
//                                     className="rounded-circle bg-success text-white d-inline-block"
//                                     style={{ width: '80px', height: '80px', lineHeight: '80px', fontSize: '36px' }}
//                                 >
//                                     {formData.profilePhoto ? (
//                                         <img
//                                             src={formData.profilePhoto}
//                                             alt="Profile"
//                                             style={{ width: '80px', height: '80px', borderRadius: '50%' }}
//                                         />
//                                     ) : (
//                                         getInitials(formData.fullName)
//                                     )}
//                                 </div>
//                                 <div className="mt-2">
//                                     <input
//                                         type="file"
//                                         name="profilePhoto"
//                                         className="photo-button text-light"
//                                         id="profilePhoto"
//                                         style={{ display: 'none' }}
//                                         onChange={handleChange} // Handle change for file input
//                                     />
//                                     <label htmlFor="profilePhoto" className="text-light photobutton">
//                                         Upload a photo...
//                                     </label>
//                                 </div>
//                                 <div className="mt-3 photobutton2 d-block m-auto w-50 fs-6 textRequired">
//                                     Upload a photo to show your 😊
//                                 </div>
//                             </div>

//                             {/* Form Fields */}
//                             <div className="form-group">
//                                 <label htmlFor="fullName" className="h5 fw-bold text-light">
//                                     Name <span className="textRequired h6">required</span>
//                                 </label>
//                                 <input
//                                     type="text"
//                                     className="form-control personalForm"
//                                     placeholder="Enter name"
//                                     id="fullName"
//                                     value={formData.fullName} // Set value from state
//                                     onChange={handleChange} // Handle change for the input
//                                     required
//                                 />
//                             </div>

//                             <div className="form-group">
//                                 <label htmlFor="email" className="h5 text-light fw-bold mt-3">
//                                     Email address <span className="textRequired h6">required</span>
//                                 </label>
//                                 <input
//                                     type="email"
//                                     className="form-control personalForm mb-2"
//                                     placeholder="Enter email"
//                                     id="email"
//                                     value={formData.email} // Set value from state
//                                     onChange={handleChange} // Handle change for the input
//                                     required
//                                     readOnly
//                                 />
//                             </div>

//                             <div className="form-group text-start">
//                                 <label htmlFor="newPassword" className="h5 text-light fw-bold mt-3">
//                                     Enter a new password
//                                 </label>
//                                 <input
//                                     type="password"
//                                     id="password"
//                                     className="form-control personalForm mb-2"
//                                     value={formData.password} // Bind to state
//                                     onChange={handleChange} // Handle change for the input
//                                     required
//                                 />
//                             </div>

//                             <div className="form-group text-start">
//                                 <label htmlFor="confirmPassword" className="h5 text-light fw-bold mt-3">
//                                     Confirm your password
//                                 </label>
//                                 <input
//                                     type="password"
//                                     id="confirmPassword"
//                                     className="form-control personalForm mb-2"
//                                     value={formData.confirmPassword} // Bind to state
//                                     onChange={handleChange} // Handle change for the input
//                                     required
//                                 />
//                             </div>

//                             {/* Save Button */}
//                             <button
//                                 type="submit"
//                                 className="w-100 d-block m-auto p-2 mt-3 action-btn"
//                                 disabled={loading}
//                             >
//                                 {loading ? 'Please Wait...' : "OK, let's go"}
//                             </button>
//                         </div>
//                     </form>

//                     {/* Error Message */}
//                     {error && <div className="alert alert-danger mt-3">{error}</div>}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default InviteNewuser;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const InviteNewuser = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        password: '',
        confirmPassword: '',
        organizationId: '',
        role: '',
        profilePhoto: null,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchInviteData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/new-member/${token}`);
                const { fullName, email, organizationId, role } = response.data;
                setFormData({ ...formData, fullName, email, organizationId, role });
            } catch (error) {
                console.error('Error fetching invite data:', error);
                setError('Error fetching invitation data.');
            }
        };

        fetchInviteData();
    }, [token]);

    const handleChange = (e) => {
        const { id, value, files } = e.target;

        if (id === 'profilePhoto' && files.length > 0) {
            const file = files[0];
            setFormData({
                ...formData,
                profilePhoto: URL.createObjectURL(file), // Preview URL for the image
                profilePhotoFile: file // Store the actual file for upload
            });
        } else {
            setFormData({ ...formData, [id]: value });
        }
    };
    const getInitials = (name) => {
        const nameArray = name.split(' ');
        const initials = nameArray.map(n => n.charAt(0)).join('').toUpperCase();
        return initials;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (!formData.password || !formData.confirmPassword) {
            setError('Password and confirm password are required.');
            setLoading(false);
            return;
        }
        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }
        if (formData.password.length < 8) {
            setError('Password must be at least 8 characters.');
            setLoading(false);
            return;
        }

        // Prepare FormData for sending both text fields and the file
        const formDataToSend = new FormData();
        formDataToSend.append('token', token);
        formDataToSend.append('password', formData.password);

        if (formData.profilePhotoFile) {
            formDataToSend.append('profilePhoto', formData.profilePhotoFile); // Append the file to FormData
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/accept-invite/new-user`,
                { token, password: formData.password },
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            toast.success('Signup successful!');
            navigate('/');
        } catch (error) {
            console.error('Error during registration:', error);
            setError('Error during registration.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container profile-form-container mt-2 d-flex justify-content-center align-items-center">
            <div style={{ width: '30rem' }}>
                <div className="card-body profileForm p-3">
                    <form onSubmit={handleSubmit}>
                        <div className="profileformGroup text-center mb-4">
                            <div
                                className="rounded-circle bg-success text-white d-inline-block"
                                style={{ width: '80px', height: '80px', lineHeight: '80px', fontSize: '36px' }}
                            >
                                {formData.profilePhoto ? (
                                    <img
                                        src={formData.profilePhoto}
                                        alt="Profile"
                                        style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                                    />
                                ) : (
                                    getInitials(formData.fullName)
                                )}
                            </div>
                            <div className="mt-2">
                                <input
                                    type="file"
                                    name="profilePhoto"
                                    className="photo-button text-light"
                                    id="profilePhoto"
                                    style={{ display: 'none' }}
                                    onChange={handleChange}
                                />
                                <label htmlFor="profilePhoto" className="text-light photobutton">
                                    Upload a photo...
                                </label>
                            </div>
                            <div className="mt-3 photobutton2 d-block m-auto w-50 fs-6 textRequired">
                                Upload a photo to show your 😊
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="fullName" className="h5 fw-bold text-light">Name <span className="textRequired h6">required</span></label>
                            <input
                                type="text"
                                className="form-control personalForm"
                                id="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email" className="h5 text-light fw-bold mt-3">Email address <span className="textRequired h6">required</span></label>
                            <input
                                type="email"
                                className="form-control personalForm mb-2"
                                id="email"
                                value={formData.email}
                                readOnly
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password" className="h5 text-light fw-bold mt-3">New Password</label>
                            <input
                                type="password"
                                id="password"
                                className="form-control personalForm mb-2"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="confirmPassword" className="h5 text-light fw-bold mt-3">Confirm Password</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                className="form-control personalForm mb-2"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                            />
                        </div>



                        <button type="submit" className="w-100 p-2 mt-3 action-btn" disabled={loading}>
                            {loading ? 'Please Wait...' : "OK, let's go"}
                        </button>
                    </form>

                    {error && <div className="alert alert-danger mt-3">{error}</div>}
                </div>
            </div>
        </div>
    );
};

export default InviteNewuser;
