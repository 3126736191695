import React from 'react'
import { MdAutoDelete, MdCancel, MdDelete, MdEdit, MdGroups2, MdMerge, MdOutlineMessage, MdPeople, MdVpnKey } from "react-icons/md";
import { PiArrowArcRightFill } from "react-icons/pi";
import { IoLockOpen, IoBriefcase, IoFileTrayFullSharp } from "react-icons/io5";
import { RiArchive2Fill, RiKey2Fill } from "react-icons/ri";
import { IoIosChatboxes, IoIosLink, IoMdPeople, IoMdPersonAdd } from "react-icons/io";
import { FaCrown, FaDollarSign, FaEdit } from "react-icons/fa";
import { BsFillBuildingFill } from "react-icons/bs";
import { TbArrowsDoubleNeSw } from "react-icons/tb";
import { Link } from 'react-router-dom';
import asset from '../asset/user.jpg'
const Adminland = () => {
    return (
        <div>
            <div className="container mt-5 adminland-bg campfireBody">
                <div className="row align-items-center">
                    <div className="col">
                    </div>
                    <div className="text-center">
                        <h3 className="mb-0 mt-3 headcamp t-color1 fw-bold"><RiKey2Fill style={{
                            fontSize: "35"
                        }} />Adminland</h3>
                    </div>
                    <div className="">
                        <p className='fs-5 text-center t-color1'>Manage your TeamNex account</p>
                    </div>
                    <hr className="adminlandLine" />
                </div>
                <div className='container pb-5 admin-panel'>
                    <p className='t-color1 fs-4'>What’s this?</p>
                    <p className='t-color1'>Adminland is the place for admins and owners to oversee this TeamNex account.
                        They have special permissions to add/remove people, change billing info, export data, etc.
                        Need something like that done? Ping someone listed below.</p>
                    <p className='t-color1 fs-4'>Administrators</p>
                    <span className=' t-color1 d-flex gap-3'>
                        <img src={asset} alt='' className="profile-pic" />
                        <p>Admin 1</p>
                        <img src={asset} alt='' className="profile-pic" />
                        <p>Admin 2</p>
                        <img src={asset} alt='' className="profile-pic" />
                        <p>Admin 3</p>
                        <img src={asset} alt='' className="profile-pic" />
                        <p>Admin 4</p>
                    </span>
                    <h5 className='t-color1 mt-5'>You’re an admin, so you can…</h5>
                    <Link to="add-roles" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdPeople className='t-color1' /> </button>Add Roles and Permissions
                    </Link>
                    <hr className="textLine ms-5 " />
                    <Link to="/add-remove-acc" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdPeople className='t-color1' /> </button>Add/remove people or change their access
                    </Link>
                    <hr className="textLine ms-5 " />
                    <Link to='/administrators' className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoBriefcase className='t-color1' /> </button>Add/remove administrators
                    </Link><hr className="textLine ms-5" />
                    <Link to="/invite-link" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoMdPersonAdd className='t-color1' /> </button>Invite coworkers with a link
                    </Link><hr className="textLine ms-5" />
                    <Link to="/managegroups" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdGroups2 className='t-color1' /> </button>Manage groups
                    </Link><hr className="textLine ms-5" />
                    <Link to="/managecompanies" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><BsFillBuildingFill className='t-color1' /> </button>Manage companies
                    </Link><hr className="textLine ms-5" />
                    <Link to="/renameprojecttools" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><FaEdit className='t-color1' /> </button>Rename project tools
                    </Link><hr className="textLine ms-5" />
                    <Link to="/messagecategories" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdOutlineMessage className='t-color1' /> </button>Change message categories
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/moveproject" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><PiArrowArcRightFill className='t-color1' /> </button>Move projects from TeamNex 2 to TeamNex 4
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/mergepeople" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdMerge className='t-color1' /> </button>Merge people
                    </Link>
                    <p className='t-color1 fs-4 mt-5'>Account Owners</p>
                    <span className=' t-color1'>
                        <img src="" alt='' className="profile-pic" />
                        Arun
                        <img src="" alt='' className="profile-pic" />
                        Arun
                        <img src="" alt='' className="profile-pic" />
                        Arun
                        <img src="" alt='' className="profile-pic" />
                        Arun
                    </span>
                    <h5 className='t-color1 mt-5'>Admin Pro Pack, included with Pro Unlimited</h5>
                    <Link to="/changepings" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoIosChatboxes className='t-color1' /> </button>Change who can send pings
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/editprojectsettings" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdEdit className='t-color1' /> </button>Change who can edit project settings
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/editproject" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoMdPeople className='t-color1' /> </button>Change who can edit the people on a project
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/archive-and-delete-project" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><RiArchive2Fill className='t-color1' /></button>Change who can archive and delete projects, docs, and more
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/publiclink" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoIosLink className='t-color1' /> </button>Change who can turn on public links
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/two-factor-authentication" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoLockOpen className='t-color1' /></button>Require two-factor authentication
                    </Link>
                    <hr className="textLine ms-5 " />
                    <Link to="/ping-and-chat-history" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdAutoDelete className='t-color1' /> </button>Change Ping & Chat history settings
                    </Link>
                    <hr className="textLine ms-5" />
                    <h5 className='t-color1 mt-5'>You’re an account owner, so you can…</h5>
                    <Link to="/planselection" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><FaDollarSign className='t-color1' /></button>Buy TeamNex today
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/managestorage" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><IoFileTrayFullSharp className='t-color1' /> </button>Manage storage
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/add-remove-acc" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><FaCrown className='t-color1' /></button>Add/remove account owners
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/account-rename" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><MdEdit className='t-color1' /></button>Rename this account (TeamNex)
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/trash" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><MdDelete className='t-color1' /></button>View everything in the trash
                    </Link><hr className="textLine ms-5" />
                    <Link to="/reassignment" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><TbArrowsDoubleNeSw className='t-color1' /></button>Reassign someone’s to-dos
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/projectaccess" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><MdVpnKey className='t-color1' /> </button>Access any project
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/export-data" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><RiArchive2Fill className='t-color1' /> </button>Export data from this account
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/managepublicitems" className='t-color1 mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><IoIosLink className='t-color1' /> </button>Manage public items
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/cancelaccount" className='t-color1 p-0 mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><MdCancel className='t-color1' /></button>Cancel the account
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Adminland;