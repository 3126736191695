import React from 'react';

const CancelAccount = () => {
  return (
    <div className="cancel-account-container p-5">
      <h3 className='text-center'>TeamNex</h3>
      <h3 className='text-center'>Are you sure you want to cancel your account?</h3>
      <p>We’ll be sorry to see you go, but thanks for giving TeamNex a try!</p>
      <div className="cancel-info">
        <h6 className='fw-bold'>When you cancel...</h6>
        <ul className='ms-3'>
          <li>You won’t be billed again.</li>
          <li>Your account will be closed immediately.</li>
          <li>Your data will be permanently deleted from our servers within 30 days and from all backups within 60 days.</li>
        </ul>
      </div>
      <div className="export-data">
        <h6 className='fw-bold'>Remember to export your data</h6>
        <p><a href="#">Export your data</a> before you go. It won’t be possible to export after you cancel your account.</p>
      </div>
      <div className="support-info">
        <h6 className='fw-bold'>Have questions before you go?</h6>
        <p>We’re here to help. <a href="#">Talk with our friendly support team</a>.</p>
      </div>
      <div className="text-center mt-4">
        <button className="accountcancel-button mb-2">Yes, cancel my account</button>
      <button className="never-mind-button">Never mind</button>
      </div>
    </div>
  );
};

export default CancelAccount;
