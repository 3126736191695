import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

const Invite_People = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
  console.log(userData);

  const [selectedRole, setSelectedRole] = useState('Member');

  const handleNext = () => {
    // Navigate to the Coworkers_Account page and pass the selected role and user data
    navigate('coworkers-account', {
      state: {
        userData: userData,
        selectedRole: selectedRole, // Pass the selected role
      },
    });
  };

  return (
    <div className="Invite_People p-3">
      <div className="d-flex justify-content-center align-items-center mt-5">
        <h4 className="fw-bold ">Who are you inviting?</h4>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <h5 className="">
          First you’ll invite them to the account. Then you can add them to projects.
        </h5>
      </div>
      <div className="d-flex justify-content-center">
        <div className="mid-box mt-2 w-50 p-5 cre-pro-rad">
          <Form>
            <Form.Check
              type="radio"
              id="Member"
              name="role"
              label={
                <>
                  <strong>Someone who works at {userData ? userData.user.companyname : "Organization Name"}</strong>
                  <p className="description">
                    People who work at {userData ? userData.user.companyname : "Organization Name"} are <strong>the only people who can</strong> create projects, add others to projects, and act as administrators. Add them this way if they are full-time, part-time, or a volunteer at {userData?.user.companyname || "Organization Name"}.
                  </p>
                </>
              }
              checked={selectedRole === 'Member'}
              onChange={() => setSelectedRole('Member')}
            />
            <Form.Check
              type="radio"
              id="Collaborator"
              name="role"
              label={
                <>
                  <strong>An outside collaborator, partner, contractor, guest, etc.</strong>
                  <p className="description">
                    This person doesn’t work at {userData ? userData.user.companyname : "Organization Name"}. They can collaborate on projects with you, but <strong>they won’t</strong> be able to create projects, invite people to the account, add people to projects, or be admins.
                  </p>
                </>
              }
              checked={selectedRole === 'Collaborator'}
              onChange={() => setSelectedRole('Collaborator')}
            />
            <Form.Check
              type="radio"
              id="Client"
              name="role"
              label={
                <>
                  <strong>A client you’re doing work for</strong>
                  <p className="description">
                    Clients can access projects you’ve created, but <strong>they can’t create their own, invite or add new people, or become admins.</strong> You can hide parts of projects from them so they can’t see work in progress.
                  </p>
                </>
              }
              checked={selectedRole === 'Client'}
              onChange={() => setSelectedRole('Client')}
            />
          </Form>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button className="mt-3 next-btn" onClick={handleNext}>
          Next, enter their name...
        </button>
      </div>
    </div>
  );
};

export default Invite_People;
