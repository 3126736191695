
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FaLongArrowAltLeft } from "react-icons/fa";
import Select from 'react-select';
import axios from 'axios';
import toast from 'react-hot-toast';

const AddPeopleToProject = () => {
    const { organizationId, id: projectId } = useParams();

    const navigate = useNavigate()
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isEditorActive, setIsEditorActive] = useState(false);
    const [availableUsers, setAvailableUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([]);
    useEffect(() => {
        const fetchAvailableUser = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/project/${projectId}`)
                console.log("response", response.data.users)
                const options = response.data.users.map(user => ({
                    value: user.user,
                    label: user.username
                }));

                setAvailableUsers(options);
            } catch (error) {
                console.error('Error fetching available members:', error);
                console.log(error.message)
            }
        }
        fetchAvailableUser()
    }, [organizationId, projectId])

    const handleSelectChange = (selectedOptions) => {
        setSelectedUsers(selectedOptions)
    }
    const handleFocus = () => {
        setIsEditorActive(true);
    };
    const handleBack = () => {
        navigate(-1)
    }
    const formatOptionLabel = ({ label, profilePhoto }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {profilePhoto ? (
                <img
                    src={profilePhoto}
                    alt="Profile"
                    style={{ width: 24, height: 24, borderRadius: '50%', marginRight: 10 }}
                />
            ) : (
                <span
                    style={{
                        width: 24,
                        height: 24,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        backgroundColor: '#4caf50',
                        color: '#fff',
                        marginRight: 10,
                        fontSize: '12px',
                    }}
                >
                    {getInitials(label)}
                </span>
            )}
            {label}
        </div>
    );


    function getInitials(name = "") {
        if (!name) return "?";
        const initials = name
            .split(" ")
            .map(part => part.charAt(0).toUpperCase())
            .join("");
        return initials;
    }

    const handleAddUserToProject = async () => {
        const userIds = selectedUsers.map(user => user.value)
        console.log(userIds)
        console.log("Selected Users:", selectedUsers);
        console.log("User IDs:", userIds);

        if (userIds.length === 0) {
            alert('Please select at least one user');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/project/${projectId}/add-member`, { userIds })
            navigate(-1);
            toast.success("Member added successfully")
        } catch (error) {
            console.error('Error adding users to project:', error);
            alert('Failed to add users to the project');
        }
    }
    console.log("Selected Users:", selectedUsers);

    console.log("Available user", availableUsers)
    return (
        <div className='addPeopleContainer todo-contain'>
            <div className='addPeopleSubContainer todo-contain'>
                <Link onClick={handleBack} className="fs-6"><FaLongArrowAltLeft className='text-light fs-6' /> Back to People on Webnexs</Link>
                <div className='my-3'>
                    <h2 className='fw-bold t-color1'>Add people to this project</h2>
                </div>
                <div className='p-4 add-bg rounded-2'>
                    <div className="mb-3">
                        <label htmlFor="addPeopleInput" className="fw-bold">
                            Who do you want to add to this project?
                        </label>
                        <div className="mt-2 cre-select-user">
                            <Select
                                closeMenuOnSelect={false}
                                className="proinput w-100"
                                placeholder="Type their names here..."
                                isMulti
                                options={availableUsers}
                                getOptionValue={(option) => option.value}
                                onChange={handleSelectChange}
                                formatOptionLabel={formatOptionLabel}
                            />

                        </div>
                        <Link to="" className="text-info fs-6">
                            Or pick people from a company...
                        </Link>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="invitationNote" className="fw-bold " >
                            Add an optional note to the invitation email
                        </label>
                        <div className='addtextAreaEditor'>
                            {isEditorActive ? (
                                <Editor
                                    className="textAreaEditor"
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    toolbar={{
                                        options: ['inline', 'list', 'link', 'history'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                                        },
                                        list: { options: ['unordered', 'ordered'] },
                                        link: { options: ['link'] },
                                    }}
                                    editorStyle={{ color: 'white', backgroundColor: '#transparent', border: '1ps splid white' }}
                                    toolbarStyle={{ backgroundColor: 'transparent', borderbottom: '1px solid white' }}
                                />

                            ) : (
                                <input
                                    type="text"
                                    className="form-control "
                                    id='textAreaEditor'
                                    placeholder="Add an optional note here..."
                                    style={{ color: 'white', border: '1px solid rgb(56, 62, 68)' }}
                                    onFocus={handleFocus}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-3 text-muted" style={{ width: "47vw" }}>
                    <p className='text-center w-60 t-color1'>
                        <span className='fw-bold'> What happens next?</span> Everyone above will be immediately added to the project and receive an email with instructions to join. They'll be able to see everything posted so far.
                    </p>
                </div>
                <div className="text-center">
                    <button className="h5 border rounded-pill border-dark px-3 py-3 addProjectPeopleBtn" onClick={handleAddUserToProject}>
                        Add them to the project
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddPeopleToProject;
