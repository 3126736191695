import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function InviteSuccess() {
    const navigate = useNavigate()
    const location = useLocation();
    const inviteResponses = location.state?.inviteResponses || [];
    console.log(
        inviteResponses
    )
    const organizationId = inviteResponses[0]?.invitation?.organizationId
    console.log(organizationId)
    const handleNotNow = () => {
        navigate(`/${organizationId}`, { replace: true })
    }
    return (
        <div className="container text-white d-flex justify-content-center align-items-center min-vh-100">
            <div className="d-flex flex-column justify-content-center align-items-center text-center p-4 rounded invite-success w-100" style={{ maxWidth: "500px" }}>
                <div className="w-25 h-75 mb-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-100">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                    </svg>
                </div>
                <div>
                    <h2 className="fw-bold mb-3">Invitation emailed to <br></br> {inviteResponses.map((invite, index) => (
                        <span>{invite.invitation.fullName} {index < inviteResponses.length - 1 && ', '} </span>
                    ))}
                    </h2>
                </div>
                <div>
                    <p className="mb-4">They’ll get an email with instructions on how to sign in and join your Teamnex account. Add them to existing projects now or do that later.</p>
                </div>
                <div className="d-flex flex-column gap-2 w-100">
                    <button className="next-btn text-black text-thin">Set up which projects they can see</button>
                    <button className="btn  camp-cancel secondary-class" onClick={() => handleNotNow()}>Not now, I’ll do this later</button>
                </div>
            </div>
        </div>
    );
}

export default InviteSuccess;
