import React, { useState } from 'react'
import { LuKey } from "react-icons/lu";
import { Link } from 'react-router-dom';

const Reassignment = () => {

    // const name = 'NewName';
    // const firstLetter = name.charAt(0).toUpperCase();

    const [selectedEmp, setSelectedEmp] = useState('');

    const People = [
        { value: '1', label: "Type a person's name..." },
        { value: 'Arun', label: 'ArunKumar' },
        { value: 'Ganesh', label: 'Ganesh' },
        { value: 'Pragatheeshwaran', label: 'Pragatheeshwaran' },

    ];
    const handleChange = (event) => {
        setSelectedEmp(event.target.value);
    };

    return (
        <div className='Reassignment todo-contain'>
            <div className=' text-center d-block m-auto card--head secondary-class' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <Link to='/adminland' className='m-2 fw-bold'><LuKey /> Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="body-bottom text-center secondary-class b-shad" style={{ width: '75vw' }}>
                    <div className="container p-3">
                        <div className="">
                            <p className='h2 fw-bold pt-3'>Reassign someone’s to-dos</p>
                        </div>
                        <hr />
                        <div className='body-content w-75 mx-auto'>
                            <div className=' text-center'>
                                <p className='text-start'>Reassign all of someone’s to-dos to another person, remove all of their assignments, or add additional assignees to all of someone’s to-dos. Use this when a coworker leaves or joins.</p>
                                <div className='p-4 box-content '>
                                    <p className='fw-bold text-start mb-2'>Step 1: Choose which to-dos to reassign</p>
                                    <p className='text-start mb-0'>Whose to-dos do you want to reassign?</p>
                                    <select
                                        className='px-2 proinput mb-2  w-100 mt-0'
                                        id="name-selector"
                                        value={selectedEmp}
                                        onChange={handleChange}
                                    >
                                        {People.map((name) => (
                                            <option key={name.value} value={name.value}>
                                                {name.label}
                                            </option>
                                        ))}
                                    </select>
                                    <p className='text-start mb-0'>Only reassign to-dos in one project…</p>
                                    <select
                                        className='px-2 proinput mb-2 w-100 mt-0'
                                        id="name-selector"
                                        value={selectedEmp}
                                        onChange={handleChange}
                                    >
                                        {People.map((name) => (
                                            <option key={name.value} value={name.value}>
                                                {name.label}
                                            </option>
                                        ))}
                                    </select>
                                    <p className='text-secondary text-start'>Optional. Leave this blank to reassign to-dos in all their projects.</p>
                                    <div className='d-flex text-start'>
                                        <button className='p-2 mt-3 mx-1 bttn-1'>Next step..</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reassignment