import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaRegClock } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";

const NewMessage = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [messages, setMessages] = useState([]);
  const [title, setTitle] = useState('');
  const [error, setError] = useState("");
  const [content, setContent] = useState('');
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const textareaRef = useRef(null);
  const navigate = useNavigate();
  const socketRef = useRef(null); // Reference to Socket.IO connection
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [isEditorActive, setIsEditorActive] = useState(false);
  const [notificationOption, setNotificationOption] = useState("team");
  const location = useLocation();
  const projectData = location.state?.projectData;
  // console.log('Project Data from Location State:', projectData); // Verify data from location state

  const { organizationId, id: projectId } = useParams();
  console.log('Project ID from useParams:', projectData); // Log projectId from route params
  console.log('Organization ID from useParams:', organizationId); // Log projectId from route params

  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
  const sender = userData ? userData?.user?._id : null;
  // console.log('Sender ID:', sender); // Verify sender ID

  const handleOptionChange = (event) => {
    setNotificationOption(event.target.value);
  };


  const formats = [
    ['bold', 'italic', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    [{ 'color': [] }, { 'background': [] }],
    ['blockquote', 'code-block'],
    ['clean'],
    // [{ 'header': [1, 2, 3, false] }], // Add heading options
  ];

  const [categories, setCategories] = useState([
    { name: 'None', name: 'Pick a category (optional)' },
    { id: 1, name: '📢 Announcement' },
    { id: 2, name: '✨ FYI' },
    { id: 3, name: '❤️ Heartbeat' },
    { id: 4, name: '💡 Pitch' },
    { id: 5, name: '🔥 Question' },
    { id: 6, name: '👍 vanakkam' }

  ]);

  const [newCategoryName, setNewCategoryName] = useState('');
  const [selectedIcon, setSelectedIcon] = useState('❓'); // Default icon for new category
  const [isEditing, setIsEditing] = useState(null);
  const [isAdding, setIsAdding] = useState(false); // Track adding mode

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!title.trim()) {
      toast.error("Enter a title")
      setError("Enter a title");
      return;
    }
    setError("");
    const messageData = {
      title,       // Title from input field
      content,     // Content from input field
      sender,      // Actual authorId
      category: selectedCategory, // Category from input field
      projectId,
      organizationId: organizationId
    };

    console.log('Message Data to Submit:', messageData); // Log messageData before sending

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/messageboard`, messageData);
      // if (!response.ok) {
      //   const errorText = await response.text();
      //   throw new Error(`Failed to post message: ${errorText}`);
      // }

      // const newMessageid = await response.json();
      toast.success('Message Created successfully!');

      // Clear input fields after success
      setTitle('');
      setContent('');
      setSelectedCategory('');

      // Emit a socket event to notify other users of the new message
      // socketRef.current.emit('newMessage', newMessageid);

      // Navigate to the newly created message
      navigate(-1);
    } catch (error) {
      console.error('Error posting message:', error.message);
      toast.error(`Error posting message: ${error.message}`);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };




  const saveState = () => {
    setHistory((prevHistory) => [...prevHistory, content]);
    setRedoStack([]); // Clear redoStack when a new action is performed
  };

  const peopleWorking = [
    { id: 1, initials: "KA", color: "rgb(162, 215, 109)" },
    { id: 2, initials: "M", color: "rgb(162, 215, 109)" },
    { id: 3, initials: "PK", color: "rgb(162, 215, 109)" },
  ];

  const [selectedPeople, setSelectedPeople] = useState({
    Manivel: true,
    PrakashKumar: true,
    KaviArasan: true
  });
  const handleBack = () => {
    navigate(-1)
  }
  const handleUndo = () => {
    setHistory((prevHistory) => {
      if (prevHistory.length > 0) {
        const previousContent = prevHistory[prevHistory.length - 1];
        setRedoStack((prevRedoStack) => [content, ...prevRedoStack]);
        setContent(previousContent);
        return prevHistory.slice(0, -1);
      }
      return prevHistory;
    });
  };

  const handleRedo = () => {
    setRedoStack((prevRedoStack) => {
      if (prevRedoStack.length > 0) {
        const nextContent = prevRedoStack[0];
        saveState();
        setContent(nextContent);
        return prevRedoStack.slice(1);
      }
      return prevRedoStack;
    });
  };



  const handleAttach = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        // console.log('File selected:', file);
      }
    });
    input.click();
  };
  // Predefined icons (emoji)
  const availableIcons = ['📢', '✨', '❤️', '💡', '🔥', '👍', '🎉', '🔔', '💬', '❓'];

  // Add Category with Emoji
  const addCategory = () => {
    if (newCategoryName.trim() && selectedIcon) {
      const newCategory = { id: Date.now(), name: newCategoryName, icon: selectedIcon };
      setCategories([...categories, newCategory]);
      setNewCategoryName(''); // Reset input
      setSelectedIcon('❓'); // Reset icon
      setIsAdding(false); // Exit add mode
    }
  };

  // Edit Category Function
  const editCategory = (id, updatedName) => {
    setCategories(categories.map(cat =>
      cat.id === id ? { ...cat, name: updatedName } : cat
    ));
    setIsEditing(null);
  };

  // Delete Category Function
  const deleteCategory = (id) => {
    setCategories(categories.filter(cat => cat.id !== id));
  };
  // const wrapSelectedText = (tagOpen, tagClose = tagOpen) => {
  const handleCheckboxChange = (name) => {
    setSelectedPeople({
      ...selectedPeople,
      [name]: !selectedPeople[name]
    });
  };
  const selectEveryone = () => {
    setSelectedPeople({
      Manivel: true,
      PrakashKumar: true,
      KaviArasan: true
    });
  };

  // Function to deselect all people
  const selectNoOne = () => {
    setSelectedPeople({
      Manivel: false,
      PrakashKumar: false,
      KaviArasan: false
    });
  };
  return (
    <div className="campfire todo-contain">
      <div className="text-center d-block m-auto campfire card--head camp" style={{ width: '65vw', height: '6vh' }}>
        <div className="d-flex justify-content-center align-items-center mt-2 h-100">
          <button className="icon--button me-2">
            <FontAwesomeIcon className="me-1" icon={faTh} />
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <Link onClick={handleBack} className="fw-bold">{projectData?.name}</Link>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center camp">
        <div className="text-center" style={{ width: '75vw' }}>
          <div className="campfireBody">
            <div className="container p-5">
              <div className="text-start mb-3">
                <select className="allcategory t-color1" id="category-selector" value={selectedCategory} onChange={handleCategoryChange}>
                  {categories.map((category) => (
                    <option key={category.id} value={category.name}> {category.name}</option>
                  ))}
                </select>
              </div>
              <input
                className={`categoryText pt-1 ${error ? "input-error" : ""}`}
                style={{
                  border: error ? "1px solid red" : "1px solid #ccc", // Apply red border if error
                  padding: "8px",
                  borderRadius: "4px",
                  width: "100%",
                }}
                placeholder="Type a title..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}

              />
              {/* {error && <p className="error-message text-danger">{error}</p>} */}
              <hr></hr>
              {/* <div className="toolbar text-start">
                <hr className="line m-0" />
                <button className="toolbarBtn" onClick={handleBold}><b><FaBold /></b></button>
                <button className="toolbarBtn" onClick={handleItalic}><i><FaItalic /></i></button>
                <button className="toolbarBtn" onClick={handleStrikethrough}><FaStrikethrough /></button>
                <button className="toolbarBtn" onClick={handleLink}><FaLink /></button>
                <button className="toolbarBtn" onClick={handleColorFill}><IoIosColorFill /></button>
                <button className="toolbarBtn" onClick={handleHeading}><FaHeading /></button>
                <button className="toolbarBtn" onClick={handleBlockquote}><BiSolidQuoteAltRight /></button>
                <button className="toolbarBtn" onClick={handleAlignCenter}><AiOutlineAlignCenter /></button>
                <button className="toolbarBtn" onClick={handleCode}><IoMdCode /></button>
                <button className="toolbarBtn" onClick={handleBulletedList}><MdFormatListBulleted /></button>
                <button className="toolbarBtn" onClick={handleNumberedList}><MdFormatListNumbered /></button>
                <button className="toolbarBtn" onClick={handleAttach}><IoIosAttach /></button>
                <span className="text-end">
                  <button className="toolbarBtn" onClick={handleUndo}><LuUndo /></button>
                  <button className="toolbarBtn" onClick={handleRedo}><LuRedo /></button>
                </span>
                <hr className="line m-0" />
              </div> */}
              <div className='message-board-editor'>
                <ReactQuill
                  modules={{ toolbar: formats }}
                  // formats={formats}
                  style={{ flexGrow: 1, minHeight: '200px' }}
                  placeholder='Write away...'
                  value={content}
                  onChange={setContent}
                />

              </div>
              {/* <textarea
                className="awayText pt-1"
                placeholder="Write away..."
                value={content}
                onChange={(e) => setContent(e.target.value)}
                ref={textareaRef}
              /> */}
            </div>
            {/* <div className="notification-settings p-5">
              <h5 className="text-start">When I post this, notify...</h5>
              <form onSubmit={handleSubmit}>
                <div className="radio-group">
                  <label><input type="radio" name="notify" defaultChecked /> Just the people working on this Company HQ</label>
                  <div className="user-avatars"></div>
                </div>
                <div className="radio-group">
                  <label><input type="radio" name="notify" /> All people who can see this Company HQ</label>
                </div>
                <div className="radio-group">
                  <label><input type="radio" name="notify" /> Only the people I select...</label>
                </div>
                <div className="radio-group">
                  <label><input type="radio" name="notify" /> No one</label>
                </div>
                <div className="buttons">
                  <button type="button" className="save-draft me-3">Save as a draft</button>
                  <button type="submit" className="post-message">Post this message</button>
                </div>
              </form>
            </div> */}
            <div className='notification-settings p-5'>
              <h3 className='text-start'>When I post this, notify...</h3>
              <form onSubmit={handleSubmit}>
                <div className="radio-group">
                  {/* <label>
                    <input
                      type="radio"
                      value="team"
                      checked={notificationOption === "team"}
                      onChange={handleOptionChange}
                    />
                    Just the 3 people working on this project
                  </label> */}

                  {/* Show people working if 'team' option is selected */}
                  {notificationOption === "team" && (
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      {peopleWorking.map((person) => (
                        <div
                          key={person.id}
                          style={{
                            width: "30px",
                            height: "30px",
                            backgroundColor: person.color,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "10px",
                          }}
                        >
                          {person.initials}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      value="all"
                      checked={notificationOption === "team"}
                      onChange={handleOptionChange}
                    />
                    All 3 people who can see this project
                  </label>
                  {/* Show people working if 'team' option is selected */}
                  {notificationOption === "all" && (
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      {peopleWorking.map((person) => (
                        <div
                          key={person.id}
                          style={{
                            width: "30px",
                            height: "30px",
                            backgroundColor: person.color,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "10px",
                          }}
                        >
                          {person.initials}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      value="selected"
                      checked={notificationOption === "selected"}
                      onChange={handleOptionChange}
                    />
                    Only the people I select...
                  </label>
                </div>

                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      value="none"
                      checked={notificationOption === "none"}
                      onChange={handleOptionChange}
                    />
                    No one
                  </label>
                </div>

                <div className="text-start">
                  <button type="button" className="save-draft me-3">Save as a draft</button>
                  <button type="submit" className="post-message">Post this message</button> <FaRegClock className='ms-3 fs-4' />
                </div>
              </form>
              {notificationOption === "selected" && (
                <div className="notification-container">
                  <h2 className="notification-heade text-start">Who should be notified?</h2>
                  {/* "Select everyone" and "Select no one" options */}
                  <div className="notification-select-actions text-start">
                    <Link className="notification-select" onClick={selectEveryone}>Select everyone</Link> .
                    <Link className="notification-select" onClick={selectNoOne}>Select no one</Link>
                  </div>
                  <div className="notification-options">
                    <div className="notification-note">
                      <p className="notification-subtitle text-start"><strong>Webnexs<span className='clientTxt ms-2 '>Client</span></strong></p>
                      <p className='text-start'>People from Webnexs <strong>aren't available</strong> because you've chosen to keep this private to your team.</p>
                    </div>
                    <div className="notification-people-list">
                      <div className="notification-person">
                        <label className="notification-label">Manivel, Full stack developer
                          <input
                            type="checkbox"
                            checked={selectedPeople.Manivel}
                            onChange={() => handleCheckboxChange('Manivel')}
                            className="notification-checkbox"
                          />
                        </label>
                      </div>
                      <hr className="line m-0" />
                      <div className="notification-person">
                        <label className="notification-label text-start">Prakash Kumar
                          <input
                            type="checkbox"
                            checked={selectedPeople.PrakashKumar}
                            onChange={() => handleCheckboxChange('PrakashKumar')}
                            className="notification-checkbox"
                          />
                        </label>
                      </div>
                      <div className="notification-divider text-start">Everyone else</div>
                      <div className="notification-person">
                        <label className="notification-label">Kavi Arasan
                          <input
                            type="checkbox"
                            checked={selectedPeople.KaviArasan}
                            onChange={() => handleCheckboxChange('KaviArasan')}
                            className="notification-checkbox text-end"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr className="line m-0" />
                  <div className="notification-button-group text-start">
                    <button className="notification-save me-3">Save changes</button>
                    <button className="notification-cancel">Never mind</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="message-board-categories">
        <h3>Message Board categories</h3>
        <p>Set up categories for this Message Board below, or change categories for the whole account in <Link >Adminland.</Link></p>
        <ul>
          {categories.map(category => (
            <li key={category.id}>
              <span>{category.icon}</span>
              {isEditing === category.id ? (
                <input
                  type="text"
                  value={category.name}
                  onChange={(e) => editCategory(category.id, e.target.value)}
                />
              ) : (
                <span>{category.name}</span>
              )}
              {isEditing === category.id ? (
                <button onClick={() => editCategory(category.id, category.name)}>✔</button>
              ) : (
                <>
                  <button className='me-2' onClick={() => setIsEditing(category.id)}><FaEdit /></button>
                  <button onClick={() => deleteCategory(category.id)}><FaTrashAlt /></button>
                </>
              )}
            </li>
          ))}
        </ul>
        // {/* Add Category Section */}
      {/* {isAdding ? (
          <div className="add-category">
            <input
              type="text"
              placeholder="Name it..."
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
            />
            <div className="icon-selection">
              <p>Select an icon:</p>
              {availableIcons.map(icon => (
                <span
                  key={icon}
                  onClick={() => setSelectedIcon(icon)}
                  style={{
                    cursor: 'pointer',
                    fontSize: '1.5rem',
                    margin: '0 5px',
                    border: selectedIcon === icon ? '2px solid blue' : 'none',
                  }}
                >
                  {icon}
                </span>
              ))}
            </div>
            <button onClick={addCategory}>✔</button>
            <button onClick={() => setIsAdding(false)}>✖</button>
          </div>
        ) : (
          <button className="add-category-btn" onClick={() => setIsAdding(true)}>
            Add a category
          </button>
        )}
      </div>  */}
    </div>
  );
};

export default NewMessage;
