import React, { useState } from "react";
import PasswordForm from "./PasswordFrom";

const Signup = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    companyname: "",
    password: "",
  });
  const [error, setError] = useState(null); // State to hold error message
  const [isLoading, setLoading] = useState(false); // State to manage loading state
  const [isSign, setSignUp] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false); // State to manage email validation error
  const [invalidCompany, setInvalidCompany] = useState(false); // State to manage company name validation error

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Simple email validation regex
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Check if company name is not empty
  const validateCompanyName = (companyname) => {
    return companyname.trim().length > 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Validation
    const emailValid = validateEmail(formData.email);
    const companyValid = validateCompanyName(formData.companyname);
  
    setInvalidEmail(!emailValid);
    setInvalidCompany(!companyValid);
  
    // If either validation fails, show an error and return early
    if (!emailValid || !companyValid) {
      let errorMessage = "";
      if (!emailValid) errorMessage += "Invalid email format. ";
      if (!companyValid) errorMessage += "Organization name is required.";
      setError(errorMessage); // Set a meaningful error message
      setLoading(false);
      return;
    }
  
    try {
      setError(null); // Clear any previous errors
  
      // Simulating API call for signup
      const response = await fakeApiCallForSignup(formData); // Replace with real API call
  
      if (response.emailExists) {
        setError("This email is already registered. Please use another email."); // Handle specific error
      } else {
        setSignUp(true); // Move to password form
      }
  
    } catch (err) {
      setError("An unexpected error occurred. Please try again."); // Handle other potential errors
    } finally {
      setLoading(false); // Reset loading state
    }
  };
  
  // Simulated API call function for demonstration
  const fakeApiCallForSignup = async (data) => {
    // Simulate a scenario where the email already exists
    if (data.email === "already@registered.com") {
      return { emailExists: true };
    }
    return { emailExists: false };
  };
  
  

  return isSign ? (
    <PasswordForm formData={formData} setFormData={setFormData} />
  ) : (
    <div className="d-flex justify-content-center align-items-center signup">
      <div
        className="Loginpage text-center"
        style={{ maxWidth: "500px", width: "100%" }}
      >
        <img
          src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public'
          alt="Logo"
          className="d-block m-auto"
          style={{ width: "100px" }}
        />
        <h5 className="card-title text-center mb-3 text-white">Try TeamNex for free</h5>
        <h3 className="text-center card-title text-m text-white">
          No credit card required • Cancel anytime
        </h3>
        <div className="card p-4 mt-4 shadow-sm">
          <div className="card-body">
            <h3 className="text-center card-title text-mm mb-4 text-white">
              Just need a few things to get you going
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Your name"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className={`form-control mb-2 ${invalidEmail ? 'is-invalid' : ''}`} // Conditional class for invalid input
                  placeholder={invalidEmail ? "Please enter a valid email" : "Your email (e.g. julie.young@hey.com)"} // Red placeholder if invalid
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  style={{ color: invalidEmail ? "black" : "black" }} // Red text for invalid email
                />
                {invalidEmail && <small className="text-danger">Invalid email format</small>} {/* Error message */}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control mb-2 ${invalidCompany ? 'is-invalid' : ''}`} // Conditional class for invalid input
                  placeholder={invalidCompany ? "Please enter your organization name" : "Your organization's name"} // Red placeholder if invalid
                  id="companyname"
                  name="companyname"
                  value={formData.companyname}
                  onChange={handleChange}
                  required
                  style={{ color: invalidCompany ? "black" : "black" }} // Red text for invalid company name
                />
                {invalidCompany && <small className="text-danger">Organization name is required</small>} {/* Error message */}
              </div>
              <button
                type="submit"
                className="btn btn-success btn-block mt-3 w-100"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Last step: you’ll set a password"}
              </button>
              {error && <p className="text-danger mt-2">{error}</p>} {/* Display general form error */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
