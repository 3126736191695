import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';



const AutocheckinNewQuestion = () => {
    const [question, setQuestion] = useState('');
    const [days, setDays] = useState([]);
    const [time, setTime] = useState('09:00');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedDay, setSelectedDay] = useState('');
    const [frequency, setFrequency] = useState('weekly');
    const [day, setDay] = useState('Mo');
    const [timeOfDay, setTimeOfDay] = useState('morning');
    const [selectedPeople, setSelectedPeople] = useState({
        'Abishek': true,
        'Anbarasan R': true,
        'Arul Kumar V': true,
        'Arun Kumar': true,
        'Arun Pandiyan': true,
        'ArunKumar AD': true,
        'Bala': true,
        'Balaji': true,
        'Bali Balajee': true,
    });

    const handleUserChange = (user) => {
        setSelectedUsers(prevUsers =>
            prevUsers.includes(user) ? prevUsers.filter(u => u !== user) : [...prevUsers, user]
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log({ question, frequency, days, time, selectedUsers });
    };

    const handleFrequencyChange = (event) => {
        setFrequency(event.target.value);
    };

    const handleDayChange = (event) => {
        setDay(event.target.value);
    };

    const handleTimeChange = (e) => {
        setTimeOfDay(e.target.value);
    };

    const handlePersonToggle = (person) => {
        setSelectedPeople({
            ...selectedPeople,
            [person]: !selectedPeople[person],
        });
    };

    const selectAll = () => {
        const allSelected = Object.keys(selectedPeople).reduce((acc, person) => {
            acc[person] = true;
            return acc;
        }, {});
        setSelectedPeople(allSelected);
    };

    const deselectAll = () => {
        const allDeselected = Object.keys(selectedPeople).reduce((acc, person) => {
            acc[person] = false;
            return acc;
        }, {});
        setSelectedPeople(allDeselected);
    };

    const handleDayClick = (dayOption) => {
        setSelectedDay(dayOption);
    };

    return (
        <div className='campfire todo-contain'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <a href="#" className="fw-bold">Webnexs HQ</a>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center" style={{ width: '75vw' }}>
                    <div className="card secondary-class campfireBody container">
                        <form className='p-5 questionColor' onSubmit={handleSubmit}>
                            <div>
                                <p className='text-start fw-bold'>What question do you want to ask?</p>
                                <input className='newquesText' placeholder='Type your question...' type="text" value={question} onChange={(e) => setQuestion(e.target.value)} />
                            </div>
                            <div className='text-start'>
                                <p className='text-start fw-bold'>How often do you want to ask?</p>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="daily"
                                            checked={frequency === 'daily'}
                                            onChange={handleFrequencyChange}
                                        />
                                        Daily
                                    </label>
                                    {frequency === 'daily' && (
                                        <div>
                                            {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((dayOption) => (
                                                <label key={dayOption}>
                                                    <span
                                                        value={dayOption}
                                                        checked={day === dayOption}
                                                        onChange={handleDayChange}
                                                    />
                                                    <button
                                                        className={`autocheckBtn ${selectedDay === dayOption ? 'selected' : 'selected'}`}
                                                        onClick={() => handleDayClick(dayOption)}
                                                        type="button"
                                                    >
                                                        {dayOption}
                                                    </button>
                                                    <hr className="line" />
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="weekly"
                                            checked={frequency === 'weekly'}
                                            onChange={handleFrequencyChange}
                                        />
                                        Once a week on...
                                    </label>
                                    {frequency === 'weekly' && (
                                        <div>
                                            {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((dayOption) => (
                                                <label key={dayOption}>
                                                    <span
                                                        value={dayOption}
                                                        checked={day === dayOption}
                                                        onChange={handleDayChange}
                                                    />
                                                    <button
                                                        className={`autocheckBtn ${selectedDay === dayOption ? 'selected' : ''}`}
                                                        onClick={() => handleDayClick(dayOption)}
                                                        type="button"
                                                    >
                                                        {dayOption}
                                                    </button>
                                                    <hr className="line" />
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="biweekly"
                                            checked={frequency === 'biweekly'}
                                            onChange={handleFrequencyChange}
                                        />
                                        Every other week...
                                    </label>
                                    {frequency === 'biweekly' && (
                                        <div>
                                            {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((dayOption) => (
                                                <label key={dayOption}>
                                                    <span
                                                        value={dayOption}
                                                        checked={day === dayOption}
                                                        onChange={handleDayChange}
                                                    />
                                                    <button
                                                        className={`autocheckBtn ${selectedDay === dayOption ? 'selected' : ''}`}
                                                        onClick={() => handleDayClick(dayOption)}
                                                        type="button"
                                                    >
                                                        {dayOption}
                                                    </button>
                                                    <hr className="line" />
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="monthly"
                                            checked={frequency === 'monthly'}
                                            onChange={handleFrequencyChange}
                                        />
                                        Once a month on the first...
                                    </label>
                                    {frequency === 'monthly' && (
                                        <div>
                                            {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((dayOption) => (
                                                <label key={dayOption}>
                                                    <span
                                                        value={dayOption}
                                                        checked={day === dayOption}
                                                        onChange={handleDayChange}
                                                    />
                                                    <button
                                                        className={`autocheckBtn ${selectedDay === dayOption ? 'selected' : ''}`}
                                                        onClick={() => handleDayClick(dayOption)}
                                                        type="button"
                                                    >
                                                        {dayOption}
                                                    </button>
                                                    <hr className="line" />
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div>
                                <p className='text-start fw-bold'>At what time of day?</p>
                                <div className='text-start m-0'>
                                    <input
                                        type="radio"
                                        value="morning"
                                        checked={timeOfDay === 'morning'}
                                        onChange={handleTimeChange}
                                    />{' '}
                                    Beginning of the day (9:00am)
                                    <hr className="line" />
                                </div>
                                <div className='text-start'>
                                    <input
                                        type="radio"
                                        value="evening"
                                        checked={timeOfDay === 'evening'}
                                        onChange={handleTimeChange}
                                    />{' '}
                                    End of the day (4:30pm)
                                    <hr className="line" />
                                </div>
                                <div className='text-start'>
                                    <input
                                        type="radio"
                                        value="custom"
                                        checked={timeOfDay === 'custom'}
                                        onChange={handleTimeChange}
                                    />{' '}
                                    Let me pick a time...
                                    <hr className="line" />
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <p className='text-start fw-bold'>Who do you want to ask?</p>
                                    </div>
                                    <div>
                                        <Link className='autoBtn' onClick={selectAll}>Select all</Link>.
                                        <Link className='autoBtn' onClick={deselectAll}>Deselect all</Link>
                                    </div>
                                </div>
                                {Object.keys(selectedPeople).map((person) => (
                                    <div className='d-flex justify-content-between P-3' key={person}>
                                        <div className='p-3'>
                                            {person}
                                        </div>
                                        <input
                                            type="checkbox"
                                            checked={selectedPeople[person]}
                                            onChange={() => handlePersonToggle(person)}
                                        />{' '}
                                    </div>
                                ))}
                            </div>
                            <div className='text-center'>
                                <button className='collectingBtn text-center' type="submit">Start collecting answers</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AutocheckinNewQuestion;
