import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faTimes
} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';
import InviteSuccess from './InviteUser/InviteSuccess';
import InviteExist from './InviteUser/InviteExist';

const Coworkers_Account = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
  const [personalNote, setPersonalNote] = useState("");
  const selectedRole = location.state?.selectedRole || 'Not Specified';
  const [isLoading, setIsLoading] = useState(false);
  const [isTextEditorVisible, setTextEditorVisible] = useState(false);
  const [formData, setFormData] = useState([
    {
      fullName: '',
      email: '',
      jobTitle: '',
      company: userData.user.companyname,
      personalNote: '',
      invitedBy: userData.user._id,
      organizationId: userData.user.organizationId._id,
    },
  ]);
  const [errors, setErrors] = useState({});
  const toolbarOptions = [
    ['bold', 'italic', 'strike'], // Formatting options
    [{ list: 'ordered' }, { list: 'bullet' }], // Ordered and unordered lists
    ['link'], // Link option
    [{ 'color': [] }, { 'background': [] }],
    ['blockquote', 'code-block'],
    ['clean']
  ];
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData];
    updatedFormData[index][name] = value;
    setFormData(updatedFormData);
  };

  const handleAddForm = () => {
    setFormData([
      ...formData,
      {
        fullName: '',
        email: '',
        jobTitle: '',
        company: userData.user.companyname,
        invitedBy: userData.user._id,
        organizationId: userData.user.organizationId._id,

      },
    ]);
  };
  const handleRemoveForm = (index) => {
    const updatedFormData = formData.filter((_, i) => i !== index);
    setFormData(updatedFormData);
  };
  const validateForm = () => {
    const newErrors = {};
    formData.forEach((data, index) => {
      const formErrors = {};
      if (!data.fullName?.trim()) formErrors.fullName = 'Name is required';
      if (!data.email?.trim()) {
        formErrors.email = 'Email is required';
      } else if (!/^[\w.%+-]+@[\w.-]+\.(com|net|org|edu|in)$/i.test(data.email)) {
        formErrors.email = 'Email must be a valid address';
      }
      if (!data.company?.trim()) formErrors.company = 'Company is required';

      if (Object.keys(formErrors).length > 0) newErrors[index] = formErrors;
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const stripHtml = (html) => {
  //   const div = document.createElement("div");
  //   div.innerHTML = html;
  //   return div.textContent || div.innerText || "";
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const sanitizedNote = stripHtml(personalNote);
    console.log("Form Data:", formData);
    if (!validateForm()) return;
    // If personal note is visible and filled out, ensure that personalNote state is captured correctly
    if (isTextEditorVisible && personalNote.trim() === '') {
      toast.error('Please add a personal note to the invitation email');
      return;
    }
    setIsLoading(true);
    const inviteResponses = [];
    try {
      for (let i = 0; i < formData.length; i++) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/invite`, {
          ...formData[i],
          role: selectedRole,
          personalNote: personalNote.trim(),
        });
        console.log("API Response:", response.data);
        if (response.status === 201) {
          inviteResponses.push(response.data); // Add successful response to array
          // toast.success(`Invitation sent successfully to ${formData[i].fullName}!`);
          console.log(response.data);
        } else if (response.status === 409 && response.data.redirectTo) {
          // User already exists
          console.log(response.data.redirectTo);
          navigate('exist');
        }
      }
      if (inviteResponses.length > 0) {
        // Only navigate if there are successful invites
        navigate('success', {
          state: {
            inviteResponses: inviteResponses // Pass array of responses
          }
        });
      }
      // Reset form data after sending invitations
      setFormData([
        {
          fullName: '',
          email: '',
          jobTitle: '',
          company: userData.user.companyname,
          personalNote: '',
          invitedBy: userData.user._id,
          organizationId: userData.user.organizationId._id,
        },
      ]);
      setErrors({});
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Handle the 409 error if not already processed
        const { redirectTo } = error.response.data;
        // console.log(redirectTo);
        navigate(redirectTo || 'exist', {
          state: {
            formData: formData
          }
        });
        // toast.error(message);
      } else {
        // General error handling for other cases
        console.error('Error sending invitation:', error);
        toast.error('Failed to send invitation. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className='coworkersAccount'>
      <div className='Coworkers_Account p-3'>
        <div className='d-flex justify-content-center align-item-center mt-5'>
          <h2 className='fw-bold  text-center'>Set up your coworker’s account</h2>
        </div>
        <div className='d-flex justify-content-center align-item-center'>
          <p className=' text-center w-75'>
            People who work at {userData.user.companyname} are the only people who can create projects, add others
            to projects, and act as administrators. They can be full-time, part-time, or a volunteer.
          </p>
        </div>

        <div className="container d-flex justify-content-center align-items-center flex-column">
          {formData.map((form, index) => (
            <form className="form-box p-4 mt-3 rounded" key={index} onSubmit={handleSubmit}>
              {index > 0 ? (
                <button
                  type="button"
                  className="form-close-btn"
                  onClick={() => handleRemoveForm(index)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              ) : null}
              <div className="row mb-3 mt-3">
                <div className="col">
                  <input
                    type="text"
                    className={`form-control ${errors[index]?.fullName ? 'is-invalid' : ''}`}
                    placeholder={errors[index]?.fullName || "Full name"}
                    name="fullName"
                    value={form.fullName}
                    onChange={(e) => handleChange(e, index)}
                    required
                  />
                </div>
                <div className="col">
                  <input
                    type="email"
                    className={`form-control ${errors[index]?.email ? 'is-invalid' : ''}`}
                    placeholder={errors[index]?.email || "Email address"}
                    name="email"
                    value={form.email}
                    onChange={(e) => handleChange(e, index)}
                    required
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job title (optional)"
                    name="jobTitle"
                    value={form.jobTitle}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className={`form-control ${errors[index]?.company ? 'is-invalid' : ''}`}
                    placeholder={errors[index]?.company || "Company/organization"}
                    name="company"
                    value={form.company}
                    onChange={(e) => handleChange(e, index)}
                    required
                  />
                </div>
              </div>
            </form>
          ))}
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-outline-success rounded-circle add-button"
              onClick={handleAddForm}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>

        {!isTextEditorVisible && (
          <div className='d-flex justify-content-center align-item-center mt-5'>
            <Link to='' className='downlink text-edit-link'
              onClick={(e) => {
                e.preventDefault();
                setTextEditorVisible(true);
              }}>
              Add a personal note to the invitation email
            </Link>
          </div>
        )}

        {isTextEditorVisible && (
          <div className='container d-flex justify-content-center align-items-center '>
            <div className='text-editor p-3 mt-2 secondary-class'>
              <p className='fw-bold pt-2'>Add a personal note to the invitation email</p>
              <div className='coworker-notes'><ReactQuill
                modules={{ toolbar: toolbarOptions }}
                style={{ flexGrow: 1, minHeight: '100px' }}
                placeholder='Type your notes here...' // Flex to take up input space
                value={personalNote} // Controlled by personalNote state
                onChange={setPersonalNote}
              /></div>
            </div>

          </div>
        )}

        <div className='d-flex justify-content-center'>
          <button className='mt-3 next-btn' onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Email invitation now...'}
          </button>
        </div>

        <div className='d-flex justify-content-center align-item-center mt-4'>
          <p className='downlink'>
            Need to add several coworkers at once?{' '}
            <Link to='/invite-link' className='downlink'>
              Invite them with a link instead.
            </Link>
          </p>

        </div>
      </div>
    </div >
  );
};

export default Coworkers_Account;