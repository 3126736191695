import React from 'react'
import { Link } from 'react-router-dom'
import { LuKey } from "react-icons/lu";

const Export_Data = () => {

    return (
        <div className='MergePeople todo-contain'>
            <div className=' text-center d-block m-auto card--head secondary-class' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <Link to='/adminland' className='m-2 fw-bold'><LuKey /> Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="body-bottom text-center secondary-class" style={{ width: '75vw' }}>
                    <div className="container ">
                        <div className="">
                            <p className='h2 fw-bold pt-3'>Export data from this account</p>
                        </div>
                        <hr />
                        <div className='body-content'>
                            <p className='px-5 pt-3 text-start'>Select what you want to export and we’ll bundle it up for you. Generating your export can take a few minutes or a few hours — it depends on the size of your export. You’ll need to wait for an export to complete to start a new one.</p>
                            <p className='px-5 pt-2 text-start'>When it’s done, we’ll email you a download link that’ll include:</p>

                            <ul className='text-start'>All your data in a website you can open in a browser on your computer, and click through any time.</ul>
                            <ul className='text-start'>Every project gets a folder with all the images, PDFs, spreadsheets, docs, etc. in one place.</ul>

                            <p className='text-center fw-bold mt-3'>What would you like to export?</p>
                            <div className='d-flex justify-content-center '>
                                <Link> Select all unarchived</Link>
                                <span> &middot; </span >
                                <Link>Select none</Link>
                            </div>

                            <hr className='mx-5' />
                            <div className='d-flex justify-content-between mx-5 text-start'>
                                <div>
                                    <p className='fw-bold my-0'>Getting started</p>
                                    <p className='my-0'>Quickly get upto speed with everything Teamnex</p>
                                    <p className='my-0 text-secondary'>Last update on july 25</p>
                                </div>
                                <div>
                                    <input type="checkbox" />
                                </div>
                            </div>
                            <hr className='mx-5' />
                            <div className='d-flex justify-content-between mx-5 text-start'>
                                <div>
                                    <p className='fw-bold my-0'>Teamnex</p>
                                    <p className='my-0'>Quickly get upto speed with everything Teamnex</p>
                                    <p className='my-0 text-secondary'>Last update on july 25</p>
                                </div>
                                <div>
                                    <input type="checkbox" />
                                </div>
                            </div>
                            <hr className='mx-5' />
                            <div className='d-flex justify-content-between mx-5 text-start'>
                                <div>
                                    <p className='fw-bold my-0'>Webnexs</p>
                                    <p className='my-0'>Quickly get upto speed with everything Teamnex</p>
                                    <p className='my-0 text-secondary'>Last update on july 25</p>
                                </div>
                                <div>
                                    <input type="checkbox" />
                                </div>
                            </div>
                            <hr className='mx-5' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Export_Data