import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableCellsLarge, faListUl, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import assert from '../../asset/graph-blue-paper.avif';

const View_Template = () => {
  const [activeTemplate, setActiveTemplate] = useState('project');

  return (
    <div className='View_Template mt-5'>
      <div className='d-flex justify-content-center mt-3'>
        <button className={`top-btn p-2 me-2 ${activeTemplate === 'project' ? 'active' : ''}`}
          onClick={() => setActiveTemplate('project')}>
            <span className='iconsclr p-1'>
          <FontAwesomeIcon className='icons ' icon={faTableCellsLarge} /></span> Project Templates
        </button>
        <button className={`top-btn p-2 ${activeTemplate === 'list' ? 'active' : ''}`}
          onClick={() => setActiveTemplate('list')}>
            <span className='iconsclr p-1'>
          <FontAwesomeIcon className='icons' icon={faListUl} /></span> To-do List Templates
        </button>
      </div>
      <div className='container viewtemplate mt-2 p-3' style={{width:"75vw"}}>
        {activeTemplate === 'project' && (
          <div 
            className='project-temp '  style={{height:"85vh"}}
            // style={{
            //   backgroundImage: `url(${assert})`,
            // }}
          >
            <h2 className='d-flex justify-content-center align-item-center fw-bold m-3 text-center'>Project Templates</h2>
            <p className='d-flex justify-content-center text-center m-3'>Save yourself time by creating project templates with frequently-used tools, to-do lists,<br /> files, and more. Anyone on your account who can create projects can use and edit these <br /> templates.</p>
            <div className='d-flex justify-content-center'>
              <button className='box-btn'>
                <Link to='/project-template' className='text-decoration-none'>
                  <p><FontAwesomeIcon className='text-success h1 fw-bold' icon={faPlus} /> <br /> <span className='text-white'>New Template</span></p>
                </Link>
              </button>
            </div>
          </div>
        )}
        {activeTemplate === 'list' && (
          <div className='List-temp project-temp'style={{height:"85vh"}}>
            <h2 className='d-flex justify-content-center align-item-center fw-bold m-3 text-center'>To-do List Templates</h2>
            <p className='d-flex justify-content-center text-center m-3'>Save yourself time by creating to-do templates you can drop into any project. Everyone on <br /> your account can edit and create their own lists from these templates.</p>
            <div className='d-flex justify-content-center'>
              <button className='box-btn'>
                <Link to='/todo-list-lemplate' className='text-decoration-none'>
                  <p><FontAwesomeIcon className='text-success h1 fw-bold' icon={faPlus} /> <br /> <span className='text-white'>New Template</span></p>
                </Link>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default View_Template;
