import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import CheckMail from './CheckMail';

const PasswordForm = ({ formData, setFormData }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password.length < 12) {
      setErrorMessage('Password must be at least 12 characters long.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, formData);
      // const response = await axios.post(`https://basecamp-admin.webnexs.org/register`, formData);
      // localStorage.setItem('authToken', true);
      // navigate('/');
      // toast.success('Signup successful! You can now log in.');
      setSuccess(true)

    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Assuming status code 409 is returned for "email already exists"

      } else {
        toast.error(error);
      }
      console.error('Signup error:', error.response ? error.response.data : error.message);
    }
  };


  return (
    !success ?
      <div className="d-flex justify-content-center align-items-center mt-5 password">
        <div className="Loginpage text-center" style={{ maxWidth: '448px', width: '100%' }}>
          <img src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public' alt="Logo" className="d-block m-auto" style={{ width: '100px' }} />
          <h5 className="card-title text-center text-black mb-3">Try TeamNex for free</h5>
          <h3 className="text-center card-title text-black text-m">No credit card required • Cancel anytime</h3>
          <div className="card p-4 mt-4 b-shad">
            <div className="card-body">
              <h2 className="h6 mt-0 mb-2 text-black text-center fw-bold">Now pick a password and you’re all set</h2>
              <p className="mt-0 mb-2 text-black">For your security, we require that passwords be at least 12 characters.</p>
              <form onSubmit={handleSubmit}>
                <div className="form-group position-relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control mb-2"
                    name="password"
                    placeholder="Type your password here…"
                    onChange={handleChange}
                    value={formData.password || ''}
                    required
                  />
                  <span
                    type="button"
                    className=" position-absolute align-items-center end-0 top-0 mt-2 me-2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </span>
                </div>
                <button type="submit" className="btn btn-success btn-block mb-2 mt-3 w-100">
                  Create my new account
                </button>
                {<p className='text-success'>{success}</p>}
                {errorMessage && <div className="alert p-1 alert-danger">{errorMessage}</div>}
              </form>
            </div>
          </div>
          <p className="text-black text-center mt-3">
            Questions? Need a hand? <a href="#">Support can help.</a>
          </p>
        </div>
      </div> : <CheckMail />
  );
};
export default PasswordForm;
