import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';

import MessageBoardShow from "../ProjectPage/MessageBoard/MessageBoardShow";
import CampfireShow from "../ProjectPage/CampfireShow";
import ScheduleShow from "../ProjectPage/ScheduleShow";
import TodoShow from "../ProjectPage/TodoShow";
import AutocheckinShow from "../ProjectPage/AutocheckinShow";
import DocumentShow from "../ProjectPage/DocumentShow";
import EmailForwardShow from "../ProjectPage/EmailForwardShow";
import CardTableShow from "../ProjectPage/CardTableShow";
import ProfileCircles from "./ProfileCircles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { BiSolidArchiveIn } from "react-icons/bi";
import { FaBellSlash, FaBoxesStacked } from "react-icons/fa6";
import { BsPinAngleFill } from "react-icons/bs";
import { FiGlobe } from "react-icons/fi";
import { IoIosSettings, IoMdPeople } from "react-icons/io";
import { Cookie } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import Campfire from './Campfire';

const items = [
  {
    username: "Arun Pandiyan",
    profilePic: '',
    title: "Title 1",
    paragraph: "this is sample paragraph to check the messageboard in TeamNex truncation functionality in the message board"
  },
  {
    username: "Pragatheeshwaran",
    profilePic: 'photoo.JPG',
    title: "Title 2",
    paragraph: "Another example paragraph to demonstrate the truncation functionality in the message board."
  },
  {
    username: "balayukesh",
    profilePic: '',
    title: "Title 3",
    paragraph: "This paragraph is short."
  },
  {
    username: "User Four",
    profilePic: 'photoo.JPG',
    title: "Title 4",
    paragraph: "This is another sample paragraph for the message board."
  }
];






const ProjectCard = () => {
  const { id } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [cookies] = useCookies(['token']);
  const location = useLocation();
  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
  const { organizationId } = useParams()
  console.log(organizationId)
  const openNav = () => {
    setIsOpen(true);
  };


  const closeNav = () => {
    setIsOpen(false);
  };


  useEffect(() => {
    const fetchProject = async () => {
      const token = userData?.token || cookies.token;;
      if (!token) {
        setError('No token found. Please log in.');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/project/${id}?organizationId=${organizationId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });

        const data = await response.json();

        if (response.ok) {
          setProjectData(data.project);
        } else {
          setError(data.message || 'Failed to load project');
        }
      } catch (err) {
        console.error('Error fetching project:', err);
        setError(err.message || 'Error fetching project');
      }
    };

    fetchProject();
  }, [id, organizationId,]);

  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <div className='Projectborder container project secondary-class'>
      <div>
        <div id="mySidenav" className={`sidenav ${isOpen ? 'open' : ''}`}>
          <span className="closebtn" onClick={closeNav}>&times;</span>

          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><BsPinAngleFill /></button>Pin to Home</Link>
          </div>
          <div className="P-2">
            <Link to="/justfollow" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaBellSlash /></button>Switch to just following</Link>
          </div>
          <div className="P-2">
            <Link to="/projectsettings" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><IoIosSettings /></button>Edit project settings</Link>
          </div>
          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaBoxesStacked /></button>Set up tools</Link>
          </div>
          <div className="P-2">
            <Link to="people/users/edit" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><IoMdPeople /></button>Set up people</Link>
          </div>
          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><BiSolidArchiveIn /></button>Archive or delete</Link>
          </div>
          <hr className="line" />
          <p className=' sideEvent ps-2 m-0'>For developers</p>
          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FiGlobe /></button>Set up webhooks</Link>
          </div>

        </div>
      </div>

      <div className='projectheader'>
        <div className="d-flex justify-content-end">
          <button className="projeccardtBtn  mt-2" onClick={openNav}>
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>
        </div>

        <h3 className='text-center fs-4 fw-bold  headcamp'>{projectData?.name}</h3>
        <h4 className=' fs-6 fw-normal text-center'>
          {projectData?.description}
        </h4>

        <div className="d-flex flex-column d-flex justify-content-center flex-md-row setupbtn">
          <Link to="people/users/edit" className=" text-decoration-none sbtn t-color1">Set up people</Link>
          <div className="d-flex justify-content-center justify-content-md-start mt-2 ps-md-4">
            {projectData?.members && <ProfileCircles accounts={projectData?.members} />}
          </div>
        </div>
      </div>

      {/* Project tools and other sections */}
      <div className='d-flex justify-content-center'>

        <div className="row d-flex justify-content-center p-0 w-100 mb-5">
          {/* Campfire */}
          {projectData?.tools?.chat && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div" >
              <Link to="chat" className="text-decoration-none" state={{ projectData }}>
                <CampfireShow projectData={projectData} />
              </Link>
            </div>
          )}

          {/* Messageboard */}
          {projectData?.tools?.messageBoard && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
              <Link to="messageBoard" className="text-decoration-none" state={{ projectData }}>
                <MessageBoardShow projectData={projectData} />
              </Link>
            </div>
          )}

          {/* Schedule */}
          {projectData?.tools?.schedule && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
              <Link to="my/schedules" className="text-decoration-none">
                <ScheduleShow />
              </Link>
            </div>
          )}

          {/* Todo show */}
          {projectData?.tools?.todos && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
              <Link to="todos" className="text-decoration-none">
                <TodoShow />
              </Link>
            </div>
          )}

          {/* Doc & Files */}
          {projectData?.tools?.docsFiles && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
              <Link to="docsfiles" className="text-decoration-none">
                <DocumentShow data={items} />
              </Link>
            </div>
          )}

          {/* Card table */}
          {projectData?.tools?.docsFiles && (
            <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
              <Link to="cardtable" className="text-decoration-none">
                <CardTableShow data={items} />
              </Link>
            </div>
          )}

          {/* Auto checkin */}
          <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
            <Link to="autocheckin" className="text-decoration-none">
              <AutocheckinShow data={items} />
            </Link>
          </div>

          {/* Email forward */}
          <div className="col-12 col-md-6 col-lg-4 p-0 m-0 pro-card-div">
            <Link to="emailforward" className="text-decoration-none">
              <EmailForwardShow data={items} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;