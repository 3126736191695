import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

const ScheduleShow = ({ data }) => {
  return (
    <div className="cardbox">
      <h2 className="card-title  fw-bold fs-5 text-center py-2 t-color1">
        Schedule
      </h2>
      <div className="card-body px-3 pt-2 overflow-hidden project-in-card">
        <div className="card-item">
          <div className="d-flex justify-content-center pt-3">
            <div className="calendar-icon">
              <FontAwesomeIcon icon={faCalendar} size="3x" />
            </div>
          </div>
          <p className="fw-normal fs-6 text-secondary text-center">Theres is no upcomming event in the schedule</p>
        </div>
      </div>
    </div>
  );
};
export default ScheduleShow;
