import React,{ useState }  from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faBold,faItalic,faStrikethrough,faLink,faFillDrip,faT,faQuoteLeft,faAlignCenter,faCode,faListUl,faListOl, faRotateRight,faRotateLeft } from '@fortawesome/free-solid-svg-icons';



const ToDo_List_Template = () => {
    const [isTextEditorVisible, setTextEditorVisible] = useState(false);

    const handleTextEditLinkClick = (e) => {
        e.preventDefault();
        setTextEditorVisible(true);
    }; 

  return (
    <div className='ToDo_List_Template mt-5'>
            <div className=' text-center d-block m-auto card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <Link to='/view-template' className='m-2 fw-bold'>To-do List Template</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="body-bottom text-center" style={{ width: '75vw' }}>
                        <div className="container "style={{height:"90vh"}}>
                            <div className="d-flex justify-content-center align-item-center">
                                <p className='h3 fw-bold pt-3'>Create a new list template</p>
                            </div>
                            <form className='d-flex justify-content-center mt-3 align-items-center mb-3'>
                                <div className='bor-box'>
                                   <input type="text" name="" id="" className='w-100 fw-bold h4 ip-boxx ps-3 mt-2' placeholder='Name the List...' />
                                   <hr />
                                   {!isTextEditorVisible && (
                                   <button className='d-flex justify-content-start ps-3 mt-3 mb-3 file-box' onClick={handleTextEditLinkClick}>Add extra details or attach a file...</button>
                                   )}
                                   {isTextEditorVisible && (
                                   <div className='container d-flex justify-content-center align-items-center'>
                                     <div className='text-editor w-100 mb-3 mt-3'>
                                        <div className='text-box w-100'>
                                            <div className='d-flex justify-content-between'>
                                               <div className='left-icons'>
                                                 <button><FontAwesomeIcon className='p-1' icon={faBold } /></button>
                                                 <button><FontAwesomeIcon className='p-1' icon={faItalic} /></button>
                                                 <button><FontAwesomeIcon className='p-1' icon={faStrikethrough} /></button>
                                                 <button><FontAwesomeIcon className='p-1' icon={faLink} /></button>
                                                 <button><FontAwesomeIcon className='p-1' icon={faFillDrip} /></button>
                                                 <button><FontAwesomeIcon className='p-1' icon={faT} /></button>
                                                 <button><FontAwesomeIcon className='p-1' icon={faQuoteLeft} /></button>
                                                 <button><FontAwesomeIcon className='p-1' icon={faAlignCenter} /></button>
                                                 <button><FontAwesomeIcon className='p-1' icon={faCode} /></button>
                                                 <button><FontAwesomeIcon className='p-1' icon={faListUl} /></button>
                                                 <button><FontAwesomeIcon className='p-1' icon={faListOl} /></button>
                                                </div>
                                               <div className='right-icons'>
                                                 <button><FontAwesomeIcon icon={faRotateLeft} /></button>
                                                 <button><FontAwesomeIcon icon={faRotateRight} /></button>
                                               </div>
                                            </div>
                                            <textarea name="textbox" className='w-100 text-area' rows={10} placeholder='Add extra details or attach a file...'></textarea>
                                        </div>
                                     </div>
                                   </div>
                                )}
                                </div> 
                            </form>
                            <div className='d-flex justify-content-center'>
                               <div className='btnbox mb-3'>
                                  <div className='d-flex justify-content-start'>
                                    <button className=' next-btn text-start me-2'>Next:Set up this template &#8594;</button>
                                    <button className='cancel-btn'>cancel</button>
                                  </div>
                               </div>
                            </div>
                            
                        </div>
               </div>
            </div>
    </div>
  )
}

export default ToDo_List_Template