import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

const SelectOrganization = ({ onSignIn }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedOrg, setSelectedOrg] = useState(null);
    const organizations = location.state?.organizations || [];

    // Handle organization selection
    const handleSelect = async () => {
        if (!selectedOrg) {
            toast.error("Please choose an organization.");
            return;
        }
        const token = localStorage.getItem('token');
        if (!token) {
            toast.error("You are not logged in.");
            return;
        }
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/select-org`,
                { organizationId: selectedOrg },
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Send token in Authorization header
                    },
                }
            );
            localStorage.setItem("token", data.token);
            onSignIn(data.token)
            navigate(`/${selectedOrg}`);
            toast.success("Organization selected successfully!");
        } catch (error) {
            console.error("Selection Error:", error.response?.data?.message);
            toast.error("Failed to select organization.");
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center password" style={{ height: "100vh" }}>
            <div className="Loginpage text-center" style={{ maxWidth: '448px', width: '100%' }}>
                <h3 className="text-center card-title text-black text-m">Teamnex Launchpad</h3>
                <div className="card p-4 mt-4 b-shad ">
                    {organizations.map((org) => (
                        <div
                            key={org.organization._id}
                            className={`organization-item ${selectedOrg === org.organization._id ? 'selected' : ''}`} // Add class if selected
                            onClick={() => setSelectedOrg(org.organization._id)} // Select organization
                            style={{ cursor: 'pointer' }}
                        >
                            <img
                                src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public'
                                alt="Logo"
                                className="d-block m-auto"
                                style={{ width: '70px' }}
                            />
                            <div className="organization-name">
                                <h4>{org.organization.name}</h4>
                            </div>
                            {/* You can add a visual indicator for selection here */}
                        </div>
                    ))}
                </div>
                <button
                    className="loginBtn custom-btn btn-block mt-3 w-100 "
                    onClick={handleSelect} // Call handleSelect on button click
                >
                    Select Organization
                </button>
                <p className="text-black text-center mt-3">
                    Questions? Need a hand? <a href="#">Support can help.</a>
                </p>
            </div>
        </div>
    );
};

export default SelectOrganization;
