import React from 'react'
import { Link } from 'react-router-dom'
import { GiKey } from "react-icons/gi";
import { Form, Row, Col } from 'react-bootstrap';


const Invite_Link = () => {
    return (
        <div className='Invite_Link'>
            <div className=' text-center d-block m-auto card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <Link to='/Adminland' className=''><GiKey /> Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="body-bottom text-center" style={{ width: '75vw' }}>
                    <div className="container ">
                        <div className="d-flex justify-content-center align-item-center">
                            <p className='h3 fw-bold pt-3'>Invite coworkers with a link</p>
                        </div>
                        <div className="d-flex justify-content-center align-item-center">
                            <p>Share a unique URL with people who work at webnexs.com so they can join this account.</p>
                        </div>
                        <div className="d-flex justify-content-center align-item-center">
                            <div className='link-box w-75 mt-3 mb-5 p-4'>
                                <div className="d-flex justify-content-center align-item-center mb-3">
                                    <div class="invToggBtn">
                                        <span className='fw-bold'>Invite Link</span>
                                        <span className=''>
                                            <input className='toggleip' type="checkbox" id="check" />
                                            <label htmlFor="check" className='button'></label>
                                        </span>
                                    </div>
                                </div>
                                <p className='d-flex align-item-start fw-bold mb-0'>Which email domain do your coworkers use?</p>
                                <p className='d-flex align-item-start'>TeamNex will only allow people to join from this domain.</p>
                                <div className='mb-4'>
                                    <input type="text" className="form-control ipboxx " placeholder="e.g, yourcompany.com" />
                                </div>
                                <p className='d-flex align-item-start fw-bold mb-2'>Which projects should people be added to when they join?</p>
                                <Form.Group className="mb-3">
                                    <Col className='w-100' sm="10">
                                        <Form.Select className='inputdd'>
                                            <option>Type the name of the projects...</option>
                                            <option>Option 1</option>
                                            <option>Option 2</option>
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <div className='d-flex justify-content-center align-item-center mt-2'>
                                    <strong>Before you share this link, remember…</strong>
                                </div>
                                <div className='d-flex justify-content-center align-item-center'>
                                    <p className='text-center'>People who join via this link can do things like create new projects, add people to projects, delete things, and see everything in the projects they’re added to.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invite_Link

