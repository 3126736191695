import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaPlus } from "react-icons/fa";
import { RiPieChartFill } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import axios from 'axios';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const ToDos = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showTodoForm, setShowTodoForm] = useState(false);
    const [listName, setListName] = useState('');
    const [details, setDetails] = useState('');
    const [isTracked, setIsTracked] = useState(false);
    const [todoLists, setTodoLists] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [assignedUser, setAssignedUser] = useState('');
    const [users, setUsers] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    // const [editorValue, setEditorValue] = useState(''); // State to store editor content

    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);

    // Fetch to-do lists from the server
    useEffect(() => {
        const fetchTodoLists = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/todolists`);
                setTodoLists(response.data);
            } catch (error) {
                console.error('Error fetching to-do lists:', error);
            }
        };
        fetchTodoLists();
    }, []);

    // Fetch users for assigning tasks
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`);
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, []);

    // Socket.IO for real-time updates
    useEffect(() => {
        const socket = io(process.env.REACT_APP_API_URL);
        socket.on('todoUpdated', (updatedTodo) => {
            setTodoLists(prev => prev.map(todo => (todo.id === updatedTodo.id ? updatedTodo : todo)));
        });
        return () => socket.disconnect();
    }, []);

    // Add a new to-do list
    const handleAddList = async () => {
        const newList = { title: listName, description: details, isTracked };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/todolists`, newList);
            setTodoLists([...todoLists, response.data]);
            setListName('');
            setDetails('');
            setIsTracked(false);
            setShowTodoForm(false);
        } catch (error) {
            console.error('Error creating to-do list:', error.response ? error.response.data : error.message);
        }
    };

    // Handle task completion
    const handleTaskCompletion = async (taskId) => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/todolist/task/${taskId}`, {
                completed: true,
            });
            setTodoLists(prev => prev.map(list => ({
                ...list,
                tasks: list.tasks.map(task => (task.id === taskId ? response.data : task)),
            })));
        } catch (error) {
            console.error('Error updating task completion:', error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!listName || !startDate || !assignedUser) {
            alert('Please fill in all the required fields.');
            return;
        }
        console.log("Task added with start date:", startDate, "and end date:", endDate);
        // handle the actual submission here
    };
    // const toolbarOptions = [
    //     ['bold', 'italic', 'strike'], // Formatting options
    //     [{ list: 'ordered' }, { list: 'bullet' }], // Ordered and unordered lists
    //     ['link'], // Link option
    //     [{ 'color': [] }, { 'background': [] }],
    //     ['blockquote', 'code-block'],
    //     ['clean']
    // ];
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'], // Text formatting
            ['link'], // Links
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
            [{ 'align': [] }], // Alignments
            ['blockquote', 'code-block'], // Blockquotes & code blocks
            ['clean'], // Remove formatting
            ['undo', 'redo'] // Undo/Redo
        ],
    };
    return (
        <div className="campfire">
            <div className="text-center d-block m-auto campfire card--head camp" style={{ width: '65vw', height: '6vh' }}>
                <div className="d-flex justify-content-center align-items-center mt-2">
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className="me-1" icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <Link to="/project" className="fw-bold">Webnexs HQ</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="card text-center" style={{ width: '75vw' }}>
                    <div className="card bg-dark campfireBody" >
                        <div className="container">
                            <div className="d-flex justify-content-between">
                                <button className="newEvent px-1 mt-4 py-1 text-dark" onClick={() => setShowTodoForm(true)}>
                                    <FaPlus className='me-1' /> New list
                                </button>
                                <div className="text-center text-light mt-3">
                                    <h3 className="mb-0 text-light">To-dos</h3>
                                    <RiPieChartFill className='text-success ms-2 fs-3' /> 0/4
                                </div>
                                <div className="text-end">
                                    <button className="Notifybtn text-light" onClick={openNav}>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </button>
                                </div>
                            </div>
                            <hr className="line" />
                        </div>

                        {showTodoForm && (
                            <div className="text-center">
                                <div className="todocontainer">
                                    <input
                                        type="text"
                                        placeholder="Name this list..."
                                        value={listName}
                                        onChange={(e) => setListName(e.target.value)}
                                        className="todoinput"
                                    />
                                    <hr className=" m-0" />
                                    {/* <textarea
                                        placeholder="Add extra details or attach a file..."
                                        value={details}
                                        onChange={(e) => setDetails(e.target.value)}
                                        className="todotextarea"
                                    /> */}
                                    <ReactQuill
                                        className='text-start text-light'
                                        placeholder="Add extra details or attach a file..."
                                        value={details} // This is the editor's content
                                        onChange={(content) => setDetails(content)} // Quill's onChange provides the content directly
                                        modules={modules}
                                        theme="snow"
                                    />
                                    <div className="todoactions">
                                        <div>
                                            <button onClick={handleAddList} className="todoadd-button me-4">Add this list</button>
                                            <button className="todocancel-button" onClick={() => setShowTodoForm(false)}>Cancel</button>
                                        </div>
                                        <label className="todocheckbox-label">
                                            <input
                                                type="checkbox"
                                                checked={isTracked}
                                                onChange={(e) => setIsTracked(e.target.checked)}
                                                className="todocheckbox me-2"
                                            />
                                            Track this list on the Hill Chart
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Todo list display */}
                        <div className='d-flex justify-content-center'>
                            <div className="todo-list-container p-3 text-start text-light">
                                {todoLists.map((list, index) => (
                                    <div key={index} className="todo-item">
                                        <div className="rxHamburgerMenu">
                                            <RxHamburgerMenu /><h4 className="fw-bold">{list.title}</h4>
                                        </div>
                                        <div>
                                        <div className="rxHamburgerMenu">
                                            <RxHamburgerMenu /><p>{list.description}</p>
                                        </div>
                                        </div>
                                        <div className="todo-tasks">
                                            {list.tasks.map((task, i) => (
                                                <div key={i} className="todo-task">
                                                    <input
                                                        type="checkbox"
                                                        checked={task.completed}
                                                        onChange={() => handleTaskCompletion(task.id)}
                                                        className="todocheckbox task-checkbox"
                                                    />
                                                    <span className="task-text">{task.name}</span>
                                                </div>
                                            ))}
                                        </div>
                                        <button className="add-task-btn ms-4">Add a to-do</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* Sidebar for settings */}
                        <div id="mySidenav" className={`CampfireSidenav messageboardSidenav ${isOpen ? 'open' : ''}`}>
                            <span className="closebtn" onClick={closeNav}>&times;</span>
                            <div className="P-2">
                                <Link to="#" className='sideBar text-decoration-none'>
                                    <button className='me-2 sidebarBtn'><FaPlus /> Bookmark</button>
                                </Link>
                            </div>
                            <div className="P-2">
                                <Link to="#" className='sideBar text-decoration-none'>
                                    <button className='me-2 sidebarBtn'><FaPlus /> Get a public link</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ToDos;
