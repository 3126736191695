import { useEffect, useState } from "react";
import ProfileCircles from "../ProfileCircles";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faScroll } from "@fortawesome/free-solid-svg-icons";

const MessageBoardShow = () => {
  const [messages, setMessages] = useState([]);
  const location = useLocation();
  const projectData = location.state?.projectData;

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/messages`);
        if (!response.ok) {
          throw new Error('Failed to fetch messages');
        }
        const data = await response.json();
        setMessages(data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, []);

  return (
    // <div className="cardbox ">
    //   <h2 className="card-title text-white fw-bold fs-5 text-center py-2">Message Board</h2>
    //   <div className="card-body px-3 pt-2 overflow-hidden project-in-card">
    //     {messages.map((message) => (
    //       <div className="card-item pb-1" key={message._id}>
    //         <div className="item-header d-flex">
    //           {/* <img alt="profilePic" src={message.profilePic} className="profile-pic" /> */}
    //           {projectData?.members && (
    //             <ProfileCircles className="circles" accounts={projectData.members} />
    //           )}
    //           <strong className="item-title pt-1 ps-2">{message.title}</strong>
    //         </div>
    //         <p className="item-paragraph text-white">
    //           {message.content ? `${message.content.substring(0, 35)}...` : 'No content available'}
    //         </p>
    //         <hr className="textLine m-0" />
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <div className="cardbox">
      <h2 className="card-title fw-bold fs-5 text-center py-2 t-color1">
        Message Board
      </h2>
      <div className="card-body px-3 pt-2 overflow-hidden project-in-card">
        <div className="card-item">
          <div className="d-flex justify-content-center pt-3">
            <div className="calendar-icon bg-warning" >
              <FontAwesomeIcon icon={faScroll} size="3x" />
            </div>
          </div>
          <p className="fw-normal fs-6 text-secondary text-center">Post announcements, pitch ideas, and keep discussions on-topic.</p>
        </div>
      </div>
    </div>
  )
}

export default MessageBoardShow;
