import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faNoteSticky, faCalendarWeek } from '@fortawesome/free-solid-svg-icons';

const UnassignedWork = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleDdboxClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <div className='UnassignedWork container p-3 w-75 secondary-class'>
      <div className='d-flex justify-content-center align-item-center fw-bold h2 pt-2'>
        <p>Unassigned work</p>
      </div>
      <hr />
      <div className='m-2 ddbox ps-5 pe-5 pt-2 pb-2' onClick={handleDdboxClick}>
        <p className='h4 fw-bold ddcont'><FontAwesomeIcon className={`pe-2 caret-right ${isClicked ? 'caret-down' : ''}`} icon={faCaretRight} /> webnexs HQ</p>
        <span className=' ps-4 ddcont fs-6'>Company-wide announcements and stuff everyone needs to know.</span>
      </div>

      {isClicked && (
        <div className=' ps-5 clickcontent '>
          <p className='fw-bold ps-5'>To-dos: Test Webnexs website</p>
          <div className='task ps-5 d-flex'>
            <input type='checkbox' />
            <span className='title'>Speed Analysis</span>
            <button className='notes'><FontAwesomeIcon icon={faNoteSticky} /></button>
          </div>
          <div className='task ps-5 d-flex'>
            <input type='checkbox' />
            <span className='title'>Complete testing webnexs website weekly once on regular basis</span>
            <button className='notes'><FontAwesomeIcon icon={faNoteSticky} /> </button>
          </div>
          <div className='ps-5'>
            <span className='date ps-4'><FontAwesomeIcon icon={faCalendarWeek} /> Tue, Mar 5, 2019 - Oct 11, 2019</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default UnassignedWork