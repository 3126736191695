import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ isSignedIn, children }) {
  // If the user is not signed in, redirect to the login page
  if (!isSignedIn) {
    return <Navigate to="/" replace />;
  }

  // If the user is authenticated, render the children components
  return children;
}

export default ProtectedRoute;
