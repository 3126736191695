import React, { useState } from 'react'
import { RiKey2Fill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const ChangePings = () => {
    const [isPingsEnabled, setIsPingsEnabled] = useState(true);

    const handleToggle = () => {
        setIsPingsEnabled(!isPingsEnabled);
    };

    const handleSaveChanges = () => {
        // Implement save changes functionality
        console.log('Changes saved');
    };

    const handleNeverMind = () => {
        // Implement never mind functionality
        console.log('Changes discarded');
    };
    return (
        <div className='campfire todo-contain'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <Link to="/adminland" className="fw-bold"><RiKey2Fill style={{
                        fontSize: "20"
                    }} />Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card " style={{ width: '75vw' }}>
                    <div className="card secondary-class campfireBody">
                        <div className="container ">
                            <div className=" text-center mt-3">
                                <a href="pings" className='headcamp fw-bold'> <h3 className="mb-0 ">Change who can send pings</h3> </a>
                            </div>
                            <hr className="line" />
                        </div>
                        <div className="card-body secondary-class p-0" style={{ height: '80vh', }}>
                            <div className="admin-panel ">
                                <div className="admin-list">
                                </div>
                                <div className="change-pings-container">
                                    <p>
                                        Keep conversations within projects by limiting which companies can send pings.
                                        Turning off pings removes the option for people in the company and anyone added
                                        to the company in the future.
                                    </p>
                                    <div className="company-toggle">
                                        <span className='fw-bold'>TemNex</span>
                                        <div className="toggle-container">
                                            <label className="switch">
                                                <span className={`toggle-label ${isPingsEnabled ? 'enabled' : ''}`}>On</span>
                                                <input
                                                    className='inputPings'
                                                    type="checkbox"
                                                    checked={isPingsEnabled}
                                                    onChange={handleToggle}
                                                />
                                                <span className="slider"></span>
                                                <span className={`toggle-label ${!isPingsEnabled ? 'enabled' : ''}`}>Off</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="pingsbutton">
                                        <button className="save-button me-4" onClick={handleSaveChanges}>Save changes</button>
                                        <button className="cancel-button" onClick={handleNeverMind}>Never mind</button>
                                    </div>
                                    <p className="note">
                                        Note: If a person has sent pings previously when pings are turned off, they'll no longer be able to access them.
                                        You may want to let them know about the change beforehand.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePings
