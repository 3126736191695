import React from 'react';
import Clock from 'react-live-clock';
const ProfileCard = ({ account, onClose, getInitials }) => {
    return (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">

                    <div className="modal-body">
                        <button type="button" className="close btn-close profileCloseBtn" onClick={onClose} aria-label="Close">
                        </button>
                        {account.profilePic ? (
                            <img src={account.profilePic} alt={account.username} className="img-fluid    profilePic" />
                        ) : (
                            <span className="profile-circle profilePic profile-circle-card">{getInitials(account.username)}</span>
                        )}
                        <h5 className="mt-2">{account.username}</h5>
                        <p className='profile-card-para'>{account.role} at </p>
                        <p className='profile-card-para'>{account.companyName}</p>
                        <p className='profile-card-para'>{account.location}</p>
                        <p><Clock
                            format={'h:mm:a'}
                        /> IST</p>
                    </div>
                    <div className="modal-footer">
                        <div className="btn-group profileBtnGroup text-dark">
                            <a href="#" className=" rounded text-decoration-none profileBtnActive" aria-current="page">Ping {account.username}</a>
                            <a href="#" className=" text-dark rounded profileHoverBtn text-decoration-none profileBtn-card">See what I've been up to</a>
                            <a href="#" className=" text-dark rounded profileHoverBtn text-decoration-none profileBtn-card">Edit my profile</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProfileCard;
