import React, { useState } from 'react';
import { LuKey } from 'react-icons/lu';
import { Link } from 'react-router-dom';
// import { Form } from 'react-bootstrap';

const Ping_And_Chat_History = () => {
  // State to keep track of the selected button
  const [selectedPingDuration, setSelectedPingDuration] = useState('Forever');
  const [selectedChatDuration, setSelectedChatDuration] = useState('Forever');

  // Function to handle button click
  const handlePingDurationClick = (duration) => {
    setSelectedPingDuration(duration);
  };

  const handleChatDurationClick = (duration) => {
    setSelectedChatDuration(duration);
  };

  // Styles for selected and unselected buttons
  const buttonStyle = {
    selected: {
      backgroundColor: '#ae9b85',
      color: 'black',
    },
    unselected: {
      backgroundColor: '#e5e5e5',
      color: 'black',
    },
  };

  return (
    <div className='Ping_Chat_History todo-contain'>
      <div className='text-center d-block m-auto card--head secondary-class' style={{ width: '65vw', height: '6vh' }}>
        <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
          <Link to='/adminland' className='m-2 fw-bold'>
            <LuKey /> Adminland
          </Link>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center'>
        <div className='body-bottom text-center secondary-class b-shad' style={{ width: '75vw' }}>
          <div className='container p-3'>
            <div className=''>
              <p className='h2 fw-bold pt-3'>Change Ping & Chat history settings</p>
            </div>
            <hr />
            <div className='body-content mx-auto'>
              <div className='text-center'>
                <p className='text-start'>
                  By default, TeamNex keeps browseable, searchable transcripts of all Pings and Chats for as long as you keep the
                  account.
                </p>
                <p className='text-start pt-2'>
                  Here you can change that setting for this account so that TeamNex automatically and permanently deletes chats
                  (including any attachments) after a certain amount of time.
                </p>

                <div className='content-between-lines'>
                  <hr className='linee' />
                  <span className='textt fw-bold'>How long should TeamNex keep chat history…</span>
                  <hr className='linee' />
                </div>
                <div className='boxes d-flex'>
                  <div className='col-lg-6 col-md-6 col-sm-12 m-2 p-3 bg-clrr'>
                    <p className='fw-bold pt-2'>…in Pings?</p>
                    <p className='px-3'>
                      Pings are private conversations between individuals. Only the participants can see the history.
                    </p>
                    <div className='d-flex flex-column'>
                      {['Forever', '30 days', '90 days', '1 Year', '2 Year'].map((duration) => (
                        <button
                          key={duration}
                          className='w-75 d-block m-auto mt-2 py-2 clkbtn'
                          onClick={() => handlePingDurationClick(duration)}
                          style={selectedPingDuration === duration ? buttonStyle.selected : buttonStyle.unselected}
                        >
                          {duration}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12 m-2 p-3 bg-clrr'>
                    <p className='fw-bold pt-2'>…in Chats?</p>
                    <p className='px-3'>
                      Chats are real-time group conversations inside projects. Everyone in the project can see the history.
                    </p>
                    <div className='d-flex flex-column'>
                      {['Forever', '30 days', '90 days', '1 Year', '2 Year'].map((duration) => (
                        <button
                          key={duration}
                          className='w-75 d-block m-auto mt-2 py-2 clkbtn'
                          onClick={() => handleChatDurationClick(duration)}
                          style={selectedChatDuration === duration ? buttonStyle.selected : buttonStyle.unselected}
                        >
                          {duration}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                <p className='btm-txt my-2'>Use caution. If you change these settings, any existing chats older than what you select above will be permanently and immediately deleted across the account. That can’t be undone.</p>
                <div className='d-flex justify-content-center'>
                  <button className='p-2 mx-1 bttn-1'>Review Changes...</button>
                  <button className='p-2 mx-1 bttn-2'>Never mind</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ping_And_Chat_History;
