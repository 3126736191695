import { TiMessages } from "react-icons/ti";
import { useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import axios from "axios";
import socket from '../../socket/socket'; // Adjust this path as needed

const CampfireShow = ({ data }) => {
  const items = data || [];
  const location = useLocation();
  const projectData = location.state?.projectData;
  const { id: projectId } = useParams();
  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const token = userData.token;
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/project/${projectId}/messages`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const latestMessages = response.data.slice(-3); // Get last 4 messages
        setMessages(latestMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();

    if (projectId) {
      socket.emit('joinProjectChat', projectId);
      console.log(`Joined project chat room: ${projectId}`);
    }

    socket.on('receiveProjectMessage', (newMessage) => {
      setMessages((prevMessages) => [...prevMessages.slice(-3), newMessage]);
    });

    socket.on('messageDeleted', ({ messageId }) => {
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg._id !== messageId)
      );
      console.log("Message deleted:", messageId);
    });

    return () => {
      socket.off('receiveProjectMessage');
      socket.off('messageDeleted');
    };
  }, [projectId]);

  console.log(messages);

  function getInitials(name = "") {
    if (!name) return "?";
    const initials = name
      .split(" ")
      .map(part => part.charAt(0).toUpperCase())
      .join("");
    return initials;
  }

  return (
    <div className="cardbox">
      <h2 className="card-title t-color1 fw-bold fs-5 text-center py-2">Campfire</h2>
      {messages.length === 0 ? (
        <div className="card-body px-3 pt-2 overflow-hidden project-in-card">
          <div className="card-item">
            <div className="d-flex justify-content-center pt-3">
              <div className="email-icon">
                <TiMessages style={{ fontSize: 50 }} />
              </div>
            </div>
            <p className="fw-normal fs-6 text-secondary text-center">
              Chat casually with your team, ask questions, and share news without ceremony.
            </p>
          </div>
        </div>
      ) : (
        <div className="message-container card-body px-3 pt-2 overflow-hidden project-in-card ">
          {messages.map((message) => (
            <div className="camp-show-msg mb-2">
              <div className="card-item pb-1 d-flex align-items-baseline" key={message._id}>
                {message.sender?.profilePhoto ? (
                  <img
                    alt="profilePic"
                    src={message.sender.profilePhoto}
                    className="profile-pic"
                    style={{ width: '20px', height: '20px', borderRadius: '50%' }}
                  />
                ) : (
                  <span
                    className='me-1'
                    style={{
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%',
                      backgroundColor: '#4caf50',
                      color: '#fff',
                      marginRight: '10px',
                      fontSize: '10px',
                    }}
                  >
                    {getInitials(message.sender?.username)}
                  </span>
                )}
                <div className="item-header d-flex">

                  <strong className="item-title pt-1 ps-2">{message.sender?.username || "Unknown User"}</strong>
                </div>
              </div>
              <div className="ps-4 ms-2">

                <p className="item-paragraph t-color1 m-0">
                  {/* {message.content ? `${message.content.substring(0, 35)}...` : 'No content available'} */}
                  <span className='fw-normal text-start fs-6' dangerouslySetInnerHTML={{ __html: message.content.substring(0, 35) }}></span>
                </p>
                {/* <hr className="textLine m-0" /> */}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CampfireShow;
