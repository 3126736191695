import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import toast from 'react-hot-toast';

const ForgotPass = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false); // New state variable

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/forgot-password`, { email });
            setMessage(response.data.message);
            setIsError(false); // This indicates a success message
            toast.success(response.data.message, { autoClose: 25000, })
        } catch (error) {
            setMessage(error.response.data.message);
            setIsError(true); // This indicates an error message
            toast.error(error.response.data.message)
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center  Forgot">
            <div className='Loginpage text-center' style={{ maxWidth: '448px', width: '100%' }}>
                <img src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public' alt="Logo" className="d-block m-auto" style={{ width: "100px" }} />

                <div className="card p-3 mt-4 shadow-sm">
                    <div className="card-body">
                        <h2 className="h5 mt-0 mb-2 text-center text-white fw-bold">Forgot your Password</h2>
                        <p className="mt-0 mb-3 fw-normal text-white">Enter your email address below and we’ll send you password reset instructions</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control mb-2"
                                    name="email"
                                    placeholder="Enter your email address..."
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="shadow btn-block mt-3 w-100 fw-bold loginBtn">
                                Email me reset instructions
                            </button>
                        </form>
                        {message && <p className={`mt-3 text-center fw-bold ${isError ? 'text-danger' : 'text-success'}`}>{message}</p>}
                        <div className='text-white mt-4'>
                            <hr></hr>
                            <p><strong>If you don’t see your reset email</strong> be sure to check your spam filter for an email from <a href="mailto:webnexs29@gmail.com?subject=Support%20Request&body=Hi%20there,">support@TeamNex.com</a></p>
                        </div>
                    </div>

                </div>
                <p className="text-center mt-3">
                    <Link to="/">Never mind, Go Back</Link>
                </p>
            </div>
        </div>
    );
};

export default ForgotPass;
