import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function InviteExist() {
    const navigate = useNavigate()
    const location = useLocation()
    const formData = location.state?.formData || "User"

    console.log(formData)
    const organizationId = formData[0]?.organizationId
    console.log(organizationId)
    const handleNotNow = () => {
        navigate(`/${organizationId}`, { replace: true })
    }
    const fullName = formData[0]?.fullName;


    return (
        <div className="container text-white d-flex justify-content-center align-items-center min-vh-100">
            <div className="d-flex flex-column justify-content-center align-items-center text-center p-4 rounded invite-success w-100" style={{ maxWidth: "500px" }}>
                <div className="w-25 h-75 mb-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>

                </div>
                <div>
                    <h2 className="fw-bold mb-3">{fullName} has already <br />been invited to this account</h2>
                </div>
                <div>
                    <p className="mb-4">They should’ve received an email with instructions to sign in and join your Teamnex account. Since you’re an admin,<br></br> you can <a href=''>send them a link to log in.</a></p>
                </div>
                <div className="d-flex flex-column gap-2 w-100">
                    <button className="next-btn text-black text-thin" onClick={() => handleNotNow()}>I'm done</button>
                </div>
            </div>
        </div>
    )
}

export default InviteExist
