import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from "react-hot-toast";

const ProfileForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    jobTitle: '',
    location: '',
    bio: '',
    email: '',
    profilePhoto: '',
    _id:''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
  const userId = storedUserData?.user?._id;
  // Fetch user data when component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('authToken'); // Retrieve the token from local storage
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/userapi/view_user`,
          {id : userId},
          {
          headers: {
            'Authorization': `Bearer ${token}`, // Attach the token in the header
          }
        });
        // Set fetched user info to formData
        setFormData({
          username: response.data.list_user[0].username || '', // Username fetched from the API
          email: response.data.list_user[0].email || '',       // Email fetched from the API
          jobTitle: response.data.list_user[0].jobTitle || '',       // Title fetched from the API
          location: response.data.list_user[0].location || '', // Location fetched from the API
          bio: response.data.list_user.bio || '',           // Bio fetched from the API
          profilePhoto: response.data.list_user[0].profilePhoto || '', // Profile photo URL
          _id: response.data.list_user[0]._id || ''
        });
      } catch (err) {
        // Handle errors by setting the error state
        setError(err.response ? err.response.data.message : 'Something went wrong');
      } finally {
        // Reset loading state
        setLoading(false);
      }
    };

    fetchUserData();
  }, []); 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
  };

  const profileupdateSubmit = async (e) => {
    e.preventDefault(); // Prevent form reload
    setLoading(true);
    setError(null);
    setResponseMessage("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/userapi/edit_profaile`,
        formData
      );
      if (response.data.status == true) {
        toast.success(response.data.message);
      }else if(response.data.status == false){
        toast.error(response.data.message);
      }
    } catch (err) {
      setError(err.response?.data?.error || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  const getInitials = (name) => {
    if (!name) return 'AK'; // Default initials if no name is provided
    const splitName = name.trim().split(' ');
    const initials = splitName.map(part => part[0].toUpperCase()).join('');
    return initials;
  };

  return (
    <div className="container profile-form-container mt-5 d-flex justify-content-center align-items-center">
      <div style={{ width: '30rem' }}>
        <div className="card-body profileForm p-3 mt-5">
          <form onSubmit={profileupdateSubmit}>
            <div className='profileformGroup'>
              <div className="text-center mb-4">
                <div
                  className="rounded-circle pro-bg  d-inline-block"
                  style={{ width: '80px', height: '80px', lineHeight: '80px', fontSize: '36px' }}
                >
                  {formData.profilePhoto ? (
                    <img
                      src={formData.profilePhoto}
                      alt="Profile"
                      style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                    />
                  ) : (
                    getInitials(formData.username)
                  )}
                </div>
                <div className="mt-2">
                  <input
                    type="file"
                    name="profilePhoto"
                    className="photo-button "
                    id="profilePhoto"
                    onChange={handleChange}
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="profilePhoto" className="t-color1 photobutton">
                    Upload a photo...
                  </label>
                </div>
                <div className="mt-3 photobutton2 d-block m-auto w-50 fs-6 textRequired">
                  Upload a photo to show your 😊
                </div>
              </div>

              {/* Form Fields */}
              <div className="form-group t-color1">
                <label htmlFor="username" className="h5 fw-bold ">
                  Name <span className="textRequired h6">required</span>
                </label>
                <input
                  type="text"
                  className="form-control personalForm"
                  placeholder='Enter name'
                  id="username"
                   name="username"
                  onChange={handleChange}
                  value={formData.username} // Set value from state
                  required
                />
              </div>

              <div className="form-group t-color1">
                <label htmlFor="title" className="h5 fw-bold  mt-3">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control personalForm"
                  id="title"
                  name="jobTitle"
                  onChange={handleChange}
                  value={formData.jobTitle} // Set value from state
                  placeholder='Enter title'
                />
              </div>

              <div className="form-group t-color1">
                <label htmlFor="location" className="h5  fw-bold mt-3">
                  Location
                </label>
                <input
                  type="text"
                  className="form-control personalForm"
                  placeholder='Enter location'
                  name="location"
                  onChange={handleChange}
                  id="location"
                  value={formData.location} // Set value from state
                />
              </div>

              <div className="form-group t-color1">
                <label htmlFor="bio" className="h5  fw-bold mt-3">
                  Short bio
                </label>
                <input
                  type="text"
                  className="form-control personalForm"
                  placeholder='Enter bio'
                  name="bio"
                  onChange={handleChange}
                  value={formData.bio}
                  id="bio"
                />
              </div>

              <div className="form-group t-color1">
                <label htmlFor="email" className="h5  fw-bold mt-3">
                  Email address <span className="textRequired h6">required</span>
                </label>
                <input
                  type="email"
                  className="form-control personalForm"
                  placeholder='Enter email'
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={formData.email} // Set value from state
                  required
                />
              </div>

              <label htmlFor="login-info t-color1 t-color1" className="h6 fw-bold mt-3 ">
                Login info
              </label>
              <p className=" m-0 t-color1">You log in with a password.</p>
              <div className="m-0 t-color1">
                <a href="#">Set up 2FA or change your login info here.</a>
              </div>

              {/* Save Button */}
              <button type="submit" className="w-100 d-block m-auto p-2 mt-3 save-button" disabled={loading}>
                {loading ? 'Saving...' : 'Save my changes'}
              </button>
            </div>
          </form>

          {/* Error Message */}
          {error && <div className="alert alert-danger mt-3">{error}</div>}
          <hr className='line t-color1' />
          {/* Leave Webnexs Section */}
          <div className='profileformGroup t-color1'>
            <label htmlFor="login-info" className="h6 fw-bold mt-3 ">
              Want to leave the webnexs.com TeamNex account?
            </label>
            <div className="mt-1">
              <a href="#">Remove me from this account...</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
