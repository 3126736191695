import React from 'react'

const Project_Template = () => {
  return (
    <div className='Project_Template mt-5 w-75 container p-3' style={{ height: "90vh" }}>
      <div className='d-flex justify-content-center align-item-center fw-bold h2 pt-2'>
        <p>Start a new project template </p>
      </div>
      <div className='d-flex justify-content-center align-item-center pt-2 text-center'>
        <p className='w-70 projectTemplate'><strong>Tip:</strong> To make a template from an existing project, go to the project page and click “Save as a project template” from the ••• menu.</p>
      </div>
      <hr className='mt-0'/>
      <div className='d-flex justify-content-center '>
        <div className='inner-container w-75'>
          <div className="form-group">
            <label htmlFor="name" className='h5 fw-bold'>Name the Template</label>
            <input type="text" className="form-control project-ip w-100" id="name" placeholder='e.g. Marketing Campaign Template' required />
          </div>
          <div className="form-group mt-4">
            <label htmlFor="name" className='h5 fw-bold'>Name the Template</label>
            <textarea name="" id="" rows={5} className="form-control project-ip w-100 h-25" style={{ resize: 'none' }} placeholder='e.g.Kicking of new marketing campaign' ></textarea>
          </div>
          <button className='mt-4 next-btn'>Next:Set up this template &#8594;</button>
        </div>
      </div>
    </div>
  )
}

export default Project_Template