import React from 'react'

const NotfoundPage = () => {
    return (
        <div className="d-flex justify-content-center align-items-center vh-100 flex-column" >
            <div className="card text-center text-light text-start" style={{ width: '29rem', borderRadius: '10px', backgroundColor: '#1b2930' }}>
                <div className="card-body p-4 d-flex flex-column flex-start text-start">
                    <img
                        src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public'
                        alt="Logo" className=' ml-2' style={{ width: '4rem' }} />
                    <h5 className="card-title">Sorry, we don't have anything to show you on this page</h5>
                    <p className="card-text mt-4">
                        This could be because:
                        <ul className="text-start">
                            <li>The item you're looking for has been deleted</li>
                            <li>You don't have access to it</li>
                            <li>You clicked a broken link</li>
                        </ul>
                    </p>
                    <p>If you think you should have access to this page, ask the person who manages the project (or the Teamnex account) to add you to it.</p>
                </div>
            </div>
            <span className='text-white mt-4'>
                Having trouble finding something?
                <a href="#" className=""> We can help.</a>
            </span>
        </div>
    )
}

export default NotfoundPage
