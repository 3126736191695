import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../asset/Logo-g.png";
import axios from "axios";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = ({ onSignIn }) => {
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [eyePassword, setEyePassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [invalidEmail, setInvalidEmail] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear error message on input change
    if (error) setError(null);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleNextClick = () => {
    if (validateEmail(formData.email)) {
      setShowPassword(true);
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
      setError('Please enter a valid email.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, formData);

      if (!response.data.token) {
        throw new Error('No token received from server.');
      }
      console.log(response.data);
      localStorage.setItem('token', JSON.stringify(response.data.token));
      localStorage.setItem('userData', JSON.stringify(response.data));
      const senderId = response.data.user._id; // Assuming _id is the user ID
      console.log('Sender ID:', senderId);
      toast.success('Logged in successfully!');
      const userDataString = localStorage.getItem('userData');
      if (userDataString) {
        // Parse the JSON string back into an object
        // const userData = JSON.parse(userDataString);

        // Step 3: Log the userData object to the console
        // console.log(userData);
      } else {
        console.log('No user data found in localStorage.');
      }
      // const organizationId = response.data.user.organizationId._id;
      const organizationId = response.data.user?.organizationId?._id;
      if (!organizationId) {
        throw new Error("Organization ID is not available");
      }
      // Call the onSignIn function to update the state
      // const organizationId = response.data.user.organizationId._id;
      onSignIn();
      navigate(`/${organizationId}`, { state: { userData: response.data } });
    } catch (error) {
      console.error('Login error:', error);
      const errorMessage = error.response && error.response.data.message
        ? error.response.data.message
        : 'An error occurred. Please try again later.';

      toast.error(errorMessage);
      setError(errorMessage);
    } finally {
      setFormData({
        email: "",
        password: "",
      });
    }
  };

  // Google login handler
  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="Signin text-center" style={{ maxWidth: "448px", width: "100%" }}>
          <img src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public' alt="Logo" className="d-block m-auto" style={{ width: "100px" }} />
          <div className="card p-2 mt-4 shadow-sm">
            <div className="card-body">
              <h1 className="fs-5 pb-2 text-white">Log in to TeamNex</h1>
              <button onClick={handleGoogleLogin} type="submit" className="action_button">
                <img width="28" height="28" className="action_button__img" alt="Google" title="Google" src={logo1} />
                Sign in with Google
              </button>
              <Link to='/signup' className="ps-3 text-decoration-none fs-6 text-white Signup">Signup</Link>
              <div className="d-flex align-items-center my-3">
                <hr className="flex-grow-1" />
                <span className="mx-2 logEmail">Or, use my email address</span>
                <hr className="flex-grow-1" />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="label" htmlFor="email">Email or username</label>
                  <input
                    className={`form-control mb-2 ${invalidEmail ? 'is-invalid' : ''}`}
                    type="email"
                    placeholder={invalidEmail ? "Please enter a valid email" : "Your email (e.g. julie.young@hey.com)"}
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  {invalidEmail && <p className="text-danger">Please enter a valid email.</p>}
                </div>
                {!showPassword && (
                  <button type="button" className="action_button" onClick={handleNextClick}>
                    Next
                  </button>
                )}
                {showPassword && (
                  <div>
                    <div className="form-group position-relative">
                      <label className="label" htmlFor="password">Enter password</label>
                      <input
                        type={eyePassword ? 'text' : 'password'}
                        placeholder="Enter password"
                        className="form-control mb-2"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                      <span
                        className="position-absolute end-0 top-0 mt-5 me-2"
                        onClick={() => setEyePassword(!eyePassword)}
                      >
                        <FontAwesomeIcon icon={eyePassword ? faEye : faEyeSlash} />
                      </span>
                    </div>
                    <button type="submit" className="loginBtn custom-btn btn-block mt-3 w-100">
                      Login
                    </button>
                  </div>
                )}
                {error && <p className="text-danger mt-2">{error}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
      <p className="text-center pt-2 m-0 forgot-password">
        <Link to="/forgot-password">Forgot your password</Link>
      </p>
    </>
  );
};

export default Login;
