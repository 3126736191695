import React from 'react'
import { LuKey } from "react-icons/lu";
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

const PublicLink = () => {
  return (
    <div className='EditProject todo-contain'>
      <div className=' text-center d-block m-auto card--head secondary-class' style={{ width: '65vw', height: '6vh' }}>
        <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
          <Link to='/adminland' className='m-2 fw-bold'><LuKey /> Adminland</Link>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="body-bottom text-center secondary-class b-shad" style={{ width: '75vw' }}>
          <div className="container p-3">
            <div className="">
              <p className='h2 fw-bold pt-3'>Change who can turn on public links</p>
            </div>
            <hr />
            <div className='body-content w-75 mx-auto'>
              <div className=' text-center'>
                <p className='text-start'>By default, anyone who’s on a project (except clients and collaborators) can change who else is on it. They can add people, remove them, or switch someone to just following.</p>
                <p className='text-start pt-2'>Here you can change that setting so that only admins and owners can enable public links.</p>

                <p className='text-start pt-3 fw-bold'>Who can turn on public links</p>
                <Form.Check
                  type="radio"
                  id="webnexs"
                  name="role"
                  className='text-start'
                  label="Anyone on the project (except clients)"
                />
                <Form.Check
                  type="radio"
                  id="webnexs"
                  name="role"
                  className='text-start'
                  label="Only admins and owners"
                />
                <div className='d-flex text-start'>
                  <button className='p-2 mx-1 bttn-1'>Save Changes</button>
                  <button className='p-2 mx-1 bttn-2'>Never mind</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicLink