import React, { useEffect, useState } from 'react';
import { RiKey2Fill } from 'react-icons/ri';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
const CreateRole = () => {
    const navigate = useNavigate()
    const [roles, setRoles] = useState([])
    const [error, setError] = useState(null)
    const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
    const organizationId = storedUserData?.user?.organizationId._id;

    const fetchRoles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/roles/${organizationId}`);
            setRoles(response.data)
        } catch (error) {
            console.error('Error fetching roles:', error);
            setError('Failed to fetch roles. Please try again.');
        }
    }
    useEffect(() => {

        fetchRoles()
    }, [])

    const handleBack = () => {
        navigate(-1)
    }

    const handleDelete = async (roleId) => {
        if (window.confirm("Are you sure you want to delete this Role?")) {
            try {
                const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/roles/${organizationId}/${roleId}`);
                if (response.status === 200) {
                    // Remove deleted role from state
                    fetchRoles();
                    setRoles(roles.filter(role => role.id !== roleId)); // Removes the deleted role from the UI

                }
            } catch (error) {
                console.error('Error deleting role:', error);
                setError('Failed to delete role. Please try again.');
            }
        }
    };
    return (
        <div>
            <div className="campfire todo-contain">
                <div
                    className="text-center d-block m-auto campfire card--head camp"
                    style={{ width: '65vw', height: '6vh' }}
                >
                    <div className="d-flex justify-content-center align-items-center mt-2 h-100">
                        <Link onClick={handleBack} className="fw-bold">
                            <RiKey2Fill style={{ fontSize: '20px' }} /> Adminland
                        </Link>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center camp">
                    <div className="card" style={{ width: '75vw' }}>
                        <div className="campfireBody">
                            <div className="container">
                                <div className="text-center mt-3">
                                    <a href="#" className="headcamp fw-bold">
                                        <h3 className="mb-0 t-color1">Add/Remove Roles</h3>
                                    </a>
                                </div>
                                <hr className="line" />
                            </div>
                            <div className="card-body p-0 new-role-con">
                                <div className="admin-panel t-color1 ">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h3 className="t-color1">Roles</h3>
                                        <Link to="new-role">
                                            <button className="p-1 action-btn">+ Add Role</button>
                                        </Link>
                                    </div>
                                    <div className='mb-5 rounded p-3 role-tab'>

                                        {/* Headings */}
                                        <div className="d-flex justify-content-between fw-bold mb-2">
                                            <span style={{ width: '10%' }}>S.No</span>
                                            <span style={{ width: '45%' }}>Role</span>
                                            <span style={{ width: '45%', textAlign: 'center' }}>Manage Authorization</span>
                                        </div>
                                        <hr className='m-0'></hr>
                                        {/* List Items */}
                                        <div className="list-group">
                                            {roles.map((role, index) => (
                                                <div
                                                    key={role.id}
                                                    className="list-group-item d-flex justify-content-between align-items-center"
                                                >
                                                    <span style={{ width: '10%' }}>{index + 1}</span>
                                                    <span style={{ width: '45%' }}>{role.RoleName}</span>

                                                    <div style={{ width: '45%' }} className='d-flex justify-content-center'>

                                                        <button
                                                            className="btn btn-light d-flex align-items-center justify-content-center  dropdown-toggle"

                                                            type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                                                        >
                                                            <RiKey2Fill className="me-1" />
                                                        </button>
                                                        <ul class="dropdown-menu rol-tab-ul" aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <Link className='dropdown-item' to={`view-permission/${role._id}`} state={{ roleId: role._id }}>View</Link>
                                                                {/* <button className="dropdown-item" type="button">View</button> */}
                                                            </li>
                                                            <li><Link className='dropdown-item' to={`edit-permission/${role._id}`} state={{ roleId: role._id }}>Edit</Link></li>
                                                            <li><button className="dropdown-item" type="button" onClick={(e) => handleDelete(role._id)}>Delete</button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default CreateRole;
