import React from 'react'
import { TbLayoutCardsFilled } from 'react-icons/tb'

function CardTableShow() {
    return (
        <div className="cardbox">
            <h2 className="card-title  fw-bold fs-5 text-center py-2 t-color1">Card Table</h2>
            <div className="card-body px-3 pt-2 overflow-hidden project-in-card">
                <div className="card-item">
                    <div className="d-flex justify-content-center pt-3">
                        <div className="calendar-icon bg-primary">
                            <TbLayoutCardsFilled className='fs-1' />
                        </div>
                    </div>
                    <p className="fw-normal fs-6 text-secondary text-center">
                        Move cards across columns in a Kanban-like tool for process-oriented workflows.</p>
                </div>
            </div>
        </div>
    )
}

export default CardTableShow
