import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaPlus } from 'react-icons/fa';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaRegBookmark } from 'react-icons/fa6';
import { MdEdit, MdOutlineSort } from "react-icons/md";
import { GoDash } from "react-icons/go";
import ProfileCircles from "../ProfileCircles";
import { useCookies } from 'react-cookie';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import axios from "axios";

const MessageBoard = ({ currentUserId }) => {
    const [selectedCategory, setSelectedCategory] = useState('All Messages'); // Default to 'All Messages'
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    // const [projectData, setProjectData] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();
    const [cookies] = useCookies(['user']);
    const userId = cookies.projectId || currentUserId;
    const location = useLocation();
    const projectData = location.state?.projectData;
    console.log(projectData?.members)
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [showCategories, setShowCategories] = useState(false);
    const { organizationId, id: projectId } = useParams();
    const [categories, setCategories] = useState([
        { name: 'None', name: 'All messages' },
        { id: 1, name: '📢 Announcement' },
        { id: 2, name: '✨ FYI' },
        { id: 3, name: '❤️ Heartbeat' },
        { id: 4, name: '💡 Pitch' },
        { id: 5, name: '🔥 Question' },
        { id: 6, name: '👍 vanakkam' },
    ]);

    const [newCategoryName, setNewCategoryName] = useState('');
    const [selectedIcon, setSelectedIcon] = useState('❓'); // Default icon for new category
    const [isEditing, setIsEditing] = useState(null);
    const [isAdding, setIsAdding] = useState(false); // Track adding mode
    const handleBack = () => navigate(-1);
    // Predefined icons (emoji)
    const availableIcons = ['📢', '✨', '❤️', '💡', '🔥', '👍', '🎉', '🔔', '💬', '❓'];

    // Add Category with Emoji
    const addCategory = () => {
        if (newCategoryName.trim() && selectedIcon) {
            const newCategory = { id: Date.now(), name: newCategoryName, icon: selectedIcon };
            setCategories([...categories, newCategory]);
            setNewCategoryName(''); // Reset input
            setSelectedIcon('❓'); // Reset icon
            setIsAdding(false); // Exit add mode
        }
    };

    // Edit Category Function
    const editCategory = (id, updatedName) => {
        setCategories(categories.map(cat =>
            cat.id === id ? { ...cat, name: updatedName } : cat
        ));
        setIsEditing(null);
    };

    // Delete Category Function
    const deleteCategory = (id) => {
        setCategories(categories.filter(cat => cat.id !== id));
    };
    // Category options
    // const categories = [
    //     { id: 0, name: 'All Messages' },
    //     { id: 1, name: 'Announcement', icon: '📢' },
    //     { id: 2, name: 'FYI', icon: '✨' },
    //     { id: 3, name: 'Heartbeat', icon: '❤️' },
    //     { id: 4, name: 'Pitch', icon: '💡' },
    //     { id: 5, name: 'Question', icon: '🔥' },
    //     { id: 6, name: 'Vanakkam', icon: '👍' },
    // ];

    // Handle category change
    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);

    // const handleClickMessage = (messageId) => {
    //     navigate(`/message/${messageId}`);
    // };
    // Fetch messages
    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/boardmessages?organizationId=${organizationId}&projectId=${projectId}`);
                console.log(response.data)
                setMessages(response.data);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };
        fetchMessages();
    }, [projectId]);


    // Fetch project data
    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${userId}`);
                if (!response.ok) throw new Error('Failed to fetch project data');
                const project = await response.json();
                // setProjectData(project);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        if (userId) fetchProjectData();
    }, [userId]);

    function getInitials(name = "") {
        if (!name) return "?";
        const initials = name
            .split(" ")
            .map(part => part.charAt(0).toUpperCase())
            .join("");
        return initials;
    }
    // Fetch user information
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${userId}`);
                if (!response.ok) throw new Error('Failed to fetch user info');
                const user = await response.json();
                setUserInfo(user);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        if (userId) fetchUserInfo();
    }, [userId]);

    // Filter messages based on the selected category
    const filteredMessagess = selectedCategory === 'All Messages'
        ? messages // Show all messages if "All Messages" is selected
        : messages.filter(message => message.category === selectedCategory); // Show only messages matching the selected category

    const getColorFromId = (id) => {
        const colors = [
            '#1abc9c',
            '#e74c3c',
            '#3498db',
            '#9b59b6',
            '#f39c12',
            '#2ecc71',
            '#e67e22',
        ];

        if (!id) return colors[0];

        const hash = id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
        return colors[hash % colors.length];
    };
    return (
        <div className='campfire todo-contain'>
            <div className='text-center d-block m-auto campfire card--head camp' style={{ width: '65vw' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <span onClick={handleBack} className="fw-bold text-primary text-decoration-underline" style={{ cursor: 'pointer' }}>
                        {projectData?.name}
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="campfireBody text-center message-board-con" style={{ width: '75vw' }}>
                    <div className="container message-board-header">
                        <div className="d-flex justify-content-between align-items-center sub-header">
                            <div className='sub-header1'>
                                <div className="text-start ">
                                    <Link to="newMessage" className='text-decoration-none' state={{ projectData }}>
                                        <button className="newEvent px-2 py-1 t-color2 d-flex align-items-center justify-content-evenly">
                                            <FaPlus className='me-1' />New message
                                        </button>
                                    </Link>
                                </div>
                                <div className="text-center">
                                    <h3 className="mb-0 t-color1 fw-bold">Message Board</h3>
                                </div>
                            </div>
                            <div className="text-end d-flex align-items-center">
                                <select
                                    className='allMessages secondary-class'
                                    id="category-selector"
                                    value={selectedCategory}
                                    onChange={handleChange}
                                >
                                    {categories.map((category) => (
                                        <option key={category.id} value={category.name}>
                                            {category.name} {category.icon}
                                        </option>
                                    ))}
                                </select>
                                <button className="Notifybtn " onClick={openNav}>
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                </button>
                            </div>
                        </div>
                        <hr className="line my-1" />
                    </div>
                    <div className="d-flex justify-content-center">
                        <div
                            id="messages"
                            className="messagebody text-start p-4"
                            style={{ cursor: 'pointer' }}
                        >
                            {messages.length === 0 ? (
                                <div className="camp-demo-card">
                                    <div className="card card-custom text-secondary  ">
                                        <div className="card-body text-center">
                                            <div className="d-flex justify-content-center align-items-center mb-3">
                                                <i className="bi bi-chat-left-text-fill fs-2"></i>
                                                <span className="fs-5 text-secondary fw-bold">
                                                    No messages just yet
                                                </span>
                                            </div>
                                            <p className="start-text fw-bold text-secondary">
                                                Post announcement ,pitch ideas, and gather feedback while keeping discussions on-topic.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            ) : (
                                messages
                                    .slice()  // Create a shallow copy of the array to avoid mutating the original array
                                    .reverse()
                                    .map((message) => {
                                        // Find the author based on authorId in projectData.members
                                        const sender = projectData?.members?.find(member => member.id === message.username);

                                        return (
                                            <Link
                                                key={message._id}
                                                className="messageBoard text-decoration-none"
                                                to={{
                                                    pathname: `message/${message._id}`,
                                                }}
                                            >
                                                <div className='d-flex message-list-con align-items-center'>

                                                    <div className="profileCircles">
                                                        {message?.sender?.profilePhoto ? (
                                                            <>
                                                                <img
                                                                    src={`${process.env.REACT_APP_API_URL}${sender.profilePhoto}`}
                                                                    alt={`${sender.username}'s profile`}
                                                                    style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                                                                />

                                                            </>
                                                        ) : (
                                                            <span
                                                                className='me-1'
                                                                style={{
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    borderRadius: '50%',
                                                                    // backgroundColor: "red",
                                                                    backgroundColor: getColorFromId(message.sender?._id),
                                                                    color: '#fff',
                                                                    marginRight: '10px',
                                                                    fontSize: '25px',
                                                                    fontWeight: '500'
                                                                }}
                                                            >
                                                                {getInitials(message.sender?.username)}
                                                            </span>
                                                        )
                                                        }
                                                    </div>
                                                    <div className="d-flex flex-column ms-2 w-100">
                                                        <h4 className="t-color1 m-0">{message.title}</h4>
                                                        <p className="m-0 message-content">
                                                            <span className="pfusername">
                                                                {message.category ? `${message.category} by ` : ""}
                                                                {message.sender?.username}  {'• '}
                                                                {new Date(message.createdDate).toLocaleDateString('en-US', {
                                                                    month: 'long',
                                                                    day: 'numeric',
                                                                })}
                                                                <GoDash />
                                                                <span dangerouslySetInnerHTML={{ __html: message.content }} className="t-color1 message-board-content" ></span>
                                                            </span>
                                                        </p>


                                                    </div>
                                                </div>

                                                <hr className="line m-2 " />
                                            </Link>

                                        );
                                    })
                            )}
                        </div>
                        <div>
                            {/* Sidebar Navigation */}
                            <div className="text-start msg-side">
                                <div id="mySidenav" className={`CampfireSidenav messageboardSidenav ${isOpen ? 'open' : ''}`}>
                                    <span className="closebtn" onClick={closeNav}>&times;</span>
                                    <div className="P-2">
                                        <Link to="#" className="sideBar text-decoration-none">
                                            <button className="me-2 sidebarBtn"><FaRegBookmark /></button>Bookmark
                                        </Link>
                                    </div>
                                    <div className="P-2">
                                        <Link to="#" className="sideBar text-decoration-none">
                                            <button className="me-2 sidebarBtn"><MdOutlineSort /></button>Sort messages by...
                                        </Link>
                                    </div>
                                    <div className="P-2">
                                        <span className="sideBar text-decoration-none" onClick={() => setShowCategories(!showCategories)}>
                                            <button className="me-2 sidebarBtn"><MdEdit /></button>Edit categories...
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* Message Board Categories */}
                            {showCategories && (
                                <div className="message-board-categories">
                                    <h3>Message Board Categories</h3>
                                    <p>Set up categories for this Message Board below.</p>
                                    <ul>
                                        {categories.map(category => (
                                            <li key={category.id}>
                                                <span>{category.icon}</span>
                                                {isEditing === category.id ? (
                                                    <input
                                                        type="text"
                                                        value={category.name}
                                                        onChange={(e) => editCategory(category.id, e.target.value)}
                                                    />
                                                ) : (
                                                    <span>{category.name}</span>
                                                )}
                                                {isEditing === category.id ? (
                                                    <button onClick={() => editCategory(category.id, category.name)}>✔</button>
                                                ) : (
                                                    <>
                                                        <button className="me-2" onClick={() => setIsEditing(category.id)}><FaEdit /></button>
                                                        <button onClick={() => deleteCategory(category.id)}><FaTrashAlt /></button>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ul>

                                    {/* Add Category Section */}
                                    {isAdding ? (
                                        <div className="add-category">
                                            <input
                                                type="text"
                                                placeholder="Name it..."
                                                value={newCategoryName}
                                                onChange={(e) => setNewCategoryName(e.target.value)}
                                            />
                                            <div className="icon-selection">
                                                <p>Select an icon:</p>
                                                {availableIcons.map(icon => (
                                                    <span
                                                        key={icon}
                                                        onClick={() => setSelectedIcon(icon)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: '1.5rem',
                                                            margin: '0 5px',
                                                            border: selectedIcon === icon ? '2px solid blue' : 'none',
                                                        }}
                                                    >
                                                        {icon}
                                                    </span>
                                                ))}
                                            </div>
                                            <button onClick={addCategory}>✔</button>
                                            <button onClick={() => setIsAdding(false)}>✖</button>
                                        </div>
                                    ) : (
                                        <button className="add-category-btn" onClick={() => setIsAdding(true)}>
                                            Add a category
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default MessageBoard;
