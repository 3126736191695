import React from 'react'
import { Link } from 'react-router-dom'
import { LuKey } from "react-icons/lu";

const ProjectAccess = () => {
    return (
        <div className='MergePeople todo-contain'>
            <div className=' text-center d-block m-auto card--head secondary-class' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <Link to='/adminland' className='m-2 fw-bold'><LuKey /> Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="body-bottom text-center secondary-class b-shad" style={{ width: '75vw' }}>
                    <div className="container ">
                        <div className="">
                            <p className='h2 fw-bold pt-3'>Access any project</p>
                        </div>
                        <hr />
                        <div className='body-content'>
                            <p className='px-5 pt-3 text-start'>As the owner, you can access any project on this account — even if you weren’t specifically invited to it. But, it looks like you already have access to everything!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectAccess