import './App.css';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import Header from './components/Header';
import Signup from './components/Authentication/Signup';
import Login from './components/Authentication/Login';
import Campfire from './components/ProjectPage/Campfire';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import ForgotPass from './components/Authentication/ForgotPass';
import { useState, useEffect, useContext } from 'react';
import { Toaster } from 'react-hot-toast';
import HomeNav from './components/HomePageComponents/HomeNav';
import ResetPassword from './components/Authentication/ResetPassword';
import ProjectCard from './components/ProjectPage/ProjectCard';
import Createproject from './components/HomePageComponents/Createproject';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import AddEmployees from './components/AddEmployees.js';
import Preferences from './components/Profile-Dropdown/Preferences.js';
import OutOfOffice from './components/Profile-Dropdown/OutOfOffice.js';
import MessageBoard from './components/ProjectPage/MessageBoard/MessageBoard.js';
import Adminland from './components/HomePageComponents/Adminland.js';

import PersonalCard from './components/Profile-Dropdown/PersonalCard.js'
import MyDevices from './components/Profile-Dropdown/MyDevices';
import NotificationSetting from './components/Profile-Dropdown/NotificationSetting.js';
import ProjectSchedule from './components/ProjectPage/ProjectSchedule.js';
import ToDos from './components/ProjectPage/To-Dos.js';
import DocsFiles from './components/ProjectPage/Docs&Files.js';
import Autocheckin from './components/ProjectPage/Autocheckin.js';
import EmailForward from './components/ProjectPage/EmailForward.js';
import CardTable from './components/ProjectPage/CardTable.js';
import NewMessage from './components/ProjectPage/MessageBoard/NewMessage.js';
import SetUpPeople from './components/ProjectPage/SetUpPeople.js';

import Assignment from './components/MyStuff/Assignment.js';
import Bookmark from './components/MyStuff/Bookmark.js';
import Schedule from './components/MyStuff/Schedule.js';
import Boosts from './components/MyStuff/Boosts.js';
import Drafts from './components/MyStuff/Drafts.js';

import Activity from './components/MyStuff/Recent_Activity/Activity.js';
import Latest_activity from './components/MyStuff/Recent_Activity/Latest_activity.js';
import Someones_Activity from './components/MyStuff/Recent_Activity/Someones_Activity.js';
import Someones_Assignment from './components/MyStuff/Recent_Activity/Someones_Assignment.js';
import Mission_control from './components/MyStuff/Recent_Activity/Mission_control.js';
import To_dos from './components/MyStuff/Recent_Activity/To_dos.js';
import UnassignedWork from './components/MyStuff/Recent_Activity/UnassignedWork.js';
import UpcomingDates from './components/MyStuff/Recent_Activity/UpcomingDates.js';
import TimeSheet from './components/MyStuff/Recent_Activity/TimeSheet.js';
import Overdue from './components/MyStuff/Recent_Activity/Overdue.js';

import View_All_List from './components/HomePageComponents/View_All_List.js';
import AutocheckinNewQuestion from './components/ProjectPage/AutocheckinNewQuestion.js';

import Invite_People from './components/HomePageComponents/InvitePeople.js';
import Coworkers_Account from './components/HomePageComponents/Coworkers_Account.js';
import Invite_Link from './components/HomePageComponents/Invite_Link.js';
import Invitation_Identifier from './components/HomePageComponents/Invitation_Identifier.js';

import View_Template from './components/HomePageComponents/ViewTemp/View_Template.js';
import Project_Template from './components/HomePageComponents/ViewTemp/Project_Template.js';
import ToDo_List_Template from './components/HomePageComponents/ViewTemp/ToDo_List_Template.js';
import JustFollow from './components/ProjectPage/JustFollow.js';
import ProjectSettings from './components/ProjectPage/ProjectSettings.js';
import Administrators from './components/HomePageComponents/Adminland/Administrators.js';
import Changecontent from './components/HomePageComponents/Adminland/Changecontent.js';
import RenameProjectTools from './components/HomePageComponents/Adminland/RenameProjectTools.js';
import ChangeMessageCategories from './components/HomePageComponents/Adminland/ChangeMessageCategories.js';
import ChangePings from './components/HomePageComponents/Adminland/ChangePings.js';
import EditProjectSettings from './components/HomePageComponents/Adminland/EditProjectSettings.js';
import PlanSelection from './components/HomePageComponents/Adminland/PlanSelection.js';
import ManagePublicItems from './components/HomePageComponents/Adminland/ManagePublicItems.js';
import CancelAccount from './components/HomePageComponents/Adminland/CancelAccount.js';


import MergePeople from './components/HomePageComponents/Adminland/MergePeople.js';
import MoveProject from './components/HomePageComponents/Adminland/MoveProject.js';
import ManageGroups from './components/HomePageComponents/Adminland/ManageGroups.js';
import ManageCompanies from './components/HomePageComponents/Adminland/ManageCompanies.js';
import Archive_And_Delete_Project from './components/HomePageComponents/Adminland/Archive_And_Delete_Project.js';
import EditProject from './components/HomePageComponents/Adminland/EditProject.js';
import PublicLink from './components/HomePageComponents/Adminland/PublicLink.js';
import Two_Factor_Authentication from './components/HomePageComponents/Adminland/Two_Factor_Authentication.js';
import Ping_And_Chat_History from './components/HomePageComponents/Adminland/Ping_And_Chat_History.js';
import Add_Remove_Acc from './components/HomePageComponents/Adminland/Add_Remove_Acc.js';
import Account_Rename from './components/HomePageComponents/Adminland/Account_Rename.js';
import Trash from './components/HomePageComponents/Adminland/Trash.js';
import Reassignment from './components/HomePageComponents/Adminland/Reassignment.js';
import ProjectAccess from './components/HomePageComponents/Adminland/ProjectAccess.js';
import Export_Data from './components/HomePageComponents/Adminland/Export_Data.js';
import QuickAccess from './components/HomePageComponents/QuickAccess.js';
import MessageShow from './components/ProjectPage/MessageBoard/MessageShow.js';
import ManageStorage from './components/HomePageComponents/Adminland/ManageStorage.js';
import AddPeopleToProject from './components/ProjectPage/AddPeopleToProject.js';
import PrivateChatinPing from './components/HomePageComponents/Ping/PrivateChatinPing.js';
import NotfoundPage from './components/NotfoundPage.js';
import ProtectedRoute from './Auth/ProtectedRoute.js';
import SubscriberList from './components/ProjectPage/MessageBoard/SubscriberList.js';
import InviteSuccess from './components/HomePageComponents/InviteUser/InviteSuccess.js';
import InviteExist from './components/HomePageComponents/InviteUser/InviteExist.js';
import InviteRegister from './components/HomePageComponents/InviteUser/InviteRegister.js';
import InviteExistLogin from './components/HomePageComponents/InviteUser/InviteExistLogin.js';
import InviteNewuser from './components/HomePageComponents/InviteUser/InviteNewuser.js';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import BodyStyleManager from './components/BodyStyleManager.js';
import CreateRole from './components/HomePageComponents/Adminland/CreateRole.js';
import SetPermission from './components/HomePageComponents/Adminland/SetPermission.js';
import ViewPermission from './components/HomePageComponents/Adminland/ViewPermission.js';
import EditPermission from './components/HomePageComponents/Adminland/EditPermission.js';
import Adminland1 from './components/HomePageComponents/Adminland1.js';
import { UserContext, UserProvider } from './context/userContext.js';
import VerifyMail from './components/Authentication/VerifyMail.js';
import InvitationHandler from './components/HomePageComponents/InviteUser/InviteHandler.js';
import SelectOrganization from './Auth/SelectOrganization.js';
import { jwtDecode } from 'jwt-decode';
import Loader from './Loader.js';

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  // console.log(isSignedIn)
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const navigate = useNavigate();
  const [userPreferences, setUserPreferences] = useState(null);
  const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
  // const userId = storedUserData?.user?._id;
  const [token, setToken] = useState(null);
  // const { organizationId } = useParams()
  const [data, setData] = useState(null)
  const userId = data?._id
  // console.log(organizationId)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
    }
  }, [token]);
  const signin = (token) => {
    setIsSignedIn(true);
    setCookie('token', token, { path: '/' });
    // console.log(token)
    const decodedToken = jwtDecode(token);
    setData(decodedToken)
    localStorage.setItem('token', token);
    // console.log(decodedToken)
  };

  const signout = () => {
    console.log('Logging out...');
    setIsSignedIn(false);
    setUserPreferences(null)
    document.documentElement.style.removeProperty('--theme-color');
    document.documentElement.removeAttribute('data-appearance');
    removeCookie('token', { path: '/' });
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    navigate('/', { replace: true });
    window.history.replaceState({}, '', '/');
    localStorage.clear()
  };
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
      const decodedToken = jwtDecode(savedToken);
      setData(decodedToken)
    } else {
      console.log('No valid userData found in localStorage');
    }
  }, []);

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      const decodedToken = jwtDecode(savedToken);
      const currentTime = Date.now() / 1000;  // Get current time in seconds
      // setData(decodedToken)
      // Check if the token is expired
      if (decodedToken.exp < currentTime) {
        // Token is expired
        signout(); // Automatically log out the user
      } else {
        setToken(savedToken);  // Set token if it's valid
      }
    } else {
      console.log('No valid userData found in localStorage');
    }
  }, []);
  const [userData, setUserData] = useState(null);

  const [rolePermissions, setRolePermissions] = useState(null);
  useEffect(() => {
    if (!userId) return;

    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/role-permissions/${userId}`);
        setRolePermissions(response.data);
      } catch (err) {
        console.error("Error fetching role permissions", err);
      }
    };

    fetchRoles();
  }, [userId]);


  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      try {
        const parsedUserData = JSON.parse(storedUserData);
        setUserData(parsedUserData);
      } catch (error) {
        console.error("Error parsing userData:", error);
      }
    } else {
      console.log("No valid userData found in localStorage");
    }
  }, [isSignedIn]);

  const organizationId = data?.organizationId



  useEffect(() => {

    if (userId && organizationId) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/get-preference`, { params: { userId, organizationId } })
        .then(response => {
          setUserPreferences(response.data.preferences);
          const { colorTheme, appearanceMode } = response.data.preferences;
          document.documentElement.style.setProperty('--theme-color', colorTheme);
          document.documentElement.setAttribute('data-appearance', appearanceMode);
          console.log("getting themes ", response.data.preferences)
        })
        .catch(error => console.log('Error fetching preferences:', error));

    }
  }, [organizationId, userId]);
  const location = useLocation();

  // const excludePaths = ["/:organizationId/accept-invite/:token", "/:organizationId/accept-invite/:token/login", "/:organizationId/accept-invite/:token/new-login", "/reset-password/:token"];
  // const shouldShowHomeNav = isSignedIn && !excludePaths.some(path => location.pathname.startsWith(path.replace(":token", "")));

  const excludePaths = [
    "/:organizationId/accept-invite/:token",
    "/:organizationId/accept-invite/:token/login",
    "/:organizationId/accept-invite/:token/new-login",
    "/reset-password/:token",
    "/login",
    "/select-organization"
  ];

  const doesPathMatch = (currentPath, templatePath) => {
    const currentSegments = currentPath.split("/").filter(Boolean);
    const templateSegments = templatePath.split("/").filter(Boolean);

    if (currentSegments.length !== templateSegments.length) {
      return false;
    }

    return templateSegments.every((segment, index) => {
      return segment.startsWith(":") || segment === currentSegments[index];
    });
  };

  const shouldShowHomeNav = isSignedIn && !excludePaths.some(path => doesPathMatch(location.pathname, path));
  const { user, error } = useContext(UserContext)


  return (
    <>
      {/* {isSignedIn ? (
        <HomeNav onLogout={signout} userData={userData} />
      ) : (
        <Header />
      )} */}
      {shouldShowHomeNav ? (
        <HomeNav onLogout={signout} userData={userData} />
      ) : (
        <Header />
      )}

      <Toaster />

      <BodyStyleManager />

      <Routes>

        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/select-organization" element={<SelectOrganization onSignIn={signin} />} />
        {/* <UserProvider> */}

        {data ?
          <>
            <Route path="/" element={isSignedIn ? <Navigate to={`/${organizationId}`} /> : <Login onSignIn={signin} />} />
            <Route path='/:organizationId' element={<Home userData={userData} />} />
            <Route path='/verify-email/:verifyId' element={<VerifyMail />} />
            <Route path="/:organizationId/adminland" element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                {rolePermissions?.pageAuth?.Adminland === "Allow" ? < Adminland /> : <Adminland1 />}
              </ProtectedRoute>} />
            <Route path="/:organizationId/quickaccess" element={<ProtectedRoute isSignedIn={isSignedIn}><QuickAccess /></ProtectedRoute>} />
            <Route path="/administrators" element={<Administrators />} />
            <Route path="/changecontent" element={<Changecontent />} />
            <Route path="/renameprojecttools" element={<RenameProjectTools />} />
            <Route path="/messagecategories" element={<ChangeMessageCategories />} />
            <Route path="/changepings" element={<ChangePings />} />
            <Route path="/editprojectsettings" element={<EditProjectSettings />} />
            <Route path="/planselection" element={<PlanSelection />} />
            <Route path="/managepublicitems" element={<ManagePublicItems />} />
            <Route path="/cancelaccount" element={<CancelAccount />} />
            <Route path="/:organizationId/projects/directory" element={<ProtectedRoute isSignedIn={isSignedIn}><View_All_List /></ProtectedRoute>} />


            <Route path="/:organizationId/accept-invite/:token" element={<InvitationHandler />} />
            {/* <Route path="/:organizationId/accept-invite/:token" element={<InviteRegister />} /> */}
            <Route path="/:organizationId/accept-invite/:token/login" element={<InviteExistLogin />} />
            <Route path="/:organizationId/accept-invite/:token/new-login" element={<InviteNewuser />} />

            <Route path="/:organizationId/project/:id" element={<ProtectedRoute isSignedIn={isSignedIn}><ProjectCard /></ProtectedRoute>} />
            <Route path="/:organizationId/project/:id/people/users/edit" element={<SetUpPeople />} />
            <Route path="/:organizationId/project/:id/people/users/*" element={<AddPeopleToProject />} />
            <Route path="/:organizationId/project/new" element={<ProtectedRoute isSignedIn={isSignedIn}><Createproject /></ProtectedRoute>} />
            <Route path="/:organizationId/project/:id/chat" element={<Campfire />} />
            {/* <Route path="/:organizationId/project/:id/chat" element={<ProtectedRoute isSignedIn={isSignedIn}><Campfire /></ProtectedRoute>} /> */}
            <Route path="/:organizationId/project/:id/messageBoard/newMessage" element={<ProtectedRoute isSignedIn={isSignedIn}><NewMessage /></ProtectedRoute>} />
            <Route path="/:organizationId/project/:id/messageBoard" element={<MessageBoard />} />
            <Route path="/:organizationId/project/:id/messageBoard/message/:messageId" element={<MessageShow />} />
            <Route path="/subscriberlist" element={<SubscriberList />} />
            <Route path="/projectschedule" element={<ProjectSchedule />} />
            <Route path="/:organizationId/project/:id/todos" element={<ToDos />} />
            {/* <Route path="/todoList" element={<TodoList />} /> */}
            <Route path="/:organizationId/project/:id/autocheckin" element={<Autocheckin />} />
            <Route path="/autocheckin-newQuestion" element={<AutocheckinNewQuestion />} />
            <Route path="/:organizationId/project/:id/docsfiles" element={<DocsFiles />} />
            <Route path="/invite" element={<AddEmployees />} />
            <Route path="/:organizationId/project/:id/emailforward" element={<EmailForward />} />
            <Route path="/:organizationId/project/:id/cardtable" element={<CardTable />} />
            <Route path="/justfollow" element={<JustFollow />} />
            <Route path="/projectsettings" element={<ProjectSettings />} />

            <Route path="/:organizationId/my/preference" element={<ProtectedRoute isSignedIn={isSignedIn}><Preferences userPreferences={userPreferences} setUserPreferences={setUserPreferences} /></ProtectedRoute>} />
            <Route path="/:organizationId/my/profile" element={<ProtectedRoute isSignedIn={isSignedIn}><PersonalCard /></ProtectedRoute>} />
            <Route path="/:organizationId/my/devices" element={<ProtectedRoute isSignedIn={isSignedIn}><MyDevices /></ProtectedRoute>} />
            <Route path="/:organizationId/my/out_of_office" element={<OutOfOffice />} />
            <Route path="/:organizationId/my/notifications/settings/edit" element={<ProtectedRoute isSignedIn={isSignedIn}><NotificationSetting /></ProtectedRoute>} />

            <Route path="/:organizationId/my/assignments" element={<ProtectedRoute isSignedIn={isSignedIn}><Assignment /></ProtectedRoute>} />
            <Route path="/:organizationId/my/bookmarks" element={<ProtectedRoute isSignedIn={isSignedIn}><Bookmark /></ProtectedRoute>} />
            <Route path="/:organizationId/my/schedules" element={<ProtectedRoute isSignedIn={isSignedIn}><Schedule /></ProtectedRoute>} />
            <Route path="/:organizationId/project/:id/my/schedules" element={<ProtectedRoute isSignedIn={isSignedIn}><Schedule /></ProtectedRoute>} />
            <Route path="/:organizationId/my/boosts" element={<ProtectedRoute isSignedIn={isSignedIn}><Boosts /></ProtectedRoute>} />
            <Route path="/:organizationId/my/drafts" element={<ProtectedRoute isSignedIn={isSignedIn}><Drafts /></ProtectedRoute>} />
            <Route path="/:organizationId/activity" element={<ProtectedRoute isSignedIn={isSignedIn}><Activity /></ProtectedRoute>} />
            <Route path="/latest-activity" element={<Latest_activity />} />

            <Route path="/someones-activity" element={<Someones_Activity />} />
            <Route path="/someones-assignment" element={<Someones_Assignment />} />
            <Route path="/mission-control" element={<Mission_control />} />
            <Route path="/to-dos" element={<To_dos />} />
            <Route path="/unassignedwork" element={<UnassignedWork />} />
            <Route path="/upcomingdates" element={<UpcomingDates />} />
            <Route path="/timesheet" element={<TimeSheet />} />
            <Route path="/overdue" element={<Overdue />} />

            <Route path="/:organizationId/account/enrollments/new" element={<Invite_People userData={userData} />} />
            <Route path="/:organizationId/account/enrollments/new/coworkers-account" element={<Coworkers_Account userData={userData} />} />
            <Route path="/:organizationId/account/enrollments/new/coworkers-account/success" element={<InviteSuccess userData={userData} />} />
            <Route path="/:organizationId/account/enrollments/new/coworkers-account/exist" element={<InviteExist userData={userData} />} />
            <Route path="/invite-link" element={<Invite_Link />} />
            <Route path="/invitation-identifier" element={<Invitation_Identifier />} />

            <Route path="/:organizationId/view-template" element={<ProtectedRoute isSignedIn={isSignedIn}><View_Template /></ProtectedRoute>} />
            <Route path="/project-template" element={<Project_Template />} />
            <Route path="/todo-list-lemplate" element={<ToDo_List_Template />} />

            <Route path="/mergepeople" element={<MergePeople />} />
            <Route path="/moveproject" element={<MoveProject />} />
            <Route path="/managegroups" element={<ManageGroups />} />
            <Route path="/managecompanies" element={<ManageCompanies />} />
            <Route path="/archive-and-delete-project" element={<Archive_And_Delete_Project />} />
            <Route path="/editproject" element={<EditProject />} />
            <Route path="/publiclink" element={<PublicLink />} />
            <Route path="/two-factor-authentication" element={<Two_Factor_Authentication />} />
            <Route path="/ping-and-chat-history" element={<Ping_And_Chat_History />} />
            <Route path="/add-remove-acc" element={<Add_Remove_Acc />} />
            <Route path="/:organizationId/adminland/add-roles" element={<CreateRole />} />
            <Route path="/:organizationId/adminland/add-roles/new-role" element={<SetPermission />} />
            <Route path="/:organizationId/adminland/add-roles/view-permission/:roleId" element={<ViewPermission />} />
            <Route path="/:organizationId/adminland/add-roles/edit-permission/:roleId" element={<EditPermission />} />
            <Route path="/account-rename" element={<Account_Rename userData={userData} />} />
            <Route path="/trash" element={<Trash />} />
            <Route path="/reassignment" element={<Reassignment />} />
            <Route path="/projectAccess" element={<ProjectAccess />} />
            <Route path="/manageStorage" element={<ManageStorage />} />
            <Route path="/chat/:chatId" element={<PrivateChatinPing />} />
            <Route path="/export-data" element={<Export_Data />} />
            <Route path="*" element={<NotfoundPage />} />

          </>
          : (
            // Default route for when data is false
            <Route path="*" element={<Login onSignIn={signin} />} />
          )}
        {/* </UserProvider> */}

      </Routes>



    </>
  );
}

export default App;