import React from 'react'

const CheckMail = () => {
    return (
        <div className="d-flex justify-content-center align-items-center mt-5 password">
            <div className="Loginpage text-center" style={{ maxWidth: '448px', width: '100%' }}>
                <img src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public' alt="Logo" className="d-block m-auto" style={{ width: '100px' }} />
                {/* {/* <h5 className="card-title text-center text-black mb-3">Try TeamNex for free</h5> */}
                <h3 className="text-center card-title text-black text-m">Teamnex</h3>
                <div className="card p-4 mt-4 b-shad">
                    <div className="card-body">
                        <h1 className="h6 mt-0 mb-2 text-black text-center fw-bold">A verification email has been sent. Please check your email.</h1>
                        <p className="mt-0 mb-2 text-black"></p>
                    </div>
                </div>
                <p className="text-black text-center mt-3">
                    Questions? Need a hand? <a href="#">Support can help.</a>
                </p>
            </div>
        </div>
    )
}

export default CheckMail
