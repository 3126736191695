import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaPlus } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

const SetUpPeople = () => {
    const users = [
        { name: "Arul Kumar V", role: "Product Developer - React Native at webnexs.com", status: "On the HQ" },
        { name: "Arun Kumar", role: "Internship at webnexs.com", status: "On the HQ" },
        { name: "Arun Pandiyan", role: "Front End Intern at webnexs.com", status: "On the HQ" },
        { name: "ArunKumar AD", role: "Front End Intern at webnexs.com", status: "On the HQ" },
        { name: "Bala", role: "UI&UX Designer & Graphic Designer at webnexs.com", status: "On the HQ" },
        { name: "Balaji", role: "Front End Developer at webnexs.com", status: "Just following" },
        { name: "David Bright Livingstone", role: "webnexs.com", status: "On the HQ" },
    ];
    const [selectedStatus, setSelectedStatus] = useState('');
    const handleChanges = (event) => {
        setSelectedStatus(event.target.value);
    };
    const statusOptions = [
        { value: 'On the HQ', label: 'On the HQ' },
        { value: 'Just following', label: 'Just following' },
    ];
    return (
        <div className='campfire'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '60vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <Link to="/project" className="fw-bold">Webnexs HQ</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center" style={{ width: '70vw' }}>
                    <div className="card bg-dark campfireBody">
                        <div className="container ">
                            <div className="row">
                                <div className="col-3 text-start">
                                    <Link to="/new-people">
                                        <button className="newEvent px-2 mt-3 py-1 text-dark">
                                            <FaPlus className='me-1' />Add a people
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-6 text-center mt-3">
                                    <a href="CompanyHQ" className='headcamp fw-bold'> <h2 className="mb-0 text-light">People who can see this Company HQ</h2> </a>
                                </div>
                            </div>
                            <hr className="line" />
                            <div className='container pt-3 setupPeople'>
                                <p className='text-light text-center text-break h-5'>If someone is<span className='user-status'> On the Company HQ</span> their avatar will show up at the top of the Company HQ and they’ll be notified when someone posts in Chat.
                                    People who are<span className='follwBtn'> Just following</span> won’t hear from the Company HQ unless someone specifically @mentions them, assigns them a to-do, or loops them into a thread.</p>
                                <div>
                                    <h4 className='text-light fw-bold text-start ms-4'>webnexs.com</h4>
                                </div>
                                <div className="user-list text-start">
                                    {users.map((user, index) => (
                                        <div key={index} className="user-item">
                                            <div className="user-avatar">{user.name.charAt(0)}</div>
                                            <div className="user-info">
                                                <div className="user-name">{user.name}</div>
                                                <div className="user-role">{user.role}</div>
                                            </div>
                                            <select
                                                className='user-status'
                                                id="day-selector"
                                                value={selectedStatus}
                                                onChange={handleChanges}
                                            >
                                                {statusOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="text-end">
                                                <button className="setupbtn text-light">
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <h4 className='text-light fw-bold text-start'>Everyone else</h4>
                                </div>
                                <div className="user-list text-start">
                                    {users.map((user, index) => (
                                        <div key={index} className="user-item">
                                            <div className="user-avatar">{user.name.charAt(0)}</div>
                                            <div className="user-info">
                                                <div className="user-name">{user.name}</div>
                                                <div className="user-role">{user.role}</div>
                                            </div>
                                            <select
                                                className='user-status'
                                                id="day-selector"
                                                value={selectedStatus}
                                                onChange={handleChanges}
                                            >
                                                {statusOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="text-end">
                                                <button className="setupbtn text-light">
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    )
}

export default SetUpPeople;
