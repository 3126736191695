import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { RiKey2Fill } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const EditPermission = () => {
    const pages = [
        'Adminland',
        'Projects',
        'Activity',
        'Todos',
        'MessageBoard',
        'Campfire',
    ];
    const actions = ['AdminLand', 'Projects', 'Todos', 'MessageBoard', 'CampFire'];

    const [role, setRole] = useState(null);
    const [pageAuth, setPageAuth] = useState({});
    const [userPermissions, setUserPermissions] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState('');

    const location = useLocation();
    const navigate = useNavigate();
    const { roleId } = location.state || {};

    const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
    const organizationId = storedUserData?.user?.organizationId._id;

    const handleBack = () => navigate(-1);

    useEffect(() => {
        const fetchRoleDetails = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/roles/${organizationId}/${roleId}`
                );
                const { userPermissions, pageAuth, ...roleDetails } = response.data.role;
                setRole(roleDetails);
                setUserPermissions(userPermissions);
                setPageAuth(pageAuth);

            } catch (err) {
                setError(err.response?.data?.error || 'Error fetching role details.');
            }
        };

        if (roleId) {
            fetchRoleDetails();
        } else {
            setError('Invalid role ID.');
        }
    }, [organizationId, roleId]);

    const handleSave = async () => {
        setError('');
        setSuccess('');

        try {
            // Clean pageAuth (remove unnecessary fields)
            const cleanPageAuth = { ...pageAuth };
            delete cleanPageAuth._id
            delete cleanPageAuth.__v
            // Clean userPermissions by removing _id and other extra fields
            const cleanUserPermissions = { ...userPermissions }
            delete cleanUserPermissions._id
            delete cleanUserPermissions.__v
            // Prepare the payload
            const payload = {
                RoleName: role.RoleName, // Role name from state
                organizationId,          // Organization ID from localStorage
                pageAuth: cleanPageAuth, // Cleaned pageAuth
                userPermissions: cleanUserPermissions, // Cleaned userPermissions
            };


            // Send the API request
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/api/roles/${roleId}`,
                payload
            );

            // Handle success
            toast.success("Permissions updated")
            navigate(-1)
            // setSuccess('Permissions updated successfully!');
        } catch (err) {
            // Handle errors
            setError(err.response?.data?.error || 'Error updating permissions.');
        }
    };


    if (error) {
        return <p className="text-danger text-center">{error}</p>;
    }

    if (!role) {
        return <p className="text-center">Loading...</p>;
    }
    return (
        <div>
            <div className="campfire todo-contain">
                <div
                    className="text-center d-block m-auto campfire card--head camp"
                    style={{ width: '65vw', height: '6vh' }}
                >
                    <div className="d-flex justify-content-center align-items-center mt-2 h-100">
                        <Link to="/adminland" onClick={handleBack} className="fw-bold">
                            <RiKey2Fill style={{ fontSize: '20px' }} /> Adminland
                        </Link>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center camp">
                    <div className="card" style={{ width: '75vw' }}>
                        <div className="campfireBody">
                            <div className="container">
                                <div className="text-center mt-3">
                                    <h3 className="mb-0 t-color1">Edit {role.RoleName} Authorization</h3>
                                </div>
                                <hr className="line" />
                            </div>
                            <div className="card-body p-0">
                                <div className="admin-panel t-color1 new-role-con">
                                    {/* Page Authorization */}
                                    <div className="mb-5 rounded">
                                        <h5>Page Authorization</h5>
                                        <div className="set-per-con">
                                            {pages.map((page) => (
                                                <div
                                                    className="d-flex justify-content-between align-items-center mb-2"
                                                    key={page}
                                                >
                                                    <span>{page}</span>
                                                    <select
                                                        className="form-select w-50"
                                                        value={pageAuth[page] || 'Not Allow'}
                                                        onChange={(e) =>
                                                            setPageAuth((prev) => ({
                                                                ...prev,
                                                                [page]: e.target.value,
                                                            }))
                                                        }
                                                    >
                                                        <option value="Allow">Allow</option>
                                                        <option value="Not Allow">Not Allow</option>
                                                    </select>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Actions Authorization */}
                                    <div className="mb-4">
                                        <h5>Actions Authorization</h5>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Actions</th>
                                                    <th>View</th>
                                                    <th>Add</th>
                                                    <th>Edit</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {actions.map((action, index) => (
                                                    <tr key={action}>
                                                        <td>{index + 1}</td>
                                                        <td>{action}</td>
                                                        {['view', 'add', 'edit', 'delete'].map((perm) => (
                                                            <td key={perm}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={userPermissions[action]?.[perm] || false}
                                                                    onChange={(e) =>
                                                                        setUserPermissions((prev) => ({
                                                                            ...prev,
                                                                            [action]: {
                                                                                ...prev[action],
                                                                                [perm]: e.target.checked,
                                                                            },
                                                                        }))
                                                                    }
                                                                />
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Save/Cancel buttons */}
                                    <div className="d-flex justify-content-start mt-4">
                                        <button
                                            className="rol-save-btn text-thin"
                                            onClick={handleBack}
                                        >
                                            Back
                                        </button>
                                        <button
                                            className="rol-save-btn text-thin ms-2"
                                            onClick={handleSave}
                                        >
                                            Save
                                        </button>
                                    </div>
                                    {success && <p className="text-success">{success}</p>}
                                    {error && <p className="text-danger">{error}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditPermission;
