import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../../Loader';
import toast from 'react-hot-toast';

const InvitationHandler = () => {
    const { organizationId, token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // Delay the API call by 10 seconds
        const timeoutId = setTimeout(() => {
            if (!organizationId || !token) {
                alert('Invalid invitation link.');
                return navigate('/');
            }

            const checkUser = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/checkUser/${organizationId}/${token}`);
                    const { message, email } = response.data;
                    console.log(response.data);

                    if (message.includes('User Invited successfully')) {
                        // Redirect to login with pre-filled email
                        navigate('/login', { state: { email } });
                        toast.success("Joined new organization")
                    } else if (message.includes('New user')) {
                        // Redirect to registration page with email and organizationId
                        navigate(`/${organizationId}/accept-invite/${token}/new-login`, { state: { email, organizationId } });
                        toast(
                            "Please Set up your password",
                            {
                                duration: 6000,
                            }
                        );
                    }
                } catch (error) {
                    console.error('Error verifying invitation:', error.response?.data?.message || error.message);
                    alert('Failed to process the invitation. Please contact support.');
                    navigate('/');
                }
            };

            checkUser();
        }, 6000); // 10 seconds delay

        // Clean up the timeout if the component unmounts before the API call is triggered
        return () => clearTimeout(timeoutId);
    }, [organizationId, token, navigate]);
    return <div className='d-flex justify-content-center align-items-center flex-column' style={{ height: '100vh' }}>
        <div>
            <Loader />
        </div>
        <div>
            <h4>
                Processing your invitation...
            </h4>
        </div>
    </div>;
};

export default InvitationHandler;
