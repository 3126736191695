import React from 'react'
import { MdAutoDelete, MdCancel, MdDelete, MdEdit, MdGroups2, MdMerge, MdOutlineMessage, MdPeople, MdVpnKey } from "react-icons/md";
import { PiArrowArcRightFill } from "react-icons/pi";
import { IoLockOpen, IoBriefcase, IoFileTrayFullSharp } from "react-icons/io5";
import { RiArchive2Fill, RiKey2Fill } from "react-icons/ri";
import { IoIosChatboxes, IoIosLink, IoMdPeople, IoMdPersonAdd } from "react-icons/io";
import { FaCrown, FaDollarSign, FaEdit } from "react-icons/fa";
import { BsFillBuildingFill } from "react-icons/bs";
import { TbArrowsDoubleNeSw } from "react-icons/tb";
import { Link } from 'react-router-dom';
import asset from '../asset/user.jpg'
const Adminland1 = () => {
    return (
        <div>
            <div>
                <div className="container mt-5 adminland-bg campfireBody">
                    <div className="row align-items-center">
                        <div className="col">
                        </div>
                        <div className="text-center">
                            <h3 className="mb-0 mt-3 headcamp t-color1 fw-bold"><RiKey2Fill style={{
                                fontSize: "35"
                            }} />Adminland</h3>
                        </div>
                        <div className="">
                            <p className='fs-5 text-center t-color1'>Manage your TeamNex account</p>
                        </div>
                        <hr className="adminlandLine" />
                    </div>
                    <div className='container pb-5 admin-panel'>
                        <p className='t-color1 fs-4'>What’s this?</p>
                        <p className='t-color1'>Adminland is the place for admins and owners to oversee this TeamNex account.
                            They have special permissions to add/remove people, change billing info, export data, etc.
                            Need something like that done? Ping someone listed below.</p>
                        <p className='t-color1 fs-4'>Administrators</p>
                        <span className=' t-color1 d-flex gap-3'>
                            <span><img src={asset} alt='' className="profile-pic" />
                                <p>Admin 1</p></span>
                            <span><img src={asset} alt='' className="profile-pic" />
                                <p>Admin 2</p></span>
                            <span><img src={asset} alt='' className="profile-pic" />
                                <p>Admin 3</p></span>
                            <span><img src={asset} alt='' className="profile-pic" />
                                <p>Admin 4</p></span>
                        </span>
                        <h5 className='t-color1 mt-5'>Reach out to an admin to…</h5>
                        <p className='t-color1 mt-3 text-decoration-none'>
                            <button className='adminlandadminBtn me-2'><MdPeople className='t-color1' /> </button>Choose which projects people can access on the account
                        </p>
                        <hr className="textLine ms-5 " />
                        <p className='t-color1 mt-3 text-decoration-none'>
                            <button className='adminlandadminBtn me-2'><MdPeople className='t-color1' /> </button>Change message categories
                        </p>

                        <p className='t-color1 fs-4 mt-5'>Account Owners</p>
                        <span className=' t-color1 d-flex gap-3'>
                            <span><img src={asset} alt='' className="profile-pic" />
                                <p>Owner 1</p></span>
                            <span><img src={asset} alt='' className="profile-pic" />
                                <p>Owner 2</p></span>
                            <span><img src={asset} alt='' className="profile-pic" />
                                <p>Owner 3</p></span>
                            <span><img src={asset} alt='' className="profile-pic" />
                                <p>Admin 4</p></span>
                        </span>
                        <h5 className='t-color1 mt-5'>Reach out to an account owner to…</h5>
                        <p className='t-color1 mt-3 text-decoration-none'>
                            <button className='adminlandadminBtn me-2'><IoIosChatboxes className='t-color1' /> </button>Upgrade/downgrade the account
                        </p>
                        <hr className="textLine ms-5" />
                        <p className='t-color1 mt-3 text-decoration-none'>
                            <button className='adminlandadminBtn me-2'><MdEdit className='t-color1' /> </button>Handle billing info or invoices
                        </p>
                        <hr className="textLine ms-5" />
                        <p className='t-color1 mt-3 text-decoration-none'>
                            <button className='adminlandadminBtn me-2'><IoMdPeople className='t-color1' /> </button>Export account data
                        </p>
                        <hr className="textLine ms-5" />
                        <p className='t-color1 mt-3 text-decoration-none'>
                            <button className='adminlandadminBtn me-2'><RiArchive2Fill className='t-color1' /></button>Require two-factor authentication
                        </p>
                        <hr className="textLine ms-5" />
                        <p to="/cancelaccount" className='t-color1 p-0 mt-3 text-decoration-none'>
                            <button className='adminlandownerBtn me-2'><MdCancel className='t-color1' /></button>Cancel the account
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Adminland1
